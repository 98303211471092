import * as API from '@/api/Request'
import * as Session from '@/api/Session'

// シュっとアカウントが消えるので UI 側で本当に確認をさせないとダメだよ。

interface DeleteAuthAccountRequestResponse {
  readonly status: string // 'success'
  readonly message: string // "Account with UID 'yusuke@mezamee.com' has been destroyed."
}

const DeleteAuthAccountRequest = () => {
  return API.BuildRequest<DeleteAuthAccountRequestResponse>({
    method: 'delete',
    version: 'v1',
    path: '/auth',
    requireAuthentication: true,
    onRequestSuccess: (response) => {
      if (response.data.status === 'success') {
        Session.clearAuthToken()
      }
    },
  })
}

export default DeleteAuthAccountRequest
