interface AuthTokenHeadersInterface {
  uid: string
  client: string
  'access-token': string
}

export const isLoggedIn = (): boolean => {
  return Object.keys(localStorage).includes(authTokenStorageKey)
}

// Request.ts で axios client の interceptors に放り込むやつ。
// export しているので防御的になっている。 hasAuthTokenHeadersInRawHeaders() でチェックして無ければ何もしない。
// 公開エリアを叩く API もあるので clearAuthToken() を else 節でやってしまうと消えたりして不便。
export const responseInterceptor = (headers: any): void => {
  if (hasAuthTokenHeadersInRawHeaders(headers)) {
    const authToken = convertAuthTokenFromRawHeaders(headers)
    const encodedToken = encodeAuthTokenHeaders(authToken)
    localStorage[authTokenStorageKey] = encodedToken
  }
}

export const currentAuthTokenHeaders = (): AuthTokenHeadersInterface => {
  if (isLoggedIn()) {
    const encoded = localStorage[authTokenStorageKey]
    return decodeAuthTokenHeaders(encoded)
  } else {
    return { uid: '', client: '', 'access-token': '' }
  }
}

export const clearAuthToken = (): void => {
  localStorage.removeItem(authTokenStorageKey)
}

// Private

const authTokenHeaderKeys: Array<keyof AuthTokenHeadersInterface> = ['uid', 'client', 'access-token']

const authTokenStorageKey = 'authToken'

// HTTP Response Header に authToken で使うフィールドがあるかどうかナイーブにループで調べる関数。
// authTokenHeaderKeys は今のところ 3 つ。 HTTP Response Header はそれ以上に沢山の値が入っている場合があるので、確定で小さい方をループで回している。
const hasAuthTokenHeadersInRawHeaders = (headers: any): boolean => {
  const keys = Object.keys(headers)
  let hasKey = true
  for (const k of authTokenHeaderKeys) {
    if (!keys.includes(k)) {
      hasKey = false
      break
    }
  }
  return hasKey
}

const convertAuthTokenFromRawHeaders = (headers: any): AuthTokenHeadersInterface => {
  // たまに該当ヘッダが空文字で返ってきてログアウト判定されてしまって困るので厳密に見るようにして、消えてたら前の値を使うようにしている…。
  const before = currentAuthTokenHeaders()
  const uid = typeof headers.uid === 'string' && headers.uid.length > 0 ? headers.uid : before.uid
  const client = typeof headers.client === 'string' && headers.client.length > 0 ? headers.client : before.client
  const token = typeof headers['access-token'] === 'string' && headers['access-token'].length > 0 ? headers['access-token'] : before['access-token']
  return {
    uid,
    client,
    'access-token': token,
  }
}

// localStorage だろうが sessionStorage だろうがユーザーがブラウザから簡単に見られるので、気持ちの問題で base64 にしている。
const encodeAuthTokenHeaders = (authToken: AuthTokenHeadersInterface): string => {
  const jsonString = JSON.stringify(authToken)
  const base64string = btoa(jsonString)
  return base64string
}

// localStorage だろうが sessionStorage だろうがユーザーがブラウザから簡単に見られるので、気持ちの問題で base64 にしている。
const decodeAuthTokenHeaders = (encodedAuthToken: string): AuthTokenHeadersInterface => {
  const jsonString = atob(encodedAuthToken)
  const authToken = JSON.parse(jsonString)
  return authToken
}
