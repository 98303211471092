import React from 'react'
import { GoogleMapsEmbedPlace, GoogleMapsEmbedView, GoogleMapsGeographicCoordinate, GoogleMapsMapType } from '@/components/GoogleMapsEmbed'

export const GoogleMaps0: React.FunctionComponent = () => {
  // <p>原宿付近: 35.670821,139.702726</p>
  // <p>Progate: 35.663928,139.702551</p>
  // <p>共栄堂: 35.696206,139.759408</p>
  // <p>神保町交差点: 35.695983,139.758066</p>
  const [mapPosition, setMapPosition] = React.useState<GoogleMapsGeographicCoordinate>({ latitude: 35.695983, longitude: 139.758066 })
  const [isHiddenGoogleMaps, setHiddenGoogleMaps] = React.useState(true)
  const [isGoogleMapsModeView, setGoogleMapsModeView] = React.useState(true)
  const [googleMapsType, setGoogleMapsType] = React.useState<GoogleMapsMapType>('satellite')

  const getPosition = () => {
    if (window === null || window.navigator.geolocation === null) {
      const errorMessage = 'お使いの端末は、GeoLacation APIに対応していません。'
      alert(errorMessage)
      return
    }

    const option: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 80000,
      maximumAge: 20,
    }

    const onSuccess: PositionCallback = (position: Position) => {
      // console.log(position)
      // const p: GoogleMapsGeographicCoordinate = { latitude: position.coords.latitude, longitude: position.coords.longitude }
      // console.log(p)
      // setMapPosition(p)
      console.log(position.coords)
      setMapPosition(position.coords)
    }

    const onError: PositionErrorCallback = (error) => {
      // エラーコード (error.code) の番号
      // 0: UNKNOWN_ERROR        原因不明のエラー
      // 1: PERMISSION_DENIED    利用者が位置情報の取得を許可しなかった
      // 2: POSITION_UNAVAILABLE 電波状況などで位置情報が取得できなかった
      // 3: TIMEOUT              位置情報の取得に時間がかかり過ぎた…
      const errorInfo = ['原因不明のエラーが発生しました…。', '位置情報の取得が許可されませんでした…。', '電波状況などで位置情報が取得できませんでした…。', '位置情報の取得に時間がかかり過ぎてタイムアウトしました…。']
      const errorMessage = '[エラー番号: ' + error.code + ']\n' + errorInfo[error.code]
      window.alert(errorMessage)
    }

    window.navigator.geolocation.getCurrentPosition(onSuccess, onError, option)
  }

  const handleOnClickToggleMapHidden = () => {
    setHiddenGoogleMaps(!isHiddenGoogleMaps)
  }

  const handleOnClickToggleGoogleMapsMode = () => {
    setGoogleMapsModeView(!isGoogleMapsModeView)
  }

  const handleOnClickToggleGoogleMapsType = () => {
    if (googleMapsType === 'satellite') {
      setGoogleMapsType('roadmap')
    } else {
      setGoogleMapsType('satellite')
    }
  }

  return (
    <>
      <div>
        <span style={{ cursor: 'pointer', textDecorationLine: 'underline' }} onClick={getPosition}>
          Get Position
        </span>
      </div>
      <div>
        <span style={{ cursor: 'pointer', textDecorationLine: 'underline' }} onClick={handleOnClickToggleMapHidden}>{`map hidden?: ${isHiddenGoogleMaps}`}</span>
      </div>
      <div>
        <span style={{ cursor: 'pointer', textDecorationLine: 'underline' }} onClick={handleOnClickToggleGoogleMapsMode}>{`map mode is view?: ${isGoogleMapsModeView}`}</span>
      </div>
      <div>
        <span style={{ cursor: 'pointer', textDecorationLine: 'underline' }} onClick={handleOnClickToggleGoogleMapsType}>{`map type: ${googleMapsType}`}</span>
      </div>
      {isHiddenGoogleMaps ? null : isGoogleMapsModeView ? (
        <GoogleMapsEmbedView ssl={true} parameters={{ center: mapPosition, zoom: 17 }} />
      ) : (
        <GoogleMapsEmbedPlace ssl={true} parameters={{ q: mapPosition, zoom: 16, center: mapPosition, mapType: googleMapsType }} />
      )}
    </>
  )
}
