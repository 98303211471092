import * as API from '@/api/Request'
import { User } from '@/api/types/User'

interface PutAuthChangePasswordRequestBody {
  password: string
  password_confirmation: string
}

interface PutAuthChangePasswordResponse {
  readonly success: boolean
  readonly data: {
    attributes: User
  }
  readonly message: string
}

const PutAuthChangePasswordRequest = (body: PutAuthChangePasswordRequestBody) => {
  return API.BuildRequest<PutAuthChangePasswordResponse>({
    method: 'put',
    version: 'v1',
    path: '/auth',
    requireAuthentication: true,
    body: body,
  })
}

export default PutAuthChangePasswordRequest
