import React from 'react'
import * as Elements from 'react-native-elements'
import { StyleSheet, GestureResponderEvent } from 'react-native'
import { PRIMARY_ORANGE, SECONDARY_ORANGE } from '@/constants/colors'

const styles = StyleSheet.create({
  titleStyle: {
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: 'bold',
  },
  base: {
    borderRadius: 34,
    paddingHorizontal: 30,
    alignSelf: 'center',
  },
  primary: {
    backgroundColor: PRIMARY_ORANGE,
  },
  secondary: {
    backgroundColor: SECONDARY_ORANGE,
  },
  action: {
    backgroundColor: PRIMARY_ORANGE,
  },
  loading: {
    opacity: 0.2,
  },
  shadowContainer: {
    alignSelf: 'center',
    shadowColor: 'rgba(0,0,0,0.12)',
    overflow: 'visible',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 8,
  },
})

type ButtonType = 'primary' | 'secondary' | 'action'

export interface ButtonProps {
  title: string
  onPress?: (_: GestureResponderEvent) => void
  disabled?: boolean
  loading?: boolean
  type?: ButtonType
  expand?: boolean
}
export const Button: React.FunctionComponent<ButtonProps> = ({ title, disabled = false, loading = false, type = 'primary', onPress, expand = false }: ButtonProps) => {
  const buttonStyle = StyleSheet.flatten([styles.base, styles[type], expand ? { width: '100%' } : {}, loading ? styles.loading : {}])
  const containerStyle = StyleSheet.flatten(type === 'action' ? styles.shadowContainer : {})

  return <Elements.Button loading={loading} title={title} titleStyle={StyleSheet.flatten(styles.titleStyle)} disabled={disabled} buttonStyle={buttonStyle} containerStyle={containerStyle} onPress={onPress} />
}
