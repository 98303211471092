import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import * as Session from '@/api/Session'
import DeleteAuthSignOutRequest from '@/api/endpoints/DeleteAuthSignOutRequest'

// https://reacttraining.com/react-router/web/example/auth-workflow の公式っぽいやつを参考にして型クイズを解いた。
const LogoutRoute = ({ children, ...rest }: RouteProps) => {
  const renderProp = () => {
    if (Session.isLoggedIn()) {
      // TODO: 非同期の扱いが適当すぎるのをなんとかする。catch なども。
      DeleteAuthSignOutRequest().send()
    }
    return children
  }
  return <Route {...rest} render={renderProp} />
}

export default LogoutRoute
