import React from 'react'
import { LocationRequest } from '@/api/types/Location'

interface State {
  dateTime: Date | null
  payAmount: number | null
  locationAddress: string | null
  location: LocationRequest | null
}

const initialState: State = {
  dateTime: null,
  payAmount: 300,
  locationAddress: null,
  location: null,
}

const setDateTime = (dateTime: Date) =>
  ({
    type: 'SET_DATE_TIME',
    dateTime,
  } as const)

const setPayment = (amount: number) =>
  ({
    type: 'SET_PAYMENT',
    amount,
  } as const)

const setLocation = (address: string, location: LocationRequest) =>
  ({
    type: 'SET_LOCATION',
    address,
    location,
  } as const)

type Action = ReturnType<typeof setDateTime> | ReturnType<typeof setPayment> | ReturnType<typeof setLocation>

const reducer: (state: State, action: Action) => State = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_DATE_TIME':
      return {
        ...state,
        dateTime: action.dateTime,
      }
    case 'SET_PAYMENT':
      return {
        ...state,
        payAmount: action.amount,
      }
    case 'SET_LOCATION':
      return {
        ...state,
        locationAddress: action.address,
        location: action.location,
      }
    default:
      return state
  }
}

const AlarmSettingContext = React.createContext<{
  state: State
  dispatch: (action: Action) => void
}>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

const AlarmSettingContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }

  return <AlarmSettingContext.Provider value={value}>{props.children}</AlarmSettingContext.Provider>
}

export { AlarmSettingContext, AlarmSettingContextProvider, setDateTime, setPayment, setLocation }
