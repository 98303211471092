import React, { useState, useMemo } from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'

import PostAuthForgotPasswordRequest from '@/api/endpoints/PostAuthForgotPasswordRequest'
import { Button } from '@/components/Button'
import * as Colors from '@/constants/colors'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { useHistory } from 'react-router-dom'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 48,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  containerFocus: {
    borderColor: Colors.PRIMARY_ORANGE,
  },
  containerError: {
    borderColor: Colors.DESTRUCTIVE,
  },
  inputLabel: {
    fontSize: 11,
  },
  inputBase: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '300',
    fontSize: 16,
    color: Colors.LIGHT_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  inputFocus: {
    color: Colors.PRIMARY_ORANGE,
  },
  inputError: {
    color: Colors.DESTRUCTIVE,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 11,
    lineHeight: 22,
    letterSpacing: 0,
  },
  linkableText: {
    color: Colors.DARK,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: Colors.GRAY,
  },
})

const ForgotPassword: React.FunctionComponent = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [emailFocused, setEmailFocused] = useState(false)
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [isPosting, setIsPosting] = useState(false)

  const emailError = useMemo(() => {
    if (!isEmailDirty) {
      return ''
    }
    if (email.length === 0) {
      return 'メールアドレスを入力してください'
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return 'メールアドレスを正しく入力してください'
    }
    return ''
  }, [email, isEmailDirty])
  const emailStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.inputFocus : {}, emailError ? style.inputError : {}])
  }, [emailError, emailFocused])
  const emailInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, email.length > 0 ? { color: Colors.DARK } : {}])
  }, [email])
  const emailContainerStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.containerFocus : {}, emailError ? style.containerError : {}])
  }, [emailError, emailFocused])

  const isSubmitButtonEnabled = useMemo(() => {
    return emailError.length === 0 && email.length > 0
  }, [email.length, emailError.length])

  const onClose = () => {
    history.push('/')
  }

  const handleOnSubmit = async () => {
    if (!isSubmitButtonEnabled) {
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    try {
      await PostAuthForgotPasswordRequest({
        email: email,
      }).send()
      alert('確認メールを送信しました。確認メールに記載のリンクからアカウントを有効化してください。')
      history.replace('/')
    } catch (e) {
      alert('確認メールを送信しました。確認メールに記載のリンクからアカウントを有効化してください。')
    }
    setIsPosting(false)
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={onClose}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title} selectable={false}>
            {'登録したメールアドレスを\n入力してください'}
          </Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>

      <View style={{ height: 60 }} pointerEvents={'none'} />

      <View style={{ marginHorizontal: 24 }}>
        <View>
          <Input
            label={'メールアドレス'}
            labelProps={{ style: [style.inputLabel, emailStyle] }}
            inputContainerStyle={emailContainerStyle}
            inputStyle={emailInput}
            labelStyle={emailStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="メールアドレス"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={emailError}
            autoFocus={false}
            autoCapitalize={'none'}
            value={email}
            onChangeText={setEmail}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => {
              setEmailFocused(false)
              setIsEmailDirty(true)
            }}
          />
        </View>
        <View style={{ height: 48 }} />
        <View>
          <Button title={'再設定リンクを送信'} type="action" disabled={!isSubmitButtonEnabled} onPress={handleOnSubmit} loading={isPosting} />
        </View>
        <View style={{ height: 96 }} />
      </View>
    </View>
  )
}

export default ForgotPassword
