import React from 'react'

import { ReactComponent as AmexIcon } from '@/images/cards/amex.svg'
import { ReactComponent as DinersIcon } from '@/images/cards/diners.svg'
import { ReactComponent as JcbIcon } from '@/images/cards/jcb.svg'
import { ReactComponent as MasterCardIcon } from '@/images/cards/mastercard.svg'
import { ReactComponent as VisaIcon } from '@/images/cards/visa.svg'

export const cardBrandToComponent = (brand: string) => {
  switch (brand) {
    case 'amex':
      return <AmexIcon></AmexIcon>
    case 'diners':
      return <DinersIcon></DinersIcon>
    case 'jcb':
      return <JcbIcon></JcbIcon>
    case 'mastercard':
      return <MasterCardIcon></MasterCardIcon>
    case 'visa':
      return <VisaIcon></VisaIcon>
    default:
      return null
  }
}
