import * as API from '@/api/Request'
import { Alarm } from '@/api/types/Alarm'

interface GetAlarmsResponse {
  readonly data: {
    attributes: Alarm
  }[]
}

const GetAlarmsRequest = () => {
  return API.BuildRequest<GetAlarmsResponse>({
    method: 'get',
    version: 'v1',
    path: '/alarms',
    requireAuthentication: true,
  })
}

export default GetAlarmsRequest
