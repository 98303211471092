import * as API from '@/api/Request'
import { User } from '@/api/types/User'

interface DeleteTrackingInfoResponse {
  readonly data: {
    attributes: User
  }
}

const DeleteTrackingInfoRequest = () => {
  return API.BuildRequest<DeleteTrackingInfoResponse>({
    method: 'delete',
    version: 'v1',
    path: '/tracking_info',
    requireAuthentication: true,
  })
}

export default DeleteTrackingInfoRequest
