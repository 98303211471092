import * as API from '@/api/Request'

interface PostAuthForgotPasswordRequestBody {
  email: string
}

interface PostAuthForgotPasswordResponse {
  readonly success: boolean // 'success'
}

// 開発環境で
// - 'http://localhost:3000/' // 同一マシンのブラウザから見た場合
// - 'https://localhost:3443/'
// - 'http://mbp151.local:3000/' // 同一ネットワークから bonjour で IP 解決して iPhone 実機から見た場合
// - 'https://mbp151.local:3443/'
// みたいに出し分けたいので window.location を見る実装になっている。
// process.env.XXX はビルド時には定数に置き換わってコンパイルされるため、通過しない if とか switch は最適化で消える。確認済。
const redirect_url: string = ((): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      const protocol = window.location.protocol
      const hostname = window.location.hostname
      const port = protocol === 'https:' ? '3443' : '3000'
      const url = `${protocol}//${hostname}:${port}/forgot_password_confirm`
      return url
    case 'staging':
      return 'https://app-dev.mezamee.com/forgot_password_confirm'
    case 'production':
      return 'https://app.mezamee.com/forgot_password_confirm'
    default:
      return 'https://app.mezamee.com/forgot_password_confirm'
  }
})()

const PostAuthForgotPasswordRequest = (body: PostAuthForgotPasswordRequestBody) => {
  return API.BuildRequest<PostAuthForgotPasswordResponse>({
    method: 'post',
    version: 'v1',
    path: '/auth/password',
    requireAuthentication: false,
    body: {
      email: body.email,
      redirect_url: redirect_url,
    },
  })
}

export default PostAuthForgotPasswordRequest
