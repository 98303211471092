import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react'
import { View, StyleSheet, PanResponder, Dimensions } from 'react-native'
import { Map as M, Marker, Circle, TileLayer } from 'react-leaflet'
import Leaflet from 'leaflet'
import { GoogleLayer } from 'react-leaflet-google-v2'
import 'leaflet/dist/leaflet.css'
import 'leaflet.gridlayer.googlemutant'
import locationMarker from '@/images/maps/marker.svg'
import { Alarm } from '@/api/types/Alarm'
import * as Colors from '@/constants/colors'

const locationIcon = new Leaflet.Icon({
  iconUrl: locationMarker,
  iconSize: new Leaflet.Point(44, 58),
  iconAnchor: new Leaflet.Point(22, 45.5),
})

delete (Leaflet.Icon.Default.prototype as any)._getIconUrl
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export interface MapProps {
  alarm: Alarm
  onChangePosition: (position: Position) => void
  onError: (error: Error) => void
  onLocationFailed: (error: { code: number; message: string }) => void
}
const Map: React.FunctionComponent<MapProps> = ({ alarm, onChangePosition, onError, onLocationFailed }) => {
  if (alarm.sleeping_location == null) {
    throw new Error('invalid alarm data')
  }
  const wakeUpPosition = {
    lat: alarm.sleeping_location.latitude,
    lng: alarm.sleeping_location.longitude,
  }
  const [currentPosition, setCurrentPosition] = useState<{ lat: number; lng: number } | null>(null)
  const [centerPosition] = useState({
    lat: alarm.sleeping_location.latitude,
    lng: alarm.sleeping_location.longitude,
  })
  const mapRef = useRef(null)

  useEffect(() => {
    if (window === null || window.navigator.geolocation === null) {
      onError(new Error(`お使いの端末では位置情報を取得できません`))
      return
    }
    const option: PositionOptions = {
      enableHighAccuracy: true,
      timeout: Infinity,
      maximumAge: 0,
    }
    const onPositionSuccess: PositionCallback = (position: Position) => {
      setCurrentPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
      if (position.coords.accuracy <= alarm.required_accuracy) {
        onChangePosition(position)
      } else {
        console.error(`位置情報の精度が足りません: 位置精度 ${position.coords.accuracy} m`)
        onError(new Error(`位置情報の精度が足りません`))
      }
    }
    const onPositionError: PositionErrorCallback = (error) => {
      // TODO: エラーコードに応じてメッセージを変える
      onError(new Error(`位置情報の取得に失敗しました`))
      onLocationFailed(error)
    }
    const watchId = window.navigator.geolocation.watchPosition(onPositionSuccess, onPositionError, option)
    return () => {
      window.navigator.geolocation.clearWatch(watchId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    ;(mapRef.current as any).leafletElement.invalidateSize()
  })

  const panResponder = useMemo(() => {
    return PanResponder.create({
      // マップ内のドラッグが外側に伝搬して、ボトムシートが動くのを防ぐ
      onStartShouldSetPanResponderCapture: (e) => {
        e.stopPropagation()
        e.preventDefault()
        return false
      },
    })
  }, [])
  const osmAttribution = '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  const osmDefaultUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

  return (
    <>
      <style type="text/css">{`
      #setting-location-map .leaflet-container {
        width: 100%;
        height: ${Dimensions.get('window').height - 320}px;
      }
    `}</style>
      <View style={style.container} nativeID="setting-location-map" {...panResponder.panHandlers}>
        <M center={centerPosition} zoom={17} ref={mapRef} zoomControl={false}>
          {process.env.REACT_APP_MAP_LAYER_TYPE === 'google' ? <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} maptype={'ROAD'} /> : <TileLayer attribution={osmAttribution} url={osmDefaultUrl} />}
          {currentPosition != null && <Marker position={currentPosition} zIndexOffset={2} />}
          <Marker position={wakeUpPosition} icon={locationIcon} zIndexOffset={1} />
          <Circle center={wakeUpPosition} radius={alarm.minimum_distance * 1000} fillColor={Colors.PRIMARY_ORANGE} color={Colors.PRIMARY_ORANGE} weight={2} />
        </M>
      </View>
    </>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default Map
