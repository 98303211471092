import React, { useMemo, useRef } from 'react'
import { View, PanResponder, Animated, Dimensions } from 'react-native'
import { ReactComponent as BtnProgress } from '@/images/buttons/btn_slide.svg'

import * as Colors from '@/constants/colors'
const THUMB_SIZE = 56

export interface SwipeThumbProps {
  onSwipeSuccess: () => void
  disabled: boolean
}
const SwipeThumb: React.FunctionComponent<SwipeThumbProps> = ({ onSwipeSuccess, disabled }) => {
  const animatedWidth = useRef(new Animated.Value(THUMB_SIZE)).current

  const rippleSize = useRef(new Animated.Value(0)).current
  const ripplePosition = useRef(new Animated.Value(0)).current
  const rippleRadius = useRef(new Animated.Value(0)).current
  const swipeWidth = Dimensions.get('screen').width
  const panResponder = useMemo(() => {
    const resetRipple = () => {
      rippleSize.setValue(0)
      ripplePosition.setValue(0)
      rippleRadius.setValue(0)
    }
    const onSlideFailure = () => {
      Animated.timing(animatedWidth, {
        toValue: THUMB_SIZE,
        duration: 200,
        useNativeDriver: false,
      }).start()
      resetRipple()
    }
    const onSlideSuccess = (newWidth: number) => {
      if (newWidth !== swipeWidth) {
        Animated.timing(animatedWidth, {
          toValue: swipeWidth,
          duration: 200,
          useNativeDriver: false,
        }).start(() => {
          onSwipeSuccess()
          resetRipple()
          animatedWidth.setValue(THUMB_SIZE)
        })
      }
    }
    return PanResponder.create({
      onStartShouldSetPanResponder: () => !disabled,
      onStartShouldSetPanResponderCapture: (e) => {
        // Android でスワイプと一緒にナビゲーションしないように対応
        return !disabled && e.nativeEvent.pageX > 45
      },
      onMoveShouldSetPanResponder: () => !disabled,
      onMoveShouldSetPanResponderCapture: () => !disabled,
      onPanResponderMove: (event, gestureState) => {
        const newWidth = THUMB_SIZE + gestureState.dx
        let widthSet
        if (newWidth < THUMB_SIZE) {
          widthSet = THUMB_SIZE
        } else if (newWidth > swipeWidth) {
          // 右端まで達した
          widthSet = swipeWidth
        } else {
          widthSet = newWidth
        }
        animatedWidth.setValue(widthSet)

        const r = (widthSet / swipeWidth) * 24
        rippleSize.setValue(r + THUMB_SIZE)
        ripplePosition.setValue((-1 * r) / 2)
        rippleRadius.setValue((r + THUMB_SIZE) / 2)
      },
      onPanResponderRelease: (event, gestureState) => {
        const newWidth = THUMB_SIZE + gestureState.dx
        const successThresholdWidth = swipeWidth * 0.75
        if (newWidth > successThresholdWidth) {
          onSlideSuccess(newWidth)
        } else {
          onSlideFailure()
        }
      },
      onShouldBlockNativeResponder: () => true,
    })
  }, [animatedWidth, onSwipeSuccess, ripplePosition, rippleRadius, rippleSize, swipeWidth, disabled])

  return (
    <Animated.View
      style={{
        width: animatedWidth,
        height: THUMB_SIZE,
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignSelf: 'flex-start',
      }}
      {...panResponder.panHandlers}
    >
      <View
        style={{
          flex: 1,
          height: THUMB_SIZE,
          marginRight: -(THUMB_SIZE / 2),
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            background: `-webkit-linear-gradient(0deg,${Colors.PRIMARY_ORANGE},${Colors.SECONDARY_YELLOW})`,
            opacity: 0.9,
          }}
        />
      </View>
      <View style={{ position: 'relative' }}>
        <Animated.View style={{ position: 'absolute', top: ripplePosition, right: ripplePosition, width: rippleSize, height: rippleSize, backgroundColor: Colors.PRIMARY_ORANGE, opacity: 0.5, borderRadius: rippleRadius }} />
        <BtnProgress
          style={{
            zIndex: 2,
            fill: '#000000',
          }}
        />
      </View>
    </Animated.View>
  )
}

export interface SwipeButtonProps {
  onSwipeSuccess: () => void
  disabled: boolean
}
export const SwipeButton: React.FunctionComponent<SwipeButtonProps> = (props) => {
  return (
    <View style={{ position: 'relative' }}>
      <View
        style={{
          height: THUMB_SIZE,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          position: 'absolute',
          left: 0,
          right: 0,
        }}
      />
      <View
        style={{
          height: THUMB_SIZE,
          position: 'absolute',
          left: 0,
          right: 0,
        }}
      >
        <p
          style={{
            margin: 'auto',
            fontSize: 20,
            fontFamily: 'Hiragino Sans',
            fontWeight: 600,
            color: Colors.PRIMARY_ORANGE,
            background: `-webkit-linear-gradient(0deg,${Colors.PRIMARY_ORANGE},${Colors.SECONDARY_YELLOW})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          スライドで覚悟を決める
        </p>
      </View>
      <SwipeThumb onSwipeSuccess={props.onSwipeSuccess} disabled={props.disabled} />
    </View>
  )
}
