import React, { useMemo } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { ReactComponent as AttentionIcon } from '@/images/icons/ic_attention.svg'
import { CreditCardContext, clearCreditCard } from '@/reducers/CreditCard'
import { CurrentUserContext, clearCurrentUser } from '@/reducers/CurrentUser'
import { AlarmContext, clearAlarms } from '@/reducers/Alarm'
import DeleteAuthAccountRequest from '@/api/endpoints/DeleteAuthAccountRequest'
import { LAST_API_CALLED_AT_KEY } from '@/App'
export interface SideMenuProps {
  onClose: () => void
}

const Link = (props: any) => <Text {...props} accessibilityRole="link" target={'_blank'} rel={'noopener noreferrer'} style={StyleSheet.compose(style.subMenuTitle, props.style)} />

const SideMenu: React.FunctionComponent<SideMenuProps> = ({ onClose }) => {
  const { state } = React.useContext(CreditCardContext)
  const creditCardContext = React.useContext(CreditCardContext)
  const alarmContext = React.useContext(AlarmContext)
  const currentUserContext = React.useContext(CurrentUserContext)
  const [, , removeActiveAlarmId] = useLocalStorage('active-alarm-id', '')
  const [, , removeInstallSuggestedAt] = useLocalStorage('install-suggested-at', '')
  const [, , removeAlarmTooltipAlreadyShown] = useLocalStorage('alarm-tooltip-already-shown', false)
  const isPaymentSettingRequired = useMemo(() => {
    return state.creditCard == null
  }, [state])
  const history = useHistory()

  const cleanUpBrowserData = () => {
    creditCardContext.dispatch(clearCreditCard())
    alarmContext.dispatch(clearAlarms())
    currentUserContext.dispatch(clearCurrentUser())
    window.localStorage.removeItem(LAST_API_CALLED_AT_KEY)
    removeActiveAlarmId()
    removeInstallSuggestedAt()
    removeAlarmTooltipAlreadyShown()
  }

  const handleSignOut = () => {
    cleanUpBrowserData()
    history.push('/sign_out')
    window.alert('ログアウトしました。')
  }

  const handleAccountDelete = async () => {
    const alarms = alarmContext.state.alarms.filter((alarm) => {
      return alarm.is_active
    })
    if (alarms.length !== 0) {
      const rejectMessage = '目覚めてないアラームがあります。\n全て目覚めると退会いただけます。'
      window.alert(rejectMessage)
    } else {
      const confirmMessage = '退会しますか？\nこの操作は取り消せません。'
      const result = window.confirm(confirmMessage)
      if (result) {
        await DeleteAuthAccountRequest().send()
        cleanUpBrowserData()
        history.push('/')
        const doneMessage = '退会処理が完了しました。\n目覚めたくなったらいつでも再度登録いただけます。'
        window.alert(doneMessage)
      }
    }
  }

  return (
    <View style={style.container}>
      <View style={{ height: 6 }} />
      <View style={style.header}>
        <View style={{ flex: 1 }} />
        <TouchableOpacity style={style.closeButton} onPress={onClose}>
          <BtnClose></BtnClose>
        </TouchableOpacity>
      </View>
      <View style={{ flex: 1 }} />
      <View style={style.menus}>
        <Text style={style.menuTitle} onPress={() => history.push('/about')}>
          Mezameeとは
        </Text>
        {false && (
          <>
            <View style={{ height: 24 }} />
            <Text style={style.menuTitle}>アカウント情報</Text>
          </>
        )}
        <View style={{ height: 24 }} />
        <Text style={style.menuTitle} onPress={() => history.push('/history')}>
          履歴
        </Text>
        <View style={{ height: 24 }} />
        <Text style={style.menuTitle} onPress={() => history.push('/email_setting')}>
          メールアドレス変更
        </Text>
        <View style={{ height: 24 }} />
        <Text style={style.menuTitle} onPress={() => history.push('/password_setting')}>
          パスワード変更
        </Text>
        <View style={{ height: 24 }} />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={style.menuTitle} onPress={() => history.push('/payment_setting')}>
            決済方法
          </Text>
          <View style={{ width: 12 }}></View>
          {isPaymentSettingRequired && <AttentionIcon></AttentionIcon>}
        </View>
        <View style={{ height: 24 }} />
        <Text style={style.menuTitle} onPress={handleSignOut}>
          ログアウト
        </Text>
        <View style={{ height: 32 }} />
        <Text style={style.menuTitle} onPress={handleAccountDelete}>
          退会
        </Text>
        <View style={{ height: 24 }} />
        {process.env.REACT_APP_ENV !== 'production' && (
          <Text style={style.menuTitle} onPress={() => history.push('/sample')}>
            サンプル
          </Text>
        )}
      </View>
      <View style={{ flex: 1 }} />
      <View style={style.subMenus}>
        <Link href={'https://mezamee.com/terms/'}>利用規約</Link>
        <View style={{ height: 18 }} />
        <Link href={'https://mezamee.com/privacy/'}>プライバシーポリシー</Link>
        <View style={{ height: 18 }} />
        <Link href={'https://mezamee.com/legal/'}>特定商取引法に基づく表記</Link>
        <View style={{ height: 18 }} />
        <Link href={'mailto:info@mezamee.com'}>お問い合わせ</Link>
      </View>
      <View style={{ height: 62 }} />
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeButton: {
    width: 56,
    height: 56,
    marginLeft: 8,
  },
  closeIcon: {},
  menus: {
    marginHorizontal: 36,
  },
  menuTitle: {
    fontSize: 16,
    fontWeight: '600',
  },
  subMenus: {
    marginHorizontal: 36,
  },
  subMenuTitle: {
    fontSize: 12,
    fontWeight: '400',
  },
})

export default SideMenu
