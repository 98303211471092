import React from 'react'
import { UserStats } from '@/api/types/UserStats'

interface State {
  id: number | null
  email: string | null
  trackingInfo: Record<string, string> | null
  stats: UserStats | null
}

const initialState: State = {
  id: null,
  email: null,
  trackingInfo: null,
  stats: null,
}

const setCurrentUser = (params: { id: number; email: string; trackingInfo: Record<string, string> | null; stats: UserStats }) =>
  ({
    type: 'SET_CURRENT_USER',
    ...params,
  } as const)

const clearCurrentUser = () =>
  ({
    type: 'CLEAR_CURRENT_USER',
  } as const)

type Action = ReturnType<typeof setCurrentUser> | ReturnType<typeof clearCurrentUser>

const reducer: (state: State, action: Action) => State = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        ...action,
      }
    case 'CLEAR_CURRENT_USER':
      return {
        ...state,
        id: null,
        email: null,
        trackingInfo: null,
        stats: null,
      }
    default:
      return state
  }
}

const CurrentUserContext = React.createContext<{
  state: State
  dispatch: (action: Action) => void
}>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

const CurrentUserContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }

  return <CurrentUserContext.Provider value={value}>{props.children}</CurrentUserContext.Provider>
}

export { CurrentUserContext, CurrentUserContextProvider, setCurrentUser, clearCurrentUser }
