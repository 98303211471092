import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import * as Session from '@/api/Session'

export interface RequestInformation<ResponseType = any> {
  method: Method
  version: string
  path: string
  requireAuthentication: boolean
  body?: any
  onRequestSuccess?: (response: AxiosResponse<ResponseType>) => void
}

// 開発環境で
// - 'http://localhost:4000/' // 同一マシンのブラウザから見た場合
// - 'https://localhost:4443/'
// - 'http://mbp151.local:4000/' // 同一ネットワークから bonjour で IP 解決して iPhone 実機から見た場合
// - 'https://mbp151.local:4443/'
// みたいに出し分けたいので window.location を見る実装になっている。
// process.env.XXX はビルド時には定数に置き換わってコンパイルされるため、通過しない if とか switch は最適化で消える。確認済。
export const baseURL: string = ((): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      const protocol = window.location.protocol
      const hostname = window.location.hostname
      const port = protocol === 'https:' ? '4443' : '4000'
      const url = `${protocol}//${hostname}:${port}`
      return url
    case 'staging':
      return 'https://api-dev.mezamee.com'
    case 'production':
      return 'https://api.mezamee.com'
    default:
      return 'https://api.mezamee.com'
  }
})()

const client = axios.create({
  baseURL: baseURL,
  timeout: 1000000, // default is `0` (no timeout)})
  responseType: 'json', // default
})

client.interceptors.response.use(
  (response) => {
    Session.responseInterceptor(response.headers)
    return response
  },
  (error) => {
    if (error.response && error.response.headers) {
      Session.responseInterceptor(error.response.headers)
    }
    return Promise.reject(error)
  }
)

export const registerResponseHandler = (successHandler: (response: any) => void, errorHandler: (error: any) => void) => {
  client.interceptors.response.use((response) => {
    successHandler(response)
    return response
  }, errorHandler)
}

export const BuildRequest = <ResponseType = any>(info: RequestInformation<ResponseType>) => {
  const config: AxiosRequestConfig = {
    method: info.method,
    url: info.version + info.path,
    data: info.body,
  }
  if (info.requireAuthentication) {
    config.headers = Session.currentAuthTokenHeaders()
  }
  return {
    config: config,
    send: async (): Promise<AxiosResponse<ResponseType>> => {
      const onRequestSuccess = info.onRequestSuccess
      if (onRequestSuccess) {
        const response = await client.request<ResponseType>(config)
        onRequestSuccess(response)
        return response
      } else {
        return client.request<ResponseType>(config)
      }
    },
  }
}
