import * as API from '@/api/Request'

interface PostStripeCardSetupResponse {
  readonly client_secret: string
}

const PostStripeCardSetupRequest = () => {
  return API.BuildRequest<PostStripeCardSetupResponse>({
    method: 'post',
    version: 'v1',
    path: '/stripe/card/setup',
    requireAuthentication: true,
  })
}

export default PostStripeCardSetupRequest
