import { AppRegistry } from 'react-native'
import App from '@/App'
import * as serviceWorker from '@/serviceWorker'

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault()
  ;(window as any).beforeInstallPromptEvent = event
})

AppRegistry.registerComponent('App', () => App)

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
})

serviceWorker.unregister()
