import * as API from '@/api/Request'
import { User } from '@/api/types/User'

interface PostTrackingInfoResponse {
  readonly data: {
    attributes: User
  }
}

const PostTrackingInfoRequest = (body: any) => {
  return API.BuildRequest<PostTrackingInfoResponse>({
    method: 'post',
    version: 'v1',
    path: '/tracking_info',
    requireAuthentication: true,
    body: body,
  })
}

export default PostTrackingInfoRequest
