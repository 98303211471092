import * as API from '@/api/Request'
import { CreditCard } from '@/api/types/CreditCard'

interface GetCreditCardResponse {
  readonly credit_card: CreditCard
}

const GetCreditCardRequest = () => {
  return API.BuildRequest<GetCreditCardResponse>({
    method: 'get',
    version: 'v1',
    path: '/credit_card',
    requireAuthentication: true,
  })
}

export default GetCreditCardRequest
