import React, { useState } from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Button } from '@/components/Button'
import SignUpContent from './SignUpContent'
import SignInContent from './SignInContent'
import { ReactComponent as Logo } from '@/images/logo.svg'
import { ReactComponent as MailIcon } from '@/images/icons/ic_mail.svg'
import { useHistory } from 'react-router-dom'

export interface IntroductionProps {
  mainAction: 'signIn' | 'signUp'
}
const Introduction: React.FunctionComponent<IntroductionProps> = ({ mainAction }) => {
  const history = useHistory()
  const [isSignUpOpened, setIsSignUpOpened] = useState(false)
  const [isSignInOpened, setIsSignInOpened] = useState(false)
  const onClickSignUp = () => {
    setIsSignUpOpened(true)
  }
  const onClickSignIn = () => {
    setIsSignInOpened(true)
  }
  const onClose = () => {
    setIsSignInOpened(false)
    setIsSignUpOpened(false)
  }
  const onClickAbout = () => {
    history.push('/about')
  }
  const mainActionText = mainAction === 'signIn' ? 'メールアドレスでログイン' : 'メールアドレスではじめる'
  const subActionText = mainAction === 'signIn' ? 'アカウントを作成' : 'ログインはこちら'
  const onClickMainAction = () => {
    if (mainAction === 'signIn') {
      onClickSignIn()
    } else {
      onClickSignUp()
    }
  }
  const onClickSubAction = () => {
    if (mainAction === 'signIn') {
      onClickSignUp()
    } else {
      onClickSignIn()
    }
  }

  return (
    <>
      {!isSignInOpened && !isSignUpOpened && (
        <View style={style.container}>
          <Text style={style.catchCopy}>{'覚悟を決めるだけ\nもう寝坊はできない'}</Text>
          <View style={{ flex: 1, minHeight: 16, maxHeight: 48 }} />
          <View style={{ width: 120 }}>
            <Logo width={120}></Logo>
          </View>
          <View style={{ flex: 1, minHeight: 16, maxHeight: 48 }} />
          <TouchableOpacity style={style.action} onPress={onClickMainAction}>
            <View style={{ height: 44 }}></View>
            <MailIcon></MailIcon>
            <View style={{ height: 32 }}></View>
            <Text style={style.actionText}>{mainActionText}</Text>
            <View style={{ height: 44 }}></View>
          </TouchableOpacity>
          <View style={{ flex: 1, minHeight: 32 }} />
          <View style={{ marginHorizontal: 24 }}>
            <Button title={subActionText} onPress={onClickSubAction} expand={true} type="primary" />
            <View style={{ height: 18 }} />
            <Button title={'Mezamee とは'} onPress={onClickAbout} expand={true} type="secondary" />
          </View>
          <View style={{ flex: 1, minHeight: 48 }} />
        </View>
      )}
      {isSignInOpened && <SignInContent onClose={onClose} />}
      {isSignUpOpened && <SignUpContent onClose={onClose} />}
    </>
  )
}

const style = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    minHeight: '100%',
  },
  catchCopy: {
    marginTop: 64,
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 15,
    color: '#2D2D2D',
    letterSpacing: 4,
    lineHeight: 26,
    textAlign: 'center',
  },
  title: {
    marginVertical: 12,
    fontSize: 24,
    fontWeight: 'bold',
  },
  action: {
    width: 235,
    height: 180,
    backgroundColor: 'white',
    borderRadius: 12,
    alignItems: 'center',
    alignContent: 'center',
  },
  actionText: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '500',
    fontSize: 13,
    color: '#888888',
    letterSpacing: 0,
    lineHeight: 18,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
  },
})

export default Introduction
