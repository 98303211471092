import React, { useState, useMemo } from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'

import PutAuthResetPasswordRequest from '@/api/endpoints/PutAuthResetPasswordRequest'
import { Button } from '@/components/Button'
import * as Colors from '@/constants/colors'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { useHistory } from 'react-router-dom'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 48,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  containerFocus: {
    borderColor: Colors.PRIMARY_ORANGE,
  },
  containerError: {
    borderColor: Colors.DESTRUCTIVE,
  },
  inputLabel: {
    fontSize: 11,
  },
  inputBase: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '300',
    fontSize: 16,
    color: Colors.LIGHT_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  inputFocus: {
    color: Colors.PRIMARY_ORANGE,
  },
  inputError: {
    color: Colors.DESTRUCTIVE,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 11,
    lineHeight: 22,
    letterSpacing: 0,
  },
  linkableText: {
    color: Colors.DARK,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: Colors.GRAY,
  },
})

const ForgotPasswordConfirm: React.FunctionComponent = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [isPasswordDirty, setIsPasswordDirty] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordConfirmFocused, setPasswordConfirmFocused] = useState(false)
  const [isPasswordConfirmDirty, setIsPasswordConfirmDirty] = useState(false)
  const [isPosting, setIsPosting] = useState(false)

  const passwordError = useMemo(() => {
    if (!isPasswordDirty) {
      return ''
    }
    if (password.length === 0) {
      return 'パスワードを入力してください'
    }
    if (password.length < 8) {
      return 'パスワードが短すぎます'
    }
    if (password.length > 255) {
      return 'パスワードが長すぎます'
    }
    return ''
  }, [password, isPasswordDirty])
  const passwordStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.inputFocus : {}, passwordError ? style.inputError : {}])
  }, [passwordError, passwordFocused])
  const passwordInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, password.length > 0 ? { color: Colors.DARK } : {}])
  }, [password])
  const passwordContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.containerFocus : {}, passwordError ? style.containerError : {}])
  }, [passwordError, passwordFocused])

  const passwordConfirmError = useMemo(() => {
    if (!isPasswordConfirmDirty) {
      return ''
    }
    if (password !== passwordConfirm) {
      return 'パスワード(確認)に誤りがあります'
    }
    return ''
  }, [isPasswordConfirmDirty, password, passwordConfirm])
  const passwordConfirmStyle = useMemo(() => {
    return StyleSheet.flatten([passwordConfirmFocused ? style.inputFocus : {}, passwordConfirmError ? style.inputError : {}])
  }, [passwordConfirmError, passwordConfirmFocused])
  const passwordConfirmContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordConfirmFocused ? style.containerFocus : {}, passwordConfirmError ? style.containerError : {}])
  }, [passwordConfirmError, passwordConfirmFocused])

  const isSubmitButtonEnabled = useMemo(() => {
    return passwordError.length === 0 && password.length > 0 && passwordConfirmError.length === 0 && passwordConfirm.length > 0
  }, [password.length, passwordConfirm.length, passwordConfirmError.length, passwordError.length])

  const onClose = () => {
    history.push('/')
  }

  const handleOnSubmit = async () => {
    if (!isSubmitButtonEnabled) {
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    const searchParams = new URLSearchParams(window.location.search)
    const uid = searchParams.get('uid')
    const client = searchParams.get('client')
    const accessToken = searchParams.get('access-token')
    if (uid && client && accessToken) {
      try {
        await PutAuthResetPasswordRequest(
          {
            password: password,
            password_confirmation: password,
          },
          uid,
          client,
          accessToken
        ).send()
        alert('パスワードを変更しました。')
        history.replace('/')
      } catch (e) {
        console.error(e)
        alert('パスワードの変更に失敗しました')
      }
    } else {
      alert('パスワードの変更に失敗しました')
      history.replace('/')
    }
    setIsPosting(false)
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={onClose}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title} selectable={false}>
            {'パスワードを再設定して\nMezameeをつかいましょう'}
          </Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>

      <View style={{ height: 60 }} pointerEvents={'none'} />

      <View style={{ marginHorizontal: 24 }}>
        <View>
          <Input
            label={'パスワード'}
            labelProps={{ style: [style.inputLabel, passwordStyle] }}
            inputContainerStyle={passwordContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="パスワード"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordError}
            secureTextEntry={true}
            autoCapitalize={'none'}
            value={password}
            onChangeText={setPassword}
            onFocus={() => setPasswordFocused(true)}
            onBlur={() => {
              setPasswordFocused(false)
              setIsPasswordDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <Input
            label={'パスワード(確認)'}
            labelProps={{
              style: [style.inputLabel, passwordConfirmStyle],
            }}
            inputContainerStyle={passwordConfirmContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordConfirmStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="パスワード(確認)"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordConfirmError}
            secureTextEntry={true}
            value={passwordConfirm}
            autoCapitalize={'none'}
            onChangeText={setPasswordConfirm}
            onFocus={() => setPasswordConfirmFocused(true)}
            onBlur={() => {
              setPasswordConfirmFocused(false)
              setIsPasswordConfirmDirty(true)
            }}
          />
        </View>
        <View style={{ height: 48 }} />
        <View>
          <Button title={'パスワードを再設定'} type="action" disabled={!isSubmitButtonEnabled} onPress={handleOnSubmit} loading={isPosting} />
        </View>
        <View style={{ height: 96 }} />
      </View>
    </View>
  )
}

export default ForgotPasswordConfirm
