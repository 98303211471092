import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Text, View, StyleSheet, TouchableOpacity, Animated } from 'react-native'
import { ReactComponent as YenIcon } from '@/images/icons/ic_yen.svg'
import { ReactComponent as Track } from '@/images/track.svg'
import { Alarm } from '@/api/types/Alarm'
import dayjs from 'dayjs'

import * as Colors from '@/constants/colors'

const style = StyleSheet.create({
  container: {
    borderRadius: 8,
    backgroundColor: '#ffffff',
    width: 343,
    height: 200,
    position: 'relative',
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 20,
    paddingBottom: 24,
  },
  dateText: {
    fontSize: 12,
  },
  timeText: {
    fontSize: 34,
  },
  cautionText: {
    fontSize: 10,
  },
  amountText: {
    fontSize: 22,
    color: Colors.DARK_GRAY,
  },
  feeAroundText: {
    fontSize: 8,
    color: Colors.DARK_GRAY,
  },
  feeText: {
    fontSize: 14,
    color: Colors.DARK_GRAY,
  },
  locationText: {
    fontSize: 12,
    color: Colors.DARK_GRAY,
  },
  amountGroup: {
    position: 'relative',
    left: -8,
    bottom: -8,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const AlarmCard: React.FunctionComponent<{ alarm: Alarm; onPress: () => void }> = ({ alarm, onPress }) => {
  const SWITCH_WIDTH = 56
  const KNOW_WIDTH = 22
  const KNOB_LEFT_DISABLE = 7
  const KNOB_LEFT_ENABLE = SWITCH_WIDTH - KNOW_WIDTH - KNOB_LEFT_DISABLE
  const knobLeft = useRef(new Animated.Value(KNOB_LEFT_DISABLE)).current
  const inputRange = [KNOB_LEFT_DISABLE, KNOB_LEFT_ENABLE]
  const knobRippleLeft = knobLeft.interpolate({
    inputRange,
    outputRange: [KNOB_LEFT_DISABLE - 5, KNOB_LEFT_ENABLE - 5],
  })
  const knobColor = knobLeft.interpolate({
    inputRange,
    outputRange: [Colors.TERTIARY, Colors.PRIMARY_ORANGE],
  })
  const knobRippleOpacity = knobLeft.interpolate({
    inputRange,
    outputRange: [0.1, 0.2],
  })
  const cardOpacity = knobLeft.interpolate({
    inputRange,
    outputRange: [0.0, 1.0],
  })
  const textColor = knobLeft.interpolate({
    inputRange,
    outputRange: [Colors.DARK_GRAY, Colors.WHITE],
  })

  const [isEnabled, setIsEnabled] = useState(true)

  useEffect(() => {
    Animated.timing(knobLeft, {
      toValue: isEnabled ? KNOB_LEFT_ENABLE : KNOB_LEFT_DISABLE,
      duration: 0,
      useNativeDriver: false,
    }).start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTogglePress = () => {
    const newIsEnabled = !isEnabled
    setIsEnabled(newIsEnabled)
    Animated.timing(knobLeft, {
      toValue: newIsEnabled ? KNOB_LEFT_ENABLE : KNOB_LEFT_DISABLE,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }

  const fireAtDateText = useMemo(() => {
    if (alarm.fire_at == null) {
      return ''
    }
    return dayjs(new Date(alarm.fire_at)).format('YYYY.MM.DD (ddd)')
  }, [alarm])
  const fireAtTimeText = useMemo(() => {
    if (alarm.fire_at == null) {
      return ''
    }
    return dayjs(new Date(alarm.fire_at)).format('HH:mm')
  }, [alarm])
  const payAmountText = useMemo(() => {
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    })
    if (alarm.amount == null) {
      return ''
    }
    return formatter.format(alarm.amount)
  }, [alarm])
  const remainText = useMemo(() => {
    if (alarm.fire_at == null) {
      return ''
    }
    const fireAt = new Date(alarm.fire_at)
    const diffHour = dayjs(fireAt).diff(new Date(), 'hour')
    const diffMinute = dayjs(fireAt).diff(new Date(), 'minute') - 60 * diffHour
    if (diffHour === 0 && diffMinute === 0) {
      return 'あと残りわずか'
    }
    return diffHour === 0 ? `あと${diffMinute}分後` : `あと${diffHour}時間${diffMinute}分後`
  }, [alarm])

  return (
    <TouchableOpacity style={style.container} onPress={onPress}>
      <Animated.View style={{ position: 'absolute', opacity: cardOpacity, top: 0, bottom: 0, left: 0, right: 0, zIndex: -1 }}>
        <div
          style={{
            borderRadius: 8,
            width: '100%',
            height: '100%',
            backgroundImage: `radial-gradient(circle at 100% 0%, ${Colors.SECONDARY_YELLOW} 0%, ${Colors.PRIMARY_ORANGE} 50%)`,
          }}
        />
      </Animated.View>
      {false && (
        <View style={{ position: 'absolute', top: 12, right: 8 }}>
          <Track></Track>
          <View
            style={{
              position: 'absolute',
              width: 56,
              height: 38,
              top: 0,
              right: 0,
            }}
          >
            <Animated.View
              style={{
                width: 22 + 10,
                height: 22 + 10,
                backgroundColor: knobColor,
                opacity: knobRippleOpacity,
                borderRadius: 16,
                position: 'absolute',
                top: 8 - 5,
                left: knobRippleLeft,
              }}
            ></Animated.View>
            <Animated.View
              style={{
                borderRadius: 16,
                position: 'absolute',
                top: 8,
                left: knobLeft,
                backgroundColor: knobColor,
              }}
            >
              <TouchableOpacity
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: 11,
                  shadowColor: '#000',
                  shadowRadius: 2,
                  shadowOpacity: 0.2,
                  shadowOffset: {
                    width: 0,
                    height: 1,
                  },
                }}
                onPress={handleTogglePress}
              ></TouchableOpacity>
            </Animated.View>
          </View>
        </View>
      )}
      <Animated.Text style={{ ...style.dateText, color: textColor }}>{fireAtDateText}</Animated.Text>
      <Text style={style.timeText}>{fireAtTimeText}</Text>
      <Animated.Text style={{ ...style.cautionText, color: textColor }}>{remainText}</Animated.Text>
      <View style={{ flex: 1 }}></View>
      <View style={style.amountGroup}>
        <YenIcon></YenIcon>
        <Animated.Text style={{ ...style.amountText, color: textColor, height: 32, lineHeight: 34, marginLeft: -4 }}>{payAmountText + ' '}</Animated.Text>
      </View>
    </TouchableOpacity>
  )
}

export default AlarmCard
