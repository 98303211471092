import * as API from '@/api/Request'
import { Alarm } from '@/api/types/Alarm'
import { LocationRequest } from '@/api/types/Location'

interface PostAlarmRequestBody {
  fire_at: string
  amount: number
  currency: string // 'JPY'
  minimum_distance?: number // default 0.1 (km)
  location: LocationRequest
}

interface PostAlarmResponse {
  readonly data: {
    attributes: Alarm
  }
}

const PostAlarmRequest = (body: PostAlarmRequestBody) => {
  return API.BuildRequest<PostAlarmResponse>({
    method: 'post',
    version: 'v1',
    path: '/alarms',
    requireAuthentication: true,
    body,
  })
}

export default PostAlarmRequest
