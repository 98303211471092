import React from 'react'
import { CreditCard } from '@/api/types/CreditCard'

interface State {
  creditCard: CreditCard | null
}

const initialState: State = {
  creditCard: null,
}

const setCreditCard = (creditCard: CreditCard) =>
  ({
    type: 'SET_CREDIT_CARD',
    creditCard,
  } as const)

const clearCreditCard = () =>
  ({
    type: 'CLEAR_CREDIT_CARD',
  } as const)

type Action = ReturnType<typeof setCreditCard> | ReturnType<typeof clearCreditCard>

const reducer: (state: State, action: Action) => State = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_CREDIT_CARD':
      return {
        ...state,
        creditCard: action.creditCard,
      }
    case 'CLEAR_CREDIT_CARD':
      return {
        ...state,
        creditCard: null,
      }
    default:
      return state
  }
}

const CreditCardContext = React.createContext<{
  state: State
  dispatch: (action: Action) => void
}>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

const CreditCardContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }

  return <CreditCardContext.Provider value={value}>{props.children}</CreditCardContext.Provider>
}

export { CreditCardContext, CreditCardContextProvider, setCreditCard, clearCreditCard }
