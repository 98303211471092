import React from 'react'

/*
  Google Maps API について

  - Maps Static API
  - Maps JavaScript API
  - Maps Embed API

  Static API は 1000 リクエストで $2 課金、
  JavaScript API は 1000 リクエストで $7 課金されるが
  Embed API は無料で使えそうなのでなんとか頑張りたいところ。

  https://developers.google.com/maps/billing/gmp-billing?hl=ja#embed-advanced を見ると、
  Embed でもどうやら Directions mode, Street View mode, Search mode では課金が発生するようだ…。

  Embed API における mode は

  - place 指定した場所にマーカーを付けることができる。
  - view マーカーなしのシンプルな埋め込み地図。
  - search 指定した条件の検索結果を表示することができる。
  - directions 指定した出発地から目的地までのアクセス方法を表示することができる。
  - streetview ストリートビューの写真を表示する。

  パラメタについて、公式ドキュメントを読むと以下のようにまとまっている。

  The following optional parameters can be used with any of the map modes listed above.

  - center
      - defines the center of the map view. It accepts a comma-separated latitude and longitude value (such as 37.4218,-122.0840).
  - zoom
    - sets the initial zoom level of the map. Accepted values range from 0 (the whole world) to 21 (individual buildings). The upper limit can vary depending on the map data available at the selected location.
  - maptype
    - can be either roadmap (the default) or satellite, and defines the type of map tiles to load.
  - language
    - defines the language to use for UI elements and for the display of labels on map tiles. Note that this parameter is only supported for some country tiles; if the specific language requested is not supported for the tile set, then the default language for that tileset will be used. By default, visitors will see a map in their own language.
  - region
    - defines the appropriate borders and labels to display, based on geo-political sensitivities. Accepts a region code specified as a two-character ccTLD (top-level domain) value.

  `language` と `region` はユーザーの環境に合わせるなら何も指定しないのが良さそうに思える。
 */

// process.env.XXX はビルド時には定数に置き換わってコンパイルされるため、通過しない if とか switch は最適化で消える。確認済。
const API_KEY: string = ((): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'AIzaSyBOL3q32ouvNtr8a6V0faLb0X5UmKnbp5o'
    case 'staging':
      return 'this_is_dummy_string_for_staging' // TODO: 入れる。
    case 'production':
      return 'this_is_dummy_string_for_production' // TODO: 入れる。
    default:
      return 'this_is_dummy_string_for_production' // TODO: 入れる。
  }
})()

export interface GoogleMapsGeographicCoordinate {
  longitude: number // 経度
  latitude: number // 緯度
}

export type GoogleMapsMapType = 'roadmap' | 'satellite'

export interface GoogleMapsEmbedPlaceParameters {
  key?: string // default: API_KEY
  q: string | GoogleMapsGeographicCoordinate
  zoom?: number // 0 (the whole world) to 21 (individual buildings). default: 15
  center?: GoogleMapsGeographicCoordinate
  mapType?: GoogleMapsMapType
}

export interface GoogleMapsEmbedViewParameters {
  key?: string // default: API_KEY
  center: GoogleMapsGeographicCoordinate
  zoom?: number // 0 (the whole world) to 21 (individual buildings). default: 15
  mapType?: GoogleMapsMapType
}

// TODO: この関数が全体的に格好良くない。なんとかしたい。
const queryString = (parameters: GoogleMapsEmbedPlaceParameters | GoogleMapsEmbedViewParameters): string => {
  const convertString = (coordinate: string | GoogleMapsGeographicCoordinate): string => {
    if (typeof coordinate === 'string') {
      return coordinate
    } else {
      return `${coordinate.latitude},${coordinate.longitude}`
    }
  }
  const query: any = {}
  if (parameters.key) {
    query.key = parameters.key
  } else {
    query.key = API_KEY
  }
  if ('q' in parameters) {
    query.q = parameters.q
  }
  if (parameters.center) {
    query.center = convertString(parameters.center)
  }
  if (parameters.zoom) {
    query.zoom = parameters.zoom
  }
  if (parameters.mapType) {
    query.maptype = parameters.mapType // query の方の t は小文字
  }
  return new URLSearchParams(query).toString()
}

export interface GoogleMapsEmbedPlaceProps {
  ssl: boolean
  parameters: GoogleMapsEmbedPlaceParameters
}

export const GoogleMapsEmbedPlace: React.FunctionComponent<GoogleMapsEmbedPlaceProps> = (props) => {
  const mode = 'place'
  const protocol = props.ssl ? 'https' : 'http'
  const url = `${protocol}://www.google.com/maps/embed/v1/${mode}?${queryString(props.parameters)}`
  // TODO: iframe のパラメタは要調整 : https://developer.mozilla.org/ja/docs/Web/HTML/Element/iframe
  return (
    <div className="hoge">
      <iframe title="Google Maps Place" src={url} scrolling="no" frameBorder="no" marginHeight={0} marginWidth={0} height={640} width={480} />
    </div>
  )
}

export interface GoogleMapsEmbedViewProps {
  ssl: boolean
  parameters: GoogleMapsEmbedViewParameters
}

export const GoogleMapsEmbedView: React.FunctionComponent<GoogleMapsEmbedViewProps> = (props) => {
  const mode = 'view'
  const protocol = props.ssl ? 'https' : 'http'
  const url = `${protocol}://www.google.com/maps/embed/v1/${mode}?${queryString(props.parameters)}`
  // TODO: iframe のパラメタは要調整 : https://developer.mozilla.org/ja/docs/Web/HTML/Element/iframe
  return (
    <div>
      <iframe title="Google Maps View" src={url} scrolling="no" frameBorder="no" marginHeight={0} marginWidth={0} height={640} width={480} />
    </div>
  )
}
