import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { View, StyleSheet, Dimensions, Text, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'
import * as Colors from '@/constants/colors'
import { Button } from '@/components/Button'
import { ReactComponent as RadioIcon } from '@/images/icons/ic_radio.svg'
import { AlarmSettingContext, setPayment } from '@/reducers/AlarmSetting'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { Resettable } from '.'

interface PayOption {
  label: string
  amount?: number
}

export interface SettingPayProps {
  onPressClose: () => void
}
const SettingPay = forwardRef<Resettable, SettingPayProps>(({ onPressClose }, ref) => {
  const { state, dispatch } = React.useContext(AlarmSettingContext)
  const payOptions: PayOption[] = [
    {
      label: '￥ 100',
      amount: 100,
    },
    {
      label: '￥ 300',
      amount: 300,
    },
    {
      label: '￥ 1,000',
      amount: 1000,
    },
    {
      label: '金額を入力',
      amount: undefined,
    },
  ]
  const [amount, setAmount] = useState(0)
  const [payIndex, setPayIndex] = useState(0)

  useImperativeHandle(ref, () => {
    return {
      reset() {
        if (state.payAmount === 0) {
          setPayIndex(0)
          return
        }
        const index = payOptions.findIndex((option) => option.amount === state.payAmount)
        if (index !== -1) {
          setPayIndex(index)
        } else {
          setPayIndex(payOptions.length - 1)
          setAmount(state.payAmount ?? 0)
        }
      },
    }
  })

  const onChangeAmount = (text: string) => {
    setAmount(parseInt(text, 10))
  }

  const onSubmit = () => {
    const payAmount = payOptions[payIndex].amount ?? amount
    if (payAmount < 100) {
      alert('100円以上の金額を入力してください')
      return
    }
    dispatch(setPayment(payAmount))
    onPressClose()
  }

  return (
    <View style={style.container}>
      <View style={{ height: 8 }} />
      <View style={style.header}>
        <View style={{ flex: 1 }} />
        <TouchableOpacity style={style.closeButton} onPress={onPressClose}>
          <BtnClose></BtnClose>
        </TouchableOpacity>
      </View>
      <View style={{ marginHorizontal: 24 }}>
        <Text style={style.title}>覚悟金額</Text>
        <View style={{ height: 30 }} />
        <View>
          {payOptions.map((payOption, index) => {
            return (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
              >
                <TouchableOpacity style={{ padding: 12 }} onPress={setPayIndex.bind(null, index)}>
                  {payIndex === index ? <RadioIcon style={{ stroke: Colors.SECONDARY_YELLOW, color: Colors.SECONDARY_YELLOW }} /> : <View style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: Colors.LIGHT }} />}
                </TouchableOpacity>
                <View
                  style={{
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.INDICATOR,
                    flex: 1,
                  }}
                >
                  {typeof payOption.amount === 'undefined' ? (
                    <Input onChangeText={onChangeAmount} value={amount.toString()} placeholder={payOption.label} keyboardType={'numeric'} containerStyle={{ paddingLeft: 0, paddingRight: 0 }} inputContainerStyle={{ borderBottomWidth: 0 }} />
                  ) : (
                    <Text
                      style={{
                        fontSize: 22,
                        marginBottom: 12,
                      }}
                    >
                      {payOption.label}
                    </Text>
                  )}
                </View>
              </View>
            )
          })}
        </View>
        <View style={{ height: 30 }} />
        <Text style={style.note}>
          {'万が一寝坊した際に課金される金額（覚悟の金額）を設定してください。最低100円（税込）から自由な金額を設定できます。\nMezameeではアラームが鳴らないので、アラームが必要であれば別途設定する必要があります。'}
        </Text>
      </View>

      <View style={{ position: 'absolute', bottom: 74, right: 0, left: 0 }}>
        <Button title={'登録する'} type="action" onPress={onSubmit} />
      </View>
    </View>
  )
})

const style = StyleSheet.create({
  container: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    backgroundColor: '#ffffff',
  },
  title: {
    marginTop: 0,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeButton: {
    width: 56,
    height: 56,
    marginLeft: 8,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontSize: 11,
    lineHeight: 18,
  },
})

export default SettingPay
