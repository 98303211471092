import React, { forwardRef, useState, useMemo, useEffect, useImperativeHandle } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'
import { Overlay } from 'react-native-elements'
import Modal from 'modal-enhanced-react-native-web'
import { Resettable } from '.'
import { ReactComponent as BtnCancel } from '@/images/buttons/btn_cancel.svg'
import { Circle } from 'rc-progress'
import PostAlarmRequest from '@/api/endpoints/PostAlarmRequest'
import { AlarmSettingContext } from '@/reducers/AlarmSetting'
import * as Colors from '@/constants/colors'
import { AlarmContext, prependAlarm } from '@/reducers/Alarm'
import { useLocalStorage } from 'react-use'
import { CurrentUserContext } from '@/reducers/CurrentUser'

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
  headTitle: {
    textAlign: 'center',
    fontSize: 16,
    color: Colors.WHITE,
  },
  body: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 32,
    color: Colors.WHITE,
  },
  cancelText: {
    textAlign: 'center',
    fontWeight: '600',
    color: Colors.WHITE,
    fontSize: 14,
  },
  count: {
    textAlign: 'center',
    fontWeight: '600',
    color: Colors.WHITE,
    fontSize: 24,
  },
  cancelButtonContainer: {
    marginHorizontal: 'auto',
    width: 56,
    height: 56,
  },
  cancelButton: {
    marginHorizontal: 'auto',
    position: 'absolute',
  },
})

export interface CancelProgressProps {
  onComplete: () => void
  onCancel: () => void
  isInitial: boolean
}
const CancelProgress = forwardRef<Resettable, CancelProgressProps>(function CancelProfress({ onComplete, onCancel, isInitial }, ref) {
  const alarmContext = React.useContext(AlarmContext)
  const [remainMilliSeconds, setRemainMilliSeconds] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const { state } = React.useContext(AlarmSettingContext)
  const WAIT_MILLI_SECONDS = 3000
  const [isPosting, setIsPosting] = useState(false)
  const [, setActiveAlarmId] = useLocalStorage('active-alarm-id', '')
  const currentUserContext = React.useContext(CurrentUserContext)

  useImperativeHandle(ref, () => {
    return {
      reset() {
        setRemainMilliSeconds(WAIT_MILLI_SECONDS)
        setIsModalOpen(true)
        setIsCanceled(false)
      },
    }
  })

  useEffect(() => {
    if (!isModalOpen) {
      return
    }
    if (isCanceled) {
      return
    }
    if (remainMilliSeconds < 0) {
      ;(async () => {
        const { dateTime, payAmount, location } = state
        if (payAmount == null || location == null || dateTime == null) {
          throw new Error('alarm setting is invalid')
        }
        try {
          setIsPosting(true)
          const response = await PostAlarmRequest({
            fire_at: dateTime.toISOString(),
            amount: payAmount,
            currency: 'JPY',
            location: location,
          }).send()
          const alarm = response.data.data.attributes
          alarmContext.dispatch(prependAlarm(alarm))
          setActiveAlarmId(alarm.id.toString())
          if (isInitial) {
            window.gtag('event', 'add_to_cart', { value: state.payAmount || 0 })
            window.gtag('event', '初回アラーム', { event_category: 'add-alarm' })
            // A8の成果データの送信
            const userId = currentUserContext.state.id
            const a8Id = currentUserContext.state.trackingInfo?.a8_id ?? ''
            if (a8Id.length > 0 && userId) {
              const img = new Image()
              img.src = `https://px.a8.net/a8fly/earnings?a8=${a8Id}&pid=s00000022070001&so=${userId}&si=1-1-1-a8&currency=JPY`
            }
          }
          alert('アラームを設定しました')
        } catch {
          alert('アラームの設定に失敗しました')
        } finally {
          setIsPosting(false)
          setIsModalOpen(false)
          onComplete()
        }
      })()
    } else {
      const timerId = setInterval(() => {
        setRemainMilliSeconds(remainMilliSeconds - 100)
      }, 100)
      return () => clearInterval(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainMilliSeconds, isModalOpen, isCanceled])

  const progressPercent = useMemo(() => {
    return Math.min(100, (1 - remainMilliSeconds / WAIT_MILLI_SECONDS) * 100)
  }, [remainMilliSeconds])
  const count = useMemo(() => {
    return Math.max(0, Math.floor(remainMilliSeconds / 1000) + 1)
  }, [remainMilliSeconds])
  const isCancelClosed = useMemo(() => {
    return remainMilliSeconds <= 0
  }, [remainMilliSeconds])

  const onPressCancelButton = () => {
    if (isCancelClosed) {
      return
    }
    setIsCanceled(true)
    onCancel()
  }

  return (
    <View style={style.container}>
      <div
        style={{
          zIndex: -1,
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundImage: 'radial-gradient(circle at 100% 0%, rgb(255, 231, 46) 0%, rgb(255, 173, 47) 50%)',
        }}
      />
      <View style={{ height: 76 }} />
      <Text style={style.headTitle}>Are you sure?</Text>
      <Text style={style.body}>Make up your mind</Text>
      <View style={{ flex: 1 }} />
      {progressPercent < 100 && (
        <Text style={style.cancelText}>
          {`あと `}
          <Text style={style.count}>{count}</Text>
          {`秒\nキャンセルできます`}
        </Text>
      )}
      <View style={{ height: 48 }} />
      <View style={style.cancelButtonContainer}>
        <Circle style={{ width: 56, height: 56 }} strokeLinecap={'butt'} strokeWidth={3} trailWidth={4} strokeColor={Colors.WHITE} trailColor={'rgba(255, 255, 255, 0.1)'} percent={progressPercent} />
        <TouchableOpacity style={style.cancelButton} onPress={onPressCancelButton} disabled={isCancelClosed}>
          <BtnCancel style={{ stroke: Colors.WHITE }} />
        </TouchableOpacity>
      </View>
      <View style={{ height: 80 }} />
      <Overlay isVisible={isPosting} ModalComponent={Modal} backdropStyle={{ opacity: 0 }} overlayStyle={{ shadowOpacity: 0, backgroundColor: 'transparent' }}>
        <ActivityIndicator color={Colors.WHITE} size={'large'}></ActivityIndicator>
      </Overlay>
    </View>
  )
})

export default CancelProgress
