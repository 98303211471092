import React from 'react'
import { Alarm } from '@/api/types/Alarm'

interface State {
  alarms: Alarm[]
  isDirty: boolean
}

const initialState: State = {
  alarms: [],
  isDirty: false,
}

const setAlarms = (alarms: Alarm[]) =>
  ({
    type: 'SET_ALARMS',
    alarms,
  } as const)

const prependAlarm = (alarm: Alarm) =>
  ({
    type: 'PREPEND_ALARM',
    alarm,
  } as const)

const removeAlarm = (alarm: Alarm) =>
  ({
    type: 'REMOVE_ALARM',
    alarm,
  } as const)

const clearAlarms = () =>
  ({
    type: 'CLEAR_ALARMS',
  } as const)

const replaceAlarm = (alarm: Alarm) =>
  ({
    type: 'REPLACE_ALARM',
    alarm,
  } as const)

type Action = ReturnType<typeof setAlarms> | ReturnType<typeof clearAlarms> | ReturnType<typeof prependAlarm> | ReturnType<typeof removeAlarm> | ReturnType<typeof replaceAlarm>

const reducer: (state: State, action: Action) => State = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_ALARMS':
      return {
        ...state,
        isDirty: false,
        alarms: [...action.alarms],
      }
    case 'PREPEND_ALARM':
      return {
        ...state,
        isDirty: true,
        alarms: [action.alarm, ...state.alarms],
      }
    case 'CLEAR_ALARMS':
      return {
        ...state,
        isDirty: false,
        alarms: [],
      }
    case 'REMOVE_ALARM':
      const alarms = state.alarms.filter((alarm) => {
        return alarm.id.toString() !== action.alarm.id.toString()
      })
      return {
        ...state,
        isDirty: true,
        alarms,
      }
    case 'REPLACE_ALARM': {
      const index = state.alarms.findIndex((alarm) => {
        return alarm.id.toString() === action.alarm.id.toString()
      })
      const alarms = index != null ? Object.assign([], state.alarms, { [index]: action.alarm }) : state.alarms
      return {
        ...state,
        isDirty: true,
        alarms,
      }
    }
    default:
      return state
  }
}

const AlarmContext = React.createContext<{
  state: State
  dispatch: (action: Action) => void
}>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

const AlarmContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }

  return <AlarmContext.Provider value={value}>{props.children}</AlarmContext.Provider>
}

export { AlarmContext, AlarmContextProvider, setAlarms, clearAlarms, prependAlarm, removeAlarm, replaceAlarm }
