import * as API from '@/api/Request'
import { User } from '@/api/types/User'

interface PutAuthChangeEmailRequestBody {
  email: string
}

interface PutAuthChangeEmailResponse {
  readonly status: string // 'success'
  readonly data: {
    attributes: User
  }
}

const PutAuthChangeEmailRequest = (body: PutAuthChangeEmailRequestBody) => {
  return API.BuildRequest<PutAuthChangeEmailResponse>({
    method: 'put',
    version: 'v1',
    path: '/auth',
    requireAuthentication: true,
    body: body,
  })
}

export default PutAuthChangeEmailRequest
