export const PRIMARY_ORANGE = '#FFAD2F'
export const SECONDARY_ORANGE = '#FFC72E'
export const DARK = '#2D2D2D'
export const LIGHT = '#F7F7F7'
export const ALPHA_DARK = 'rgba(0, 0, 0, 0.06)'
export const TERTIARY = '#CCCCCC'
export const LIGHT_GRAY = '#DDDDDD'
export const GRAY = '#BBBBBB'
export const DARK_GRAY = '#888888'
export const SECONDARY_YELLOW = '#FFE72E'
export const DESTRUCTIVE = '#F6551C'
export const INDICATOR = '#CCCCCC'
export const WHITE = '#FFFFFF'
