import React from 'react'
import { View, Text, StyleSheet, Dimensions, TouchableOpacity } from 'react-native'
import * as Colors from '@/constants/colors'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { PaymentSettingBody } from '@/pages/PaymentSetting/PaymentSetting'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)

export interface SettingCreditCardProps {
  onPressClose: () => void
}
const SettingCreditCard: React.FunctionComponent<SettingCreditCardProps> = ({ onPressClose }) => {
  const onSubmit = () => {
    onPressClose()
  }

  return (
    <Elements stripe={stripePromise}>
      <View style={style.container}>
        <View style={{ height: 8 }} />
        <View style={style.header}>
          <View style={{ flex: 1 }} />
          <TouchableOpacity style={style.closeButton} onPress={onPressClose}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <Text style={style.title}>決済方法</Text>
        <PaymentSettingBody onSubmit={onSubmit}></PaymentSettingBody>
      </View>
    </Elements>
  )
}

const style = StyleSheet.create({
  container: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    backgroundColor: '#ffffff',
  },
  title: {
    marginHorizontal: 24,
    marginTop: 0,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeButton: {
    width: 56,
    height: 56,
    marginLeft: 8,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontSize: 11,
    lineHeight: 18,
  },
})

export default SettingCreditCard
