import * as API from '@/api/Request'

interface DeleteStripeCardResponse {
  readonly success: boolean
  readonly message: string
}

const DeleteStripeCardRequest = () => {
  return API.BuildRequest<DeleteStripeCardResponse>({
    method: 'delete',
    version: 'v1',
    path: '/stripe/card',
    requireAuthentication: true,
  })
}

export default DeleteStripeCardRequest
