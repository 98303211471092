import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator } from 'react-native'
import { Overlay } from 'react-native-elements'
import Modal from 'modal-enhanced-react-native-web'
import SideMenu from './SideMenu'
import BottomSheet from '@/components/BottomSheet'
import AlarmSheet from '@/pages/Alarm'
import WakeUpSheet from '@/pages/WakeUp'
import Done from '@/pages/WakeUp/Done'
import { Button } from '@/components/Button'
import * as Colors from '@/constants/colors'
import { AlarmSettingContextProvider } from '@/reducers/AlarmSetting'
import { ReactComponent as BtnAdd } from '@/images/buttons/btn_add.svg'
import { ReactComponent as BtnNav } from '@/images/buttons/btn_nav.svg'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import AlarmCard from './AlarmCard'
import GetAlarmsRequest from '@/api/endpoints/GetAlarmsRequest'
import { Alarm } from '@/api/types/Alarm'
import { CreditCardContext, setCreditCard } from '@/reducers/CreditCard'
import { CurrentUserContext, setCurrentUser } from '@/reducers/CurrentUser'
import { AlarmContext, setAlarms, replaceAlarm } from '@/reducers/Alarm'
import GetCreditCardRequest from '@/api/endpoints/GetCreditCardRequest'
import GetMeRequest from '@/api/endpoints/GetMeRequest'
import { useLocalStorage } from 'react-use'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { isIOS } from '@/utils'
import Tooltip from '@/components/Tooltip'

const Home: React.FunctionComponent = () => {
  const history = useHistory()
  const creditCardContext = React.useContext(CreditCardContext)
  const alarmContext = React.useContext(AlarmContext)
  const currentUserContext = React.useContext(CurrentUserContext)
  const [, setCount] = useState(0)
  const forceUpdate = () => {
    setCount((count) => count + 1)
  }
  const [isOpenModal, setIsOpenModal] = useState(false)
  let alarmSheet: BottomSheet
  const [isVisibleDone, setIsVisibleDone] = useState(false)
  const [isVisibleInstallSuggestion, setIsVisibleInstallSuggestion] = useState(false)
  const [installSuggestedAt, setInstallSuggestedAt] = useLocalStorage('install-suggested-at', '')
  const [lastVisibledAt, setLastVisibledAt] = useState(new Date().getTime())
  const [successAlarmIds, setSuccessAlarmIds] = useState<number[]>([])

  const onPressNewAlarm = () => {
    alarmSheet.open({})
  }
  const closeAlarm = async () => {
    if ((installSuggestedAt?.length ?? 0) === 0) {
      setInstallSuggestedAt(Date.now().toString())
      setIsVisibleInstallSuggestion(true)
    }
    alarmSheet.close({})
  }
  let wakeUpSheet: BottomSheet
  const [wakeUpAlarm, setWakeUpAlarm] = useState<Alarm | null>(null)
  const onPressWakeUp = (alarm: Alarm) => {
    setWakeUpAlarm(alarm)
    wakeUpSheet.open({})
  }
  const handleOnCloseDone = () => {
    wakeUpSheet.close({})
  }
  const handleOnWokeUp = (alarmId: number) => {
    setSuccessAlarmIds([...successAlarmIds, alarmId])
  }
  const closeWakeUp = async () => {
    setWakeUpAlarm(null)
  }

  const [activeAlarmId, setActiveAlarmId] = useLocalStorage('active-alarm-id', '')
  const [alarmTooltipAlreadyShown, setAlarmTooltipAlreadyShown] = useLocalStorage('alarm-tooltip-already-shown', false)
  const [isLoading, setIsLoading] = useState(true)
  const alarms = useMemo(() => {
    return alarmContext.state.alarms
  }, [alarmContext.state])
  const activeAlarms = useMemo(() => {
    return alarms.filter((alarm) => alarm.is_active)
  }, [alarms])
  const hasActiveAlarm = useMemo(() => {
    return activeAlarms.length > 0
  }, [activeAlarms])
  const showAlarmTooltip = useMemo(() => {
    return hasActiveAlarm && !alarmTooltipAlreadyShown && alarms.length === 1 && !isVisibleInstallSuggestion
  }, [hasActiveAlarm, alarmTooltipAlreadyShown, alarms, isVisibleInstallSuggestion])
  const onCloseAlarmTooltip = () => {
    setAlarmTooltipAlreadyShown(true)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible') {
        setLastVisibledAt(new Date().getTime())
      }
    })
  }, [])

  useEffect(() => {
    if (activeAlarms.length === 0) {
      return
    }
    const alarm = activeAlarms[0]
    let timerId: number | null = window.setInterval(() => {
      if (successAlarmIds.includes(alarm.id)) {
        return
      }
      const now = new Date()
      if (document.visibilityState === 'hidden') {
        return
      }
      if (now.getTime() - lastVisibledAt < 8 * 1000) {
        return
      }
      if (dayjs(new Date(alarm.fire_at)).isBefore(now)) {
        if (timerId) {
          window.clearInterval(timerId)
          timerId = null
        }
        alarm.is_active = false
        alarmContext.dispatch(replaceAlarm(alarm))

        setActiveAlarmId('')
        wakeUpSheet.close({})
        closeWakeUp()
        setIsVisibleDone(true)
      }
    }, 1000)
    return () => {
      if (timerId) {
        window.clearInterval(timerId)
        timerId = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlarms, successAlarmIds])

  useEffect(() => {
    const checkDoneFalure = (alarms: Array<Alarm>): Array<Alarm> => {
      const now = new Date()

      for (const alarm of alarms) {
        if (!alarm.is_active && alarm.id.toString() === activeAlarmId) {
          setTimeout(() => {
            setIsVisibleDone(true)
          }, 1000)
          break
        }
      }
      setActiveAlarmId('')
      return alarms.map((alarm) => {
        if (dayjs(new Date(alarm.fire_at)).isBefore(now)) {
          alarm.is_active = false
        }
        return alarm
      })
    }
    const getAlarms = async () => {
      setIsLoading(true)
      const response = await GetAlarmsRequest().send()
      let alarms = response.data.data.map((obj) => {
        return obj.attributes
      })
      alarms = checkDoneFalure(alarms)
      alarmContext.dispatch(setAlarms(alarms))
      setIsLoading(false)
    }
    if (alarmContext.state.alarms.length === 0) {
      alarmContext.dispatch(setAlarms(alarms))
      getAlarms()
    } else {
      // ログイン直後でアラームを既に取得している
      let alarms = alarmContext.state.alarms
      alarms = checkDoneFalure(alarms)
      alarmContext.dispatch(setAlarms(alarms))
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getCreditCard = async () => {
      const response = await GetCreditCardRequest().send()
      creditCardContext.dispatch(setCreditCard(response.data.credit_card))
    }
    if (creditCardContext.state.creditCard == null) {
      getCreditCard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getMe = async () => {
      const response = await GetMeRequest().send()
      const { id, email, tracking_info, stats } = response.data.data.attributes
      currentUserContext.dispatch(setCurrentUser({ id, email, trackingInfo: tracking_info, stats }))
    }
    if (currentUserContext.state.email == null) {
      getMe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    // Overlayのモーダルに微妙にマージンが入るが、調整する方法がないので、無理やりマージンを消す
    const content = document.getElementById('add-to-homescreen-overlay')
    if (!content) {
      return
    }
    const modalRoot = content.closest('[aria-modal=true]')
    if (!modalRoot) {
      return
    }
    for (const child of modalRoot.children) {
      if ((child as HTMLDivElement).style.margin.length > 0) {
        ;(child as HTMLDivElement).style.margin = ''
      }
    }
  })

  const toggleSideMenu = () => {
    setIsOpenModal(!isOpenModal)
  }

  const onPressAbout = () => {
    history.push('/about')
  }

  const beforeInstallPromptEvent = (window as any).beforeInstallPromptEvent
  const isInstallButtonEnabled = typeof beforeInstallPromptEvent !== 'undefined'

  const onPressInstall = () => {
    beforeInstallPromptEvent.prompt()
    beforeInstallPromptEvent.userChoice.then(() => {
      delete (window as any).beforeInstallPromptEvent
    })
  }

  return (
    <>
      <View style={style.container}>
        <View style={style.header}>
          <View>
            <TouchableOpacity onPress={setIsOpenModal.bind(null, !isOpenModal)} style={style.menuButton}>
              <View style={style.menuButtonIcon}>
                <BtnNav></BtnNav>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ height: 80 }} />
        {isLoading && activeAlarms.length === 0 && (
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}></View>
            <ActivityIndicator color={Colors.PRIMARY_ORANGE} size={'large'}></ActivityIndicator>
            <View style={{ flex: 1 }}></View>
          </View>
        )}
        {activeAlarms.length > 0 && (
          <>
            <Tooltip
              isVisible={showAlarmTooltip}
              content={
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={{ textAlign: 'center', fontSize: 14, paddingVertical: 12 }}>{'タップして\n「スライドで目覚める」をスライド'}</Text>
                </View>
              }
              placement={'bottom'}
              modalComponent={Modal}
              showChildInTooltip={false}
              allowChildInteraction={false}
              disableShadow={true}
              onClose={onCloseAlarmTooltip}
              backgroundColor={'rgba(0,0,0,0.2)'}
            >
              <AlarmCard
                alarm={activeAlarms[0]}
                onPress={() => {
                  onPressWakeUp(activeAlarms[0])
                }}
              ></AlarmCard>
            </Tooltip>
            <View style={{ height: 40 }} />
            <Text style={style.note}>通信環境が悪いと位置情報の取得に時間がかかる（数十秒）ことがあります。時間に余裕をもって「起床場所」から離れましょう。</Text>
          </>
        )}
        {!isLoading && activeAlarms.length === 0 && (
          <>
            <View style={style.notFoundContainer}>
              <View style={{ height: 30 }} pointerEvents={'none'} />
              <Text style={style.notFoundTitle} selectable={false}>
                {'覚悟を決めれば\n寝坊はやめられる'}
              </Text>
              <View style={{ height: 16 }} pointerEvents={'none'} />
              <Text style={style.notFoundBody} selectable={false}>
                {'「もう寝坊なんかしない」\nその覚悟に値段をつけてください。\n\n寝坊したらその金額が課金されます。\nこれでもう寝坊するわけにはいきません。\n充実した朝を過ごしましょう。'}
              </Text>
              <View style={{ height: 44 }} pointerEvents={'none'} />
              <View style={{ marginHorizontal: 24 }}>
                <Button title={'Mezamee とは'} expand={true} type="secondary" onPress={onPressAbout} />
                <View style={{ height: 16 }} />
                <Button title={'さあ、はじめよう'} expand={true} type="primary" onPress={onPressNewAlarm} />
              </View>
              <View style={{ height: 50 }} />
            </View>
            <View style={{ height: 40 }} />
            <TouchableOpacity onPress={onPressNewAlarm}>
              <BtnAdd style={{ stroke: '#000000' }} />
            </TouchableOpacity>
          </>
        )}
        <View style={{ height: 40 }} />

        <Modal
          isVisible={isOpenModal}
          onBackdropPress={toggleSideMenu}
          onSwipeComplete={toggleSideMenu}
          animationIn="slideInRight"
          animationOut="slideOutRight"
          swipeDirection="right"
          backdropOpacity={0.25}
          useNativeDriver={false}
          hideModalContentWhileAnimating={true}
          propagateSwipe={true}
          style={style.sideMenu}
        >
          <SideMenu onClose={setIsOpenModal.bind(null, false)} />
        </Modal>
        {!isLoading && (
          <BottomSheet
            ref={(ref) => {
              if (ref !== null) {
                alarmSheet = ref
              }
            }}
            onOpen={forceUpdate}
            closeOnDragDown={true}
            dragFromTopOnly={true}
          >
            <AlarmSettingContextProvider>
              <AlarmSheet onCreated={closeAlarm} templateAlarm={alarms[alarms.length - 1]} isInitial={alarms.length === 0} />
            </AlarmSettingContextProvider>
          </BottomSheet>
        )}
        {hasActiveAlarm && (
          <BottomSheet
            ref={(ref) => {
              if (ref !== null) {
                wakeUpSheet = ref
              }
            }}
            onOpen={forceUpdate}
            onClose={closeWakeUp}
            dragFromTopOnly={true}
            closeOnDragDown={true}
          >
            {wakeUpAlarm && <WakeUpSheet alarm={wakeUpAlarm} onWokeUp={handleOnWokeUp} onCloseDone={handleOnCloseDone} />}
          </BottomSheet>
        )}
        <Modal isVisible={isVisibleDone} style={{ margin: 0 }}>
          <Done
            onClose={() => {
              setIsVisibleDone(false)
              alert('覚悟の金額が課金されました')
            }}
            success={false}
          />
        </Modal>
        <Overlay isVisible={isVisibleInstallSuggestion} ModalComponent={Modal} backdropStyle={{}} overlayStyle={{ width: '85%', borderRadius: 4 }}>
          <View nativeID={'add-to-homescreen-overlay'}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <View style={{ flex: 1 }} />
              <TouchableOpacity
                style={{
                  width: 44,
                  height: 44,
                  marginLeft: 8,
                }}
                onPress={() => {
                  setIsVisibleInstallSuggestion(false)
                }}
              >
                <BtnClose></BtnClose>
              </TouchableOpacity>
            </View>
            {isIOS() ? (
              <>
                <View style={{ height: 8 }}></View>
                <Text style={style.addToHomeScreenBody}>
                  {`ホーム画面にメザミーを追加しませんか？\n必要な時に、すぐメザミーを開くことができます。\n`}
                  {`標準ブラウザ Safariの共有ボタンから『ホーム画面に追加』をタップしてください。`}
                </Text>
                <View style={{ height: 24 }}></View>
              </>
            ) : isInstallButtonEnabled ? (
              <>
                <View style={{ height: 8 }}></View>
                <Text style={style.addToHomeScreenBody}>{`メザミーをホーム画面に追加しますか？\n目覚める時にすぐにメザミーを\n開くことができます`}</Text>
                <View style={{ height: 24 }}></View>
                <Button title={'ホーム画面に追加'} type="primary" onPress={onPressInstall} />
                <View style={{ height: 24 }}></View>
              </>
            ) : (
              <>
                <View style={{ height: 8 }}></View>
                <Text style={style.addToHomeScreenBody}>
                  {`ホーム画面にメザミーを追加しませんか？\n必要な時に、すぐメザミーを開くことができます。\n`}
                  {`標準ブラウザ Chromeのメニューより『ホーム画面に追加』をタップしてください。`}
                </Text>
                <View style={{ height: 24 }}></View>
              </>
            )}
          </View>
        </Overlay>
      </View>
    </>
  )
}

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
  },
  header: {
    position: 'absolute',
    top: 8,
    right: 6,
  },
  menuButton: {
    width: 56,
    height: 56,
  },
  menuButtonIcon: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notFoundContainer: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    marginHorizontal: 16,
    width: Dimensions.get('window').width - 16 * 2,
    borderRadius: 8,
  },
  notFoundTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.DARK,
  },
  notFoundBody: {
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
    color: Colors.DARK_GRAY,
  },
  addToHomeScreenBody: {
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
    color: Colors.DARK,
  },
  sideMenu: {
    margin: 0,
    marginLeft: 48,
    width: Dimensions.get('window').width - 48,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontSize: 11,
    lineHeight: 18,
    marginHorizontal: 24,
  },
})

export default Home
