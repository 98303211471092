import React from 'react'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import * as Session from '@/api/Session'

// https://reacttraining.com/react-router/web/example/auth-workflow の公式っぽいやつを参考にして型クイズを解いた。
const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const renderProp = ({ location }: RouteComponentProps<any>) => {
    if (Session.isLoggedIn()) {
      return children
    } else {
      return (
        <Redirect
          to={{
            pathname: '/sign_in',
            state: { from: location },
          }}
        />
      )
    }
  }
  return <Route {...rest} render={renderProp} />
}

export default PrivateRoute
