import React, { useRef, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

import LogoutRoute from '@/routes/LogoutRoute'
import PrivateRoute from '@/routes/PrivateRoute'
import ForgotPassword from '@/pages/ForgotPassword'
import ForgotPasswordConfirm from '@/pages/ForgotPasswordConfirm'
import Introduction from '@/pages/Introduction'
import Home from '@/pages/Home'
import PaymentSetting from '@/pages/PaymentSetting'
import EmailSetting from '@/pages/EmailSetting'
import PasswordSetting from '@/pages/PasswordSetting'
import About from '@/pages/About'
import History from '@/pages/History'
import Nakaoka from '@/samples/Nakaoka'
import { CreditCardContextProvider } from '@/reducers/CreditCard'
import { CurrentUserContextProvider } from '@/reducers/CurrentUser'
import { AlarmContextProvider } from '@/reducers/Alarm'
import { registerResponseHandler } from '@/api/Request'
import * as Session from '@/api/Session'

dayjs.locale('ja')

export const LAST_API_CALLED_AT_KEY = 'last-api-called-at'

registerResponseHandler(
  (response) => {
    if (response.status >= 200 && response.status <= 299) {
      window.localStorage.setItem(LAST_API_CALLED_AT_KEY, new Date().getTime().toString())
    }
    return
  },
  (error) => {
    const response = error?.response
    // ログインの失敗を除いて、401が返ってきた時はトークンの期限切れとみなして、ログイン画面にリダイレクトする
    if (!response?.config?.url.includes('/auth/sign_in') && response?.status === 401) {
      Session.clearAuthToken()
      window.localStorage.removeItem(LAST_API_CALLED_AT_KEY)
      window.location.replace('/sign_in')
    }
    return Promise.reject(error)
  }
)

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    const rawLastCalledAt = window.localStorage.getItem(LAST_API_CALLED_AT_KEY)
    if (!rawLastCalledAt) {
      return
    }
    if (!Session.isLoggedIn) {
      return
    }
    const lastCalledAt = dayjs(parseInt(rawLastCalledAt))
    const now = dayjs()
    const diff = now.diff(lastCalledAt, 'hour')
    if (diff > 1) {
      window.location.reload()
    }
  }
})

const App: React.FunctionComponent = () => {
  const router = useRef(null)

  useEffect(() => {
    ;(router.current as any).history.listen((location: any) => {
      if (window.gtag) {
        window.gtag('config', 'G-G15H4VGVQM', { page_path: location.pathname })
      }
    })
  }, [])

  return (
    <>
      <style type="text/css">{`
        :focus { outline: none; }
        * {
          -webkit-tap-highlight-color: transparent;
        }
        body {
          overscroll-behavior-y: none;
        }
      `}</style>
      <AlarmContextProvider>
        <CreditCardContextProvider>
          <CurrentUserContextProvider>
            <BrowserRouter ref={router}>
              <Switch>
                <Route exact={true} path="/about">
                  <About />
                </Route>
                <Route exact={true} path="/forgot_password">
                  <ForgotPassword />
                </Route>
                <Route exact={true} path="/forgot_password_confirm">
                  <ForgotPasswordConfirm />
                </Route>
                <Route exact={true} path="/sign_in">
                  <Introduction mainAction={'signIn'} />
                </Route>
                <Route exact={true} path="/sign_up">
                  <Introduction mainAction={'signUp'} />
                </Route>
                <LogoutRoute exact={true} path="/sign_out">
                  <Redirect to="/sign_in" />
                </LogoutRoute>
                <PrivateRoute exact={true} path="/">
                  <Home />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/payment_setting">
                  <PaymentSetting />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/email_setting">
                  <EmailSetting />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/password_setting">
                  <PasswordSetting />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/history">
                  <History />
                </PrivateRoute>
                <PrivateRoute exact={true} path="/sample">
                  <Nakaoka />
                </PrivateRoute>
              </Switch>
            </BrowserRouter>
          </CurrentUserContextProvider>
        </CreditCardContextProvider>
      </AlarmContextProvider>
    </>
  )
}

export default App
