import * as API from '@/api/Request'
import { Alarm } from '@/api/types/Alarm'
import { LocationRequest } from '@/api/types/Location'

interface PostAlarmWakeUpRequestBody {
  location: LocationRequest
}

interface PostAlarmWakeUpResponse {
  readonly data: {
    attributes: Alarm
  }
}

const PostAlarmWakeUpRequest = (id: number, body: PostAlarmWakeUpRequestBody) => {
  return API.BuildRequest<PostAlarmWakeUpResponse>({
    method: 'post',
    version: 'v1',
    path: `/alarms/${id}/wake_up`,
    requireAuthentication: true,
    body,
  })
}

export default PostAlarmWakeUpRequest
