import React, { useEffect, useMemo, useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { loadStripe, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js'
import { CardNumberElement, CardExpiryElement, CardCvcElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { useHistory } from 'react-router-dom'
import PostStripeCardSetupRequest from '@/api/endpoints/PostStripeCardSetupRequest'
import PostStripeCardConfirmRequest from '@/api/endpoints/PostStripeCardConfirmRequest'
import { CreditCardContext, setCreditCard, clearCreditCard } from '@/reducers/CreditCard'
import GetCreditCardRequest from '@/api/endpoints/GetCreditCardRequest'
import { ReactComponent as CardIcon } from '@/images/cards/card.svg'
import * as Colors from '@/constants/colors'
import DeleteStripeCardRequest from '@/api/endpoints/DeleteStripeCardRequest'
import { useBoolean } from 'react-use'
import { cardBrandToComponent } from '@/utils'

import { Button } from '@/components/Button'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    flex: 1,
    textAlign: 'left',
    marginHorizontal: 24,
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 24,
    color: '#2D2D2D',
    letterSpacing: 1,
    lineHeight: 30,
  },
  subtitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 15,
    color: '#2D2D2D',
    letterSpacing: 0,
    lineHeight: 32,
  },
})

export interface PaymentSettingBodyProps {
  onSubmit: () => void
}
export const PaymentSettingBody: React.FunctionComponent<PaymentSettingBodyProps> = ({ onSubmit }) => {
  const { state, dispatch } = React.useContext(CreditCardContext)
  const [isLoading, setLoading] = useBoolean(true)
  const [formInputCardBrand, setFormInputCardBrand] = useState('unknown')
  const stripe = useStripe()
  const elements = useElements()
  const [isPosting, setIsPosting] = useBoolean(false)
  const [isDeleting, setIsDeleting] = useBoolean(false)

  const registeredCreditCard = useMemo(() => {
    return state.creditCard
  }, [state.creditCard])

  const registeredCreditCardText = useMemo(() => {
    if (registeredCreditCard == null) {
      return ''
    }
    return `●●●● ●●●● ●●●● ${registeredCreditCard.last4} ${registeredCreditCard.month}/${registeredCreditCard.year}`
  }, [registeredCreditCard])
  const registeredCreditCardIcon = useMemo(() => {
    if (registeredCreditCard == null) {
      return null
    }
    const component = cardBrandToComponent(registeredCreditCard.brand)
    return component ?? <CardIcon width={24} fill={Colors.GRAY}></CardIcon>
  }, [registeredCreditCard])

  useEffect(() => {
    const getCreditCard = async () => {
      const response = await GetCreditCardRequest().send()
      dispatch(setCreditCard(response.data.credit_card))
      setLoading(false)
    }
    if (state.creditCard == null) {
      getCreditCard()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    if (stripe === null || elements === null) {
      return
    }
    const sendsGtagEvent = registeredCreditCard == null
    const cardElement = elements?.getElement(CardNumberElement)
    if (cardElement === null) {
      console.error('elements.getElement(CardElement) returns null')
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    const setupResponse = await PostStripeCardSetupRequest().send()
    const clientSecret = setupResponse.data.client_secret
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    })
    if (error) {
      console.error('setupIntent failed', error)
      return
    }

    const payment_method = setupIntent?.payment_method
    if (payment_method) {
      const confirmResponse = await PostStripeCardConfirmRequest({ payment_method }).send()
      if (confirmResponse.data.success) {
        dispatch(setCreditCard(confirmResponse.data.credit_card))
        if (sendsGtagEvent) {
          window.gtag('event', 'クレジットカード登録', { event_category: 'add-card' })
          window.gtag('event', 'add_payment_info')
        }
        alert('クレジットカードを登録しました')
        onSubmit()
      } else {
        alert('クレジットカードの登録に失敗しました')
      }
    }
    setIsPosting(false)
  }

  const handleDeleteCard = async () => {
    if (isDeleting) {
      return
    }
    setIsDeleting(true)
    const response = await DeleteStripeCardRequest()
      .send()
      .catch((e) => e.response)
    if (response.data.success) {
      dispatch(clearCreditCard())
      alert('登録されているクレジットカードを削除しました')
    } else {
      if (response.data.message === 'active alarm found') {
        alert('アラームが設定されているので、クレジットカードを削除できません')
      } else {
        alert('登録されているクレジットカードの削除に失敗しました')
      }
    }
    setIsDeleting(false)
  }

  const handleCardNumberChange = (e: StripeCardNumberElementChangeEvent) => {
    setFormInputCardBrand(e.brand)
  }

  const formInputCreditCardIcon = useMemo(() => {
    const component = cardBrandToComponent(formInputCardBrand)
    return component ?? <CardIcon width={24} fill={Colors.GRAY}></CardIcon>
  }, [formInputCardBrand])

  return (
    <>
      <style type="text/css">{`
    .StripeElement {
      display: block;
      margin: 10px 0 20px 0;
      max-width: 500px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: 'Source Code Pro', monospace;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      background: white;
    }

    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.PaymentRequestButton {
      padding: 0;
    }
  `}</style>
      <View style={{ flex: 1, marginTop: 40, marginHorizontal: 24 }}>
        <Text style={style.subtitle}>クレジットカード</Text>
        {!isLoading && registeredCreditCard != null && (
          <>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {registeredCreditCardIcon}
              <View style={{ width: 12 }}></View>
              <Text
                style={{
                  flex: 1,
                  fontFamily: 'Hiragino Sans',
                  fontWeight: '400',
                  fontSize: 13,
                  color: Colors.DARK,
                  letterSpacing: 0,
                  lineHeight: 16,
                }}
                numberOfLines={1}
                ellipsizeMode={'tail'}
              >
                {registeredCreditCardText}
              </Text>
            </View>
            <View style={{ height: 44 }}></View>
            <Button title={'削除する'} type="action" onPress={handleDeleteCard} loading={isDeleting} />
          </>
        )}
        {!isLoading && registeredCreditCard == null && (
          <>
            <form onSubmit={(e) => e.preventDefault()}>
              <Text>カード番号</Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {formInputCreditCardIcon != null && (
                  <>
                    {formInputCreditCardIcon}
                    <View style={{ width: 12 }}></View>
                  </>
                )}
                <View style={{ flex: 1 }}>
                  <CardNumberElement
                    onChange={handleCardNumberChange}
                    options={{
                      placeholder: '1234 1234 1234 1234',
                      style: {
                        base: {
                          fontSize: '16px',
                        },
                      },
                    }}
                  ></CardNumberElement>
                </View>
              </View>
              <View style={{ height: 8 }}></View>
              <View>
                <Text>有効期限</Text>
                <CardExpiryElement
                  options={{
                    placeholder: '月 / 年',
                    style: {
                      base: {
                        fontSize: '16px',
                      },
                    },
                  }}
                ></CardExpiryElement>
              </View>
              <View style={{ height: 8 }}></View>
              <View>
                <Text>CVC</Text>
                <CardCvcElement
                  options={{
                    placeholder: 'セキュリティコード',
                    style: {
                      base: {
                        fontSize: '16px',
                      },
                    },
                  }}
                ></CardCvcElement>
              </View>
            </form>
            <View style={{ height: 32 }}></View>
            <Button title={'登録する'} type="action" onPress={handleSubmit} loading={isPosting} />
          </>
        )}
      </View>
    </>
  )
}

const PaymentSettingScreen: React.FunctionComponent = () => {
  const history = useHistory()
  const onSubmit = () => {
    // noop
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={() => history.replace('/')}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title}>決済方法</Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>
      <PaymentSettingBody onSubmit={onSubmit}></PaymentSettingBody>
    </View>
  )
}

const PaymentSetting = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentSettingScreen />
    </Elements>
  )
}
export default PaymentSetting
