import * as API from '@/api/Request'
import { User } from '@/api/types/User'
import { Alarm } from '@/api/types/Alarm'

interface PostAuthSignInRequestBody {
  email: string
  password: string
}

interface PostAuthSignInResponse {
  readonly status: string // 'success'
  readonly data: {
    attributes: User
  }
  readonly included: {
    type: string
    attributes: Alarm
  }[]
}

const PostAuthSignInRequest = (body: PostAuthSignInRequestBody) => {
  return API.BuildRequest<PostAuthSignInResponse>({
    method: 'post',
    version: 'v1',
    path: '/auth/sign_in',
    requireAuthentication: false,
    body,
  })
}

export default PostAuthSignInRequest
