import React, { useState, useMemo, useEffect } from 'react'
import { View, StyleSheet, Dimensions, Text, ActivityIndicator } from 'react-native'
import { SwipeButton } from '@/pages/WakeUp/SwipeButton'
import { Overlay } from 'react-native-elements'
import Modal from 'modal-enhanced-react-native-web'
import Map from './Map'
import Done from './Done'
import * as Colors from '@/constants/colors'
import dayjs from 'dayjs'
import { Alarm } from '@/api/types/Alarm'
import { ReactComponent as ArrowIcon } from '@/images/icons/ic_arrow.svg'
import { ReactComponent as CardIcon } from '@/images/cards/card.svg'
import { cardBrandToComponent } from '@/utils'
import PostAlarmWakeUpRequest from '@/api/endpoints/PostAlarmWakeUpRequest'
import { CreditCardContext } from '@/reducers/CreditCard'
import { AlarmContext, removeAlarm } from '@/reducers/Alarm'
import { geocode } from '@/utils/gsi_reverse_geocoder'
import { useLocalStorage } from 'react-use'

type ItemRightIcon = 'amex' | 'diners' | 'jcb' | 'mastercard' | 'visa'

const ListItem = ({ title, rightTitle, placeholderRithtTitle = '', rightIcon, hideArrow = false }: { title: string; rightIcon?: ItemRightIcon; rightTitle: string; placeholderRithtTitle?: string; hideArrow?: boolean }) => {
  const rightIconComponent = useMemo(() => {
    const component = cardBrandToComponent(rightIcon ?? '')
    if (component != null) {
      return component
    }
    return rightIcon != null ? <CardIcon width={24} fill={Colors.GRAY}></CardIcon> : null
  }, [rightIcon])
  return (
    <View
      style={{
        width: '100%',
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          height: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          maxWidth: 64,
          marginLeft: 20,
          marginRight: 16,
          borderBottomWidth: 1,
          borderBottomColor: Colors.WHITE,
        }}
      >
        <Text
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: '400',
            fontSize: 12,
            color: '#999999',
            letterSpacing: 0.5,
            lineHeight: 16,
          }}
        >
          {title}
        </Text>
      </View>
      <View
        style={{
          height: '100%',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: Colors.LIGHT_GRAY,
        }}
      >
        {rightIconComponent != null && (
          <>
            {rightIconComponent}
            <View style={{ width: 12 }}></View>
          </>
        )}
        <Text
          style={{
            flex: 1,
            fontFamily: 'Hiragino Sans',
            fontWeight: '400',
            fontSize: 13,
            color: Colors.DARK,
            letterSpacing: 0,
            lineHeight: 16,
            ...(rightTitle.length > 0 ? {} : { fontSize: 14, color: Colors.DARK_GRAY }),
          }}
          numberOfLines={1}
          ellipsizeMode={'tail'}
        >
          {rightTitle.length > 0 ? rightTitle : placeholderRithtTitle}
        </Text>
        {!hideArrow && (
          <View
            style={{
              paddingHorizontal: 12,
            }}
          >
            <ArrowIcon></ArrowIcon>
          </View>
        )}
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
})

export interface WakeUpProps {
  onWokeUp: (alarmId: number) => void
  onCloseDone: () => void
  alarm: Alarm
}
const WakeUp: React.FunctionComponent<WakeUpProps> = ({ alarm, onWokeUp, onCloseDone }) => {
  const [isVisibleDone, setIsVisibleDone] = useState(false)
  const [currentPosition, setCurrentPosition] = useState<Position | null>(null)
  const creditCardContext = React.useContext(CreditCardContext)
  const alarmContext = React.useContext(AlarmContext)
  const [locationText, setLocationText] = useState('取得しています...')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isPosting, setIsPosting] = useState(false)
  const [, setActiveAlarmId] = useLocalStorage('active-alarm-id', '')

  const handleChangePosition = (position: Position) => {
    setErrorMessage(null)
    setCurrentPosition(position)
  }

  const handleMapError = (error: Error) => {
    setErrorMessage(error.message)
  }

  const distance = useMemo(() => {
    if (currentPosition == null) {
      return 0
    }
    const sleepingLocation = alarm.sleeping_location
    const lat = sleepingLocation.latitude * (Math.PI / 180)
    const lng = sleepingLocation.longitude * (Math.PI / 180)
    const lat2 = currentPosition.coords.latitude * (Math.PI / 180)
    const lng2 = currentPosition.coords.longitude * (Math.PI / 180)
    return 6378.137 * Math.acos(Math.cos(lat) * Math.cos(lat2) * Math.cos(lng2 - lng) + Math.sin(lat) * Math.sin(lat2))
  }, [alarm, currentPosition])

  const isWakeUpable = useMemo(() => {
    if (currentPosition == null) {
      return false
    }
    if (distance < alarm.minimum_distance) {
      return false
    }
    return true
  }, [currentPosition, distance, alarm])

  const onSwipeSuccess = async () => {
    if (currentPosition == null) {
      return
    }
    if (alertValidationErrorIfNeeded()) {
      return
    }
    if (!isWakeUpable) {
      return
    }
    if (isPosting) {
      return
    }
    const id = alarm.id
    const amount = alarm.amount
    try {
      const { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed } = currentPosition.coords
      setIsPosting(true)
      await PostAlarmWakeUpRequest(id, {
        location: {
          latitude,
          longitude,
          altitude: altitude ?? undefined,
          accuracy,
          altitudeAccuracy: altitudeAccuracy ?? undefined,
          heading: heading ?? undefined,
          speed: speed ?? undefined,
        },
      }).send()
      window.gtag('event', 'remove_from_cart', { value: amount })
      onWokeUp(id)
      setIsVisibleDone(true)
    } catch {
      alert('エラーが発生しました')
    } finally {
      setIsPosting(false)
    }
  }

  const alertValidationErrorIfNeeded = (): boolean => {
    if (currentPosition == null) {
      alert('現在位置が取得できていません')
      return true
    }
    if (distance < alarm.minimum_distance) {
      // minimum_distance の表示上だけの下限を 100m にしたい、 80m とか出ると中途半端で微妙ということなので、アラートの文言だけ弄ってる
      // 地図上のサークル表示半径などはちゃんと 80m なら 80m になっている。
      // 50m 単位でアバウトに表示したい。 0.08km なら 80m なので表示は 100m で 0.12km なら 120m なので 150m といった感じ。
      const distance_meter = (Math.ceil(alarm.minimum_distance * 20.0) / 20.0) * 1000.0
      alert(`起床場所から${~~distance_meter}m以上離れてください`)
      return true
    }
    return false
  }

  const done = () => {
    setActiveAlarmId('')
    alarmContext.dispatch(removeAlarm(alarm))
    setIsVisibleDone(false)
    onCloseDone()
  }

  useEffect(() => {
    const { latitude, longitude } = alarm.sleeping_location
    const fetchAddress = async () => {
      const address = await geocode(latitude.toString(), longitude.toString(), true)
      setLocationText(address)
    }
    fetchAddress()
  }, [alarm])

  useEffect(() => {
    // ref: https://pqina.nl/blog/blocking-navigation-gestures-on-ios-13-4/
    const listener = (e: TouchEvent) => {
      e.preventDefault()
    }
    const sheet = document.getElementById('wakeup-sheet') as HTMLDivElement
    sheet.addEventListener('touchstart', listener)
    return () => {
      sheet.removeEventListener('touchstart', listener)
    }
  }, [])

  const handleLocationFailed = () => {
    const navigator = window.navigator as any
    if (navigator.standalone) {
      const isYes = window.confirm('位置情報の取得に失敗しました。少し時間をおいてから再度お試しいただくか、ブラウザでご利用ください。ブラウザで開きますか？')
      if (isYes) {
        const url = new URL(window.location.href)
        window.open(url.origin, '_blank')
      }
    }
  }

  const dateTimeText = useMemo(() => {
    return dayjs(new Date(alarm.fire_at)).format('YYYY/MM/DD (ddd) HH:mm')
  }, [alarm.fire_at])

  const payAmountText = useMemo(() => {
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    })
    if (alarm.amount == null) {
      return ''
    }
    return formatter.format(alarm.amount)
  }, [alarm.amount])

  const creditCardText = useMemo(() => {
    const creditCard = creditCardContext.state.creditCard
    if (creditCard == null) {
      return ''
    }
    return `●●●● ●●●● ●●●● ${creditCard.last4}`
  }, [creditCardContext.state])

  const creditCardBrand = useMemo(() => {
    const creditCard = creditCardContext.state.creditCard
    if (creditCard == null) {
      return
    }
    return creditCard.brand as ItemRightIcon
  }, [creditCardContext.state])

  return (
    <View nativeID={'wakeup-sheet'} style={style.container}>
      <View
        style={{
          position: 'absolute',
          top: -25,
          width: Dimensions.get('window').width,
        }}
      >
        <View
          style={{
            position: 'relative',
            zIndex: -1,
          }}
        >
          <Map alarm={alarm} onChangePosition={handleChangePosition} onError={handleMapError} onLocationFailed={handleLocationFailed} />
          {errorMessage ? (
            <View style={{ backgroundColor: Colors.DESTRUCTIVE, height: 58, width: '100%', opacity: 0.9, alignItems: 'center', justifyContent: 'center' }}>
              <Text
                style={{
                  fontFamily: 'Hiragino Sans',
                  fontWeight: '200',
                  fontSize: 18,
                  color: '#FFFFFF',
                  letterSpacing: 0.88,
                  lineHeight: 16,
                }}
              >
                {errorMessage}
              </Text>
            </View>
          ) : (
            <SwipeButton onSwipeSuccess={onSwipeSuccess} disabled={false} />
          )}
        </View>
        <View style={{ height: 10 }}></View>
        <View style={{ zIndex: -1 }}>
          <ListItem title={'起床場所'} rightTitle={locationText} hideArrow={true} />
        </View>
        <View>
          <ListItem title={'目覚時間'} rightTitle={dateTimeText} hideArrow={true} />
        </View>
        <View>
          <ListItem title={'覚悟金額'} rightTitle={payAmountText} hideArrow={true} />
        </View>
        <View>
          <ListItem title={'支払方法'} rightIcon={creditCardBrand} rightTitle={creditCardText} hideArrow={true} />
        </View>
      </View>
      <Modal isVisible={isVisibleDone} style={{ margin: 0 }}>
        <Done onClose={done} success={true} />
      </Modal>
      <Overlay isVisible={isPosting} ModalComponent={Modal} backdropStyle={{ opacity: 0 }} overlayStyle={{ shadowOpacity: 0, backgroundColor: 'transparent' }}>
        <ActivityIndicator color={Colors.PRIMARY_ORANGE} size={'large'}></ActivityIndicator>
      </Overlay>
    </View>
  )
}
export default WakeUp
