import * as API from '@/api/Request'
import { User } from '@/api/types/User'

interface PutAuthResetPasswordRequestBody {
  password: string
  password_confirmation: string
}

interface PutAuthResetPasswordResponse {
  readonly success: boolean
  readonly data: {
    attributes: User
  }
  readonly message: string
}

const PutAuthResetPasswordRequest = (body: PutAuthResetPasswordRequestBody, uid: string, client: string, accessToken: string) => {
  const req = API.BuildRequest<PutAuthResetPasswordResponse>({
    method: 'put',
    version: 'v1',
    path: '/auth',
    requireAuthentication: false,
    body: body,
  })
  req.config.headers = { uid: uid, client: client, 'access-token': accessToken }
  return req
}

export default PutAuthResetPasswordRequest
