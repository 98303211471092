import axios from 'axios'
import { GSI_MUNI_ARRAY } from '@/utils/gsi_muni'

export const geocode = async (latitude: string, longitude: string, cache = false): Promise<string> => {
  const cacheKey = 'gsi-reverse-geocode-cache'
  const geocodeCache = JSON.parse(window.localStorage.getItem(cacheKey) ?? 'null') as {
    latitude: string
    longitude: string
    address: string
  } | null

  // 西経から始まったりすると経度がマイナスになったりなんだりするので、0 ~ 360 度の範囲に収まるようにする。
  while (parseFloat(longitude) < 0.0) {
    longitude = `${parseFloat(longitude) + 360.0}`
  }
  while (360.0 < parseFloat(longitude)) {
    longitude = `${parseFloat(longitude) - 360.0}`
  }
  // 地図から取得した場合とAPIから取得した場合とで小数点以下の桁数が異なるので、8桁で揃える
  latitude = latitude.replace(/(\d+\.\d{8})\d*/, '$1')
  longitude = longitude.replace(/(\d+\.\d{8})\d*/, '$1')
  if (cache && geocodeCache && latitude === geocodeCache.latitude && longitude === geocodeCache.longitude) {
    return Promise.resolve(geocodeCache.address)
  }

  return new Promise((resolve) => {
    axios
      .get('https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress', {
        params: {
          lon: longitude,
          lat: latitude,
        },
      })
      .then((response) => {
        const results = response.data.results
        if (results) {
          const muniCd: keyof typeof GSI_MUNI_ARRAY = results.muniCd
          const address_csv = GSI_MUNI_ARRAY[muniCd]
          if (address_csv) {
            const address_array = address_csv.split(',')
            const address = address_array[1] + address_array[3] + results.lv01Nm
            if (cache) {
              window.localStorage.setItem(
                cacheKey,
                JSON.stringify({
                  latitude,
                  longitude,
                  address,
                })
              )
            }
            resolve(address)
          } else {
            // 海外でも使えるように住所ラベルの部分に緯度経度を放り込むような感じにした
            // reject(response)
            resolve(`緯度: ${latitude}, 経度: ${longitude}`)
          }
        } else {
          // 海外でも使えるように住所ラベルの部分に緯度経度を放り込むような感じにした
          // reject(response)
          resolve(`緯度: ${latitude}, 経度: ${longitude}`)
        }
      })
  })
}
