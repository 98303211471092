import React, { useState, useMemo } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'
import { useHistory } from 'react-router-dom'
import PostAuthSignInRequest from '@/api/endpoints/PostAuthSignInRequest'
import { Button } from '@/components/Button'
import { Link } from '@/components/Link'
import * as Colors from '@/constants/colors'
import { CreditCardContext, setCreditCard } from '@/reducers/CreditCard'
import { AlarmContext, setAlarms } from '@/reducers/Alarm'
import { CurrentUserContext, setCurrentUser } from '@/reducers/CurrentUser'
import { useLocalStorage } from 'react-use'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 16,
    fontSize: 20,
    color: Colors.DARK,
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    letterSpacing: 1,
    lineHeight: 32,
  },
  inputLabel: {
    fontSize: 11,
  },
  inputBase: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '300',
    fontSize: 16,
    color: Colors.LIGHT_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  containerFocus: {
    borderColor: Colors.PRIMARY_ORANGE,
  },
  containerError: {
    borderColor: Colors.DESTRUCTIVE,
  },
  inputFocus: {
    color: Colors.PRIMARY_ORANGE,
  },
  inputError: {
    color: Colors.DESTRUCTIVE,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 11,
    lineHeight: 22,
    letterSpacing: 0,
  },
  linkableText: {
    color: Colors.DARK,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: Colors.DARK_GRAY,
  },
})

export interface SignInContentProps {
  onClose: () => void
}

const SignInContent: React.FunctionComponent<SignInContentProps> = ({ onClose }) => {
  const history = useHistory()
  const creditCardContext = React.useContext(CreditCardContext)
  const alarmContext = React.useContext(AlarmContext)
  const currentUserContext = React.useContext(CurrentUserContext)

  const [email, setEmail] = useState('')
  const [emailFocused, setEmailFocused] = useState(false)
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [isPasswordDirty, setIsPasswordDirty] = useState(false)
  const [isPosting, setIsPosting] = useState(false)
  const [doneInitialLogin, setDoneInitialLogin] = useLocalStorage('done-initial-login', '')

  const emailError = useMemo(() => {
    if (!isEmailDirty) {
      return ''
    }
    if (email.length === 0) {
      return 'メールアドレスを入力してください'
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return 'メールアドレスを正しく入力してください'
    }
    return ''
  }, [email, isEmailDirty])

  const passwordError = useMemo(() => {
    if (!isPasswordDirty) {
      return ''
    }
    if (password.length === 0) {
      return 'パスワードを入力してください'
    }
    if (password.length < 8) {
      return 'パスワードが短すぎます'
    }
    if (password.length > 255) {
      return 'パスワードが長すぎます'
    }
    return ''
  }, [password, isPasswordDirty])

  const emailStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.inputFocus : {}, emailError ? style.inputError : {}])
  }, [emailError, emailFocused])
  const emailInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, email.length > 0 ? { color: Colors.DARK } : {}])
  }, [email])
  const emailContainerStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.containerFocus : {}, emailError ? style.containerError : {}])
  }, [emailError, emailFocused])

  const passwordStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.inputFocus : {}, passwordError ? style.inputError : {}])
  }, [passwordError, passwordFocused])
  const passwordInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, password.length > 0 ? { color: Colors.DARK } : {}])
  }, [password])
  const passwordContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.containerFocus : {}, passwordError ? style.containerError : {}])
  }, [passwordError, passwordFocused])

  const isSubmitButtonEnabled = useMemo(() => {
    return emailError.length === 0 && email.length > 0 && passwordError.length === 0 && password.length > 0
  }, [email.length, emailError.length, password.length, passwordError.length])

  const handleOnSubmit = async () => {
    if (!isSubmitButtonEnabled) {
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    try {
      const response = await PostAuthSignInRequest({
        email: email,
        password: password,
      }).send()
      const creditCard = response.data.data.attributes.credit_card
      if (creditCard != null) {
        creditCardContext.dispatch(setCreditCard(creditCard))
      }
      {
        const { id, email, tracking_info, stats } = response.data.data.attributes
        currentUserContext.dispatch(setCurrentUser({ id, email, trackingInfo: tracking_info, stats }))
      }
      const alarms = response.data.included.filter((obj) => obj.type === 'alarm').map((obj) => obj.attributes)
      alarmContext.dispatch(setAlarms(alarms))
      console.log(doneInitialLogin)
      if (!doneInitialLogin) {
        setDoneInitialLogin('true')
        window.gtag('event', '初回ログイン', { event_category: 'sign-in' })
      }
      alert('ログインしました')
      history.push('/')
    } catch (e) {
      const errors = e.response?.data?.errors
      if (errors?.length > 0) {
        alert(errors[0])
      } else {
        alert('ログインに失敗しました')
      }
    }
    setIsPosting(false)
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={onClose}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title} selectable={false}>
            {'メールアドレスを\n入力してログインしましょう'}
          </Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>

      <View style={{ height: 60 }} pointerEvents={'none'} />

      <View style={{ marginHorizontal: 24 }}>
        <View>
          <Input
            label={'メールアドレス'}
            labelProps={{ style: [style.inputLabel, emailStyle] }}
            inputContainerStyle={emailContainerStyle}
            inputStyle={emailInput}
            labelStyle={emailStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="メールアドレス"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={emailError}
            autoFocus={false}
            autoCapitalize={'none'}
            value={email}
            onChangeText={(text) => setEmail(text)}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => {
              setEmailFocused(false)
              setIsEmailDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <Input
            label={'パスワード'}
            labelProps={{ style: [style.inputLabel, passwordStyle] }}
            inputContainerStyle={passwordContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="パスワード"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordError}
            autoCapitalize={'none'}
            secureTextEntry={true}
            value={password}
            onChangeText={setPassword}
            onFocus={() => setPasswordFocused(true)}
            onBlur={() => {
              setPasswordFocused(false)
              setIsPasswordDirty(true)
            }}
          />
        </View>
        <View style={{ height: 30 }} />
        <Text style={style.note}>
          <Link onPress={() => history.push('/forgot_password')} style={style.linkableText}>{'パスワードをお忘れの方'}</Link>
          {' はこちらより復旧できます。\n'}
          <Link href={'https://mezamee.com/privacy/'} style={style.linkableText}>
            {'プライバシーポリシー'}
          </Link>
          {' はこちらをご確認ください。'}
        </Text>
        <View style={{ height: 48 }} />
        <View>
          <Button title={'ログイン'} type="action" disabled={!isSubmitButtonEnabled} onPress={handleOnSubmit} loading={isPosting} />
        </View>
        <View style={{ height: 96 }} />
      </View>
    </View>
  )
}

export default SignInContent
