import React, { useMemo, useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { useHistory } from 'react-router-dom'
import { useBoolean } from 'react-use'
import * as Colors from '@/constants/colors'
import { Button } from '@/components/Button'
import PutAuthChangePasswordRequest from '@/api/endpoints/PutAuthChangePasswordRequest'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    flex: 1,
    textAlign: 'left',
    marginHorizontal: 24,
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 24,
    color: '#2D2D2D',
    letterSpacing: 1,
    lineHeight: 30,
  },
  subtitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 15,
    color: '#2D2D2D',
    letterSpacing: 0,
    lineHeight: 32,
  },
  containerFocus: {
    borderColor: Colors.PRIMARY_ORANGE,
  },
  containerError: {
    borderColor: Colors.DESTRUCTIVE,
  },
  inputLabel: {
    fontSize: 11,
  },
  inputBase: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '300',
    fontSize: 16,
    color: Colors.LIGHT_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  inputFocus: {
    color: Colors.PRIMARY_ORANGE,
  },
  inputError: {
    color: Colors.DESTRUCTIVE,
  },
})

const PasswordSettingScreen: React.FunctionComponent = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [isPasswordDirty, setIsPasswordDirty] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordConfirmFocused, setPasswordConfirmFocused] = useState(false)
  const [isPasswordConfirmDirty, setIsPasswordConfirmDirty] = useState(false)
  const [isPosting, setIsPosting] = useBoolean(false)

  const passwordError = useMemo(() => {
    if (!isPasswordDirty) {
      return ''
    }
    if (password.length === 0) {
      return 'パスワードを入力してください'
    }
    if (password.length < 8) {
      return 'パスワードが短すぎます'
    }
    if (password.length > 255) {
      return 'パスワードが長すぎます'
    }
    return ''
  }, [password, isPasswordDirty])
  const passwordStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.inputFocus : {}, passwordError ? style.inputError : {}])
  }, [passwordError, passwordFocused])
  const passwordInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, password.length > 0 ? { color: Colors.DARK } : {}])
  }, [password])
  const passwordContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.containerFocus : {}, passwordError ? style.containerError : {}])
  }, [passwordError, passwordFocused])

  const passwordConfirmError = useMemo(() => {
    if (!isPasswordConfirmDirty) {
      return ''
    }
    if (password !== passwordConfirm) {
      return 'パスワード(確認)に誤りがあります'
    }
    return ''
  }, [isPasswordConfirmDirty, password, passwordConfirm])
  const passwordConfirmStyle = useMemo(() => {
    return StyleSheet.flatten([passwordConfirmFocused ? style.inputFocus : {}, passwordConfirmError ? style.inputError : {}])
  }, [passwordConfirmError, passwordConfirmFocused])
  const passwordConfirmContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordConfirmFocused ? style.containerFocus : {}, passwordConfirmError ? style.containerError : {}])
  }, [passwordConfirmError, passwordConfirmFocused])

  const isSubmitButtonEnabled = useMemo(() => {
    return passwordError.length === 0 && password.length > 0 && passwordConfirmError.length === 0 && passwordConfirm.length > 0
  }, [password.length, passwordConfirm.length, passwordConfirmError.length, passwordError.length])

  const handleOnSubmit = async () => {
    if (!isSubmitButtonEnabled) {
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    try {
      await PutAuthChangePasswordRequest({
        password: password,
        password_confirmation: passwordConfirm,
      }).send()
      alert('パスワードを変更しました')
      history.replace('/')
    } catch (e) {
      console.error(e)
      alert('パスワードの変更に失敗しました')
    }
    setIsPosting(false)
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={() => history.replace('/')}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title}>パスワード変更</Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>
      <View style={{ flex: 1, marginTop: 40, marginHorizontal: 24 }}>
        <View>
          <Input
            label={'新しいパスワード'}
            labelProps={{ style: [style.inputLabel, passwordStyle] }}
            inputContainerStyle={passwordContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="新しいパスワード"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordError}
            secureTextEntry={true}
            autoCapitalize={'none'}
            value={password}
            onChangeText={setPassword}
            onFocus={() => setPasswordFocused(true)}
            onBlur={() => {
              setPasswordFocused(false)
              setIsPasswordDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <Input
            label={'新しいパスワード(確認)'}
            labelProps={{
              style: [style.inputLabel, passwordConfirmStyle],
            }}
            inputContainerStyle={passwordConfirmContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordConfirmStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="新しいパスワード(確認)"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordConfirmError}
            secureTextEntry={true}
            value={passwordConfirm}
            autoCapitalize={'none'}
            onChangeText={setPasswordConfirm}
            onFocus={() => setPasswordConfirmFocused(true)}
            onBlur={() => {
              setPasswordConfirmFocused(false)
              setIsPasswordConfirmDirty(true)
            }}
          />
        </View>
      </View>
      <View style={{ position: 'absolute', bottom: 74, right: 0, left: 0 }}>
        <Button title={'パスワードを変更'} type="action" disabled={!isSubmitButtonEnabled} onPress={handleOnSubmit} loading={isPosting} />
      </View>
    </View>
  )
}

const PasswordSetting = () => {
  return <PasswordSettingScreen />
}
export default PasswordSetting
