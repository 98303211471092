import * as API from '@/api/Request'
import { User } from '@/api/types/User'

interface GetMeResponse {
  readonly status: string // 'success'
  readonly data: {
    attributes: User
  }
}

const GetMeRequest = () => {
  return API.BuildRequest<GetMeResponse>({
    method: 'get',
    version: 'v1',
    path: '/me',
    requireAuthentication: true,
  })
}

export default GetMeRequest
