import React, { forwardRef, useImperativeHandle, useState, useMemo } from 'react'
import { View, StyleSheet, Dimensions, Text, TouchableOpacity } from 'react-native'
import * as Colors from '@/constants/colors'
import { Button } from '@/components/Button'
import { AlarmSettingContext, setDateTime } from '@/reducers/AlarmSetting'
import { Resettable } from '.'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import dayjs from 'dayjs'

export interface SettingDateTimeProps {
  onPressClose: () => void
}
const SettingDateTime = forwardRef<Resettable, SettingDateTimeProps>(function SettingDateTime({ onPressClose }, ref) {
  const { state, dispatch } = React.useContext(AlarmSettingContext)
  const dateTime = state.dateTime ?? new Date()
  const [year, setYear] = useState(dateTime.getFullYear())
  const [month, setMonth] = useState(dateTime.getMonth() + 1)
  const [date, setDate] = useState(dateTime.getDate())
  const [hour, setHour] = useState(dateTime.getHours())
  const [minute, setMinute] = useState(dateTime.getMinutes())

  useImperativeHandle(ref, () => {
    return {
      reset() {
        const d = state.dateTime ?? new Date()
        setYear(d.getFullYear())
        setMonth(d.getMonth() + 1)
        setDate(d.getDate())
        setHour(d.getHours())
        setMinute(d.getMinutes())
      },
    }
  })

  const dates = useMemo(() => {
    const d = new Date(year, month - 1, 1)
    const endOfMonth = dayjs(d).endOf('month')
    return [...Array(endOfMonth.date()).keys()].map((v) => v + 1)
  }, [year, month])

  const dateTimeSet = useMemo(() => {
    return dayjs()
      .year(year)
      .month(month - 1)
      .date(date)
      .hour(hour)
      .minute(minute)
      .second(59)
      .millisecond(999)
      .toDate()
  }, [date, hour, minute, month, year])

  const isValid = dayjs(dateTimeSet).isAfter(new Date())

  const captionText = (() => {
    if (isValid) {
      const diffHour = dayjs(dateTimeSet).diff(new Date(), 'hour')
      const diffMinute = dayjs(dateTimeSet).diff(new Date(), 'minute') - 60 * diffHour
      return diffHour === 0 ? 'あと残りわずか' : `あと ${diffHour} 時間 ${diffMinute} 分後`
    } else {
      return '目覚時間を選択してください'
    }
  })()

  const onSubmit = () => {
    dispatch(setDateTime(dateTimeSet))
    onPressClose()
  }

  return (
    <View style={style.container}>
      <View style={{ height: 8 }} />
      <View style={style.header}>
        <View style={{ flex: 1 }} />
        <TouchableOpacity style={style.closeButton} onPress={onPressClose}>
          <BtnClose></BtnClose>
        </TouchableOpacity>
      </View>
      <View style={{ marginHorizontal: 24 }}>
        <Text style={style.title}>目覚時間</Text>
        <View style={{ height: 30 }} />
        <Text style={style.inputLabel}>{'年 / 月 / 日 時間'}</Text>
        <View style={{ height: 10 }} />
        <View style={style.inputGroup}>
          <View style={{ position: 'relative' }}>
            <Text style={style.selectText}>{year}</Text>
            <View style={{ opacity: 0, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              <select value={year} style={{ width: '100%', height: '100%', fontSize: '16px', transform: 'scale(calc(12 / 16))' }} onChange={(event) => setYear(parseInt(event.target.value))}>
                <option key="2020" value="2020">
                  2020
                </option>
                <option key="2021" value="2021">
                  2021
                </option>
              </select>
            </View>
          </View>
          <Text style={style.selectSeparator}>/</Text>
          <View style={{ position: 'relative' }}>
            <Text style={style.selectText}>{month.toString().padStart(2, '0')}</Text>
            <View style={{ opacity: 0, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              <select value={month} style={{ width: '100%', height: '100%', fontSize: '16px', transform: 'scale(calc(12 / 16))' }} onChange={(event) => setMonth(parseInt(event.target.value))}>
                {[...Array(12).keys()].map((v) => {
                  const m = v + 1
                  return (
                    <option key={m} value={m}>
                      {m.toString().padStart(2, '0')}
                    </option>
                  )
                })}
              </select>
            </View>
          </View>
          <Text style={style.selectSeparator}>/</Text>
          <View style={{ position: 'relative' }}>
            <Text style={style.selectText}>{date.toString().padStart(2, '0')}</Text>
            <View style={{ opacity: 0, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              <select value={date} style={{ width: '100%', height: '100%', fontSize: '16px', transform: 'scale(calc(12 / 16))' }} onChange={(event) => setDate(parseInt(event.target.value))}>
                {dates.map((v) => {
                  return (
                    <option key={v} value={v}>
                      {v.toString().padStart(2, '0')}
                    </option>
                  )
                })}
              </select>
            </View>
          </View>
          <Text style={style.selectSeparator} />
          <View style={{ position: 'relative' }}>
            <Text style={style.selectText}>{hour.toString().padStart(2, '0')}</Text>
            <View style={{ opacity: 0, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              <select value={hour} style={{ width: '100%', height: '100%', fontSize: '16px', transform: 'scale(calc(12 / 16))' }} onChange={(event) => setHour(parseInt(event.target.value))}>
                {[...Array(24).keys()].map((v) => {
                  const h = v
                  return (
                    <option key={h} value={h}>
                      {h.toString().padStart(2, '0')}
                    </option>
                  )
                })}
              </select>
            </View>
          </View>
          <Text style={style.selectSeparator}>:</Text>
          <View style={{ position: 'relative' }}>
            <Text style={style.selectText}>{minute.toString().padStart(2, '0')}</Text>
            <View style={{ opacity: 0, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              <select value={minute} style={{ width: '100%', height: '100%', fontSize: '16px', transform: 'scale(calc(12 / 16))' }} onChange={(event) => setMinute(parseInt(event.target.value))}>
                {[...Array(12).keys()].map((v) => {
                  const m = v * 5
                  return (
                    <option key={m} value={m}>
                      {m.toString().padStart(2, '0')}
                    </option>
                  )
                })}
              </select>
            </View>
          </View>
        </View>
        <View style={{ height: 9 }} />
        <Text style={style.caption}>{captionText}</Text>
        <View style={{ height: 30 }} />
        <Text style={style.note}>家を出る時間を設定してください。メザミーではアラームは鳴らないので、必要であれば別途設定する必要があります。</Text>
      </View>

      <View style={{ position: 'absolute', bottom: 74, right: 0, left: 0 }}>
        <Button title={'登録する'} type="action" onPress={onSubmit} disabled={!isValid} />
      </View>
    </View>
  )
})

const style = StyleSheet.create({
  container: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    backgroundColor: '#ffffff',
  },
  title: {
    marginTop: 0,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeButton: {
    width: 56,
    height: 56,
    marginLeft: 8,
  },
  caption: {
    color: Colors.DESTRUCTIVE,
    fontSize: 11,
    fontWeight: '600',
  },
  inputLabel: {
    color: '#BBBBBB',
    fontSize: 11,
    fontWeight: '600',
  },
  inputGroup: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  selectText: {
    fontSize: 28,
    fontWeight: '600',
    color: Colors.DARK,
    paddingBottom: 14,
    borderBottomColor: Colors.TERTIARY,
    borderBottomWidth: 2,
  },
  selectSeparator: {
    fontSize: 28,
    color: '#C3C3C3',
    marginHorizontal: 5,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontSize: 11,
    lineHeight: 18,
  },
})

export default SettingDateTime
