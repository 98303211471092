import { StyleSheet } from 'react-native'
import * as Colors from '@/constants/colors'

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: '#00000077',
  },
  mask: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  container: {
    backgroundColor: '#fff',
    width: '100%',
    height: 0,
    overflow: 'hidden',
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
  },
  draggableContainer: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 999,
  },
  draggableIcon: {
    width: 45,
    height: 5,
    borderRadius: 5,
    margin: 10,
    backgroundColor: Colors.DARK,
  },
})

export default styles
