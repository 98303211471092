import React, { useEffect, useMemo } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { ReactComponent as BtnCancel } from '@/images/buttons/btn_cancel.svg'
import { Circle } from 'rc-progress'
import * as Colors from '@/constants/colors'

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
  headTitle: {
    textAlign: 'center',
    color: Colors.WHITE,
    fontSize: 16,
  },
  body: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: Colors.WHITE,
    fontSize: 32,
  },
  cancelText: {
    textAlign: 'center',
    fontWeight: '600',
    color: Colors.WHITE,
    fontSize: 14,
  },
  count: {
    textAlign: 'center',
    fontWeight: '600',
    color: Colors.WHITE,
    fontSize: 24,
  },
  closeButtonContainer: {
    marginHorizontal: 'auto',
    width: 56,
    height: 56,
  },
  closeButton: {
    marginHorizontal: 'auto',
    position: 'absolute',
  },
})

export interface DoneProps {
  onClose: () => void
  success: boolean
}
const Done: React.FunctionComponent<DoneProps> = ({ onClose, success }) => {
  const onPressCloseButton = () => {
    onClose()
  }

  useEffect(() => {
    // ref: https://pqina.nl/blog/blocking-navigation-gestures-on-ios-13-4/
    const listener = (e: TouchEvent) => {
      e.preventDefault()
    }
    const sheet = document.getElementById('done') as HTMLDivElement
    sheet.addEventListener('touchstart', listener)
    return () => {
      sheet.removeEventListener('touchstart', listener)
    }
  }, [])

  const headTitle = useMemo(() => {
    return success ? 'You made it!' : 'What a pity!'
  }, [success])

  const body = useMemo(() => {
    return success ? 'Have a Nice Day' : 'Never Give Up'
  }, [success])

  return (
    <View style={style.container} nativeID={'done'}>
      <div
        style={{
          zIndex: -1,
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundImage: 'radial-gradient(circle at 100% 0%, rgb(255, 231, 46) 0%, rgb(255, 173, 47) 50%)',
        }}
      />
      <View style={{ height: 76 }} />
      <Text style={StyleSheet.flatten([style.headTitle])}>{headTitle}</Text>
      <Text style={StyleSheet.flatten([style.body])}>{body}</Text>
      <View style={{ flex: 1 }} />
      <View style={{ height: 48 }} />
      <View style={style.closeButtonContainer}>
        <Circle style={{ width: 56, height: 56 }} strokeLinecap={'butt'} strokeWidth={3} trailWidth={4} strokeColor={Colors.WHITE} trailColor={'rgba(255, 255, 255, 0.1)'} percent={100} />
        <TouchableOpacity style={style.closeButton} onPress={onPressCloseButton}>
          <BtnCancel style={{ stroke: Colors.WHITE }} />
        </TouchableOpacity>
      </View>
      <View style={{ height: 80 }} />
    </View>
  )
}

export default Done
