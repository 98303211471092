import * as API from '@/api/Request'
import { CreditCard } from '@/api/types/CreditCard'

interface PostStripeCardConfirmRequestBody {
  payment_method: string
}

interface PostStripeCardConfirmResponse {
  readonly success: boolean
  readonly credit_card: CreditCard
}

const PostStripeCardConfirmRequest = (body: PostStripeCardConfirmRequestBody) => {
  return API.BuildRequest<PostStripeCardConfirmResponse>({
    method: 'post',
    version: 'v1',
    path: '/stripe/card/confirm',
    requireAuthentication: true,
    body,
  })
}

export default PostStripeCardConfirmRequest
