import React, { useEffect, useMemo, useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { AlarmContext, setAlarms } from '@/reducers/Alarm'
import { useHistory } from 'react-router-dom'
import GetAlarmsRequest from '@/api/endpoints/GetAlarmsRequest'
import * as Colors from '@/constants/colors'
import dayjs from 'dayjs'
import GetMeRequest from '@/api/endpoints/GetMeRequest'
import { CurrentUserContext, setCurrentUser } from '@/reducers/CurrentUser'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    flex: 1,
    textAlign: 'left',
    marginHorizontal: 24,
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 24,
    color: Colors.DARK,
    letterSpacing: 1,
    lineHeight: 30,
  },
  itemTitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 15,
    color: Colors.DARK,
    letterSpacing: 0,
    lineHeight: 18,
  },
  itemTime: {
    fontFamily: 'Helvetica Neue,Hiragino Sans',
    fontSize: 14,
    color: Colors.DARK_GRAY,
    letterSpacing: 0,
    lineHeight: 18,
  },
  itemAmount: {
    fontFamily: 'Helvetica Neue,Hiragino Sans',
    fontSize: 22,
    color: Colors.DARK_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
    marginRight: 4,
  },
  itemMessage: {
    fontFamily: 'Hiragino Sans',
    fontSize: 12,
    color: Colors.DARK_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  primary: {
    color: Colors.PRIMARY_ORANGE,
  },
  statsTitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 12,
    color: Colors.PRIMARY_ORANGE,
    letterSpacing: 0.55,
    lineHeight: 20,
    textAlign: 'center',
  },
  statsValue: {
    fontFamily: 'Helvetica Neue,Hiragino Sans',
    fontSize: 56,
    color: Colors.DARK,
    letterSpacing: 0.55,
    lineHeight: 60,
    textAlign: 'center',
  },
  statsSubValue: {
    fontFamily: 'Helvetica Neue,Hiragino Sans',
    fontSize: 30,
    color: Colors.DARK,
    letterSpacing: 0,
    lineHeight: 40,
    textAlign: 'center',
  },
  statsUnit: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 11,
    color: Colors.DARK_GRAY,
    letterSpacing: 0,
    lineHeight: 20,
    textAlign: 'center',
  },
})

interface DisplayAlarm {
  id: string
  title: string
  displayTime: string
  amount: string
  message: string
  primary: boolean
}

interface DisplayStats {
  wakeUpSuccessRate: string
  awokeAlarmsCount: string
  totalAvoidanceAmount: string
}

const History: React.FunctionComponent = () => {
  const history = useHistory()
  const alarmContext = React.useContext(AlarmContext)
  const currentUserContext = React.useContext(CurrentUserContext)

  useEffect(() => {
    const getMe = async () => {
      const response = await GetMeRequest().send()
      const { id, email, tracking_info, stats } = response.data.data.attributes
      currentUserContext.dispatch(setCurrentUser({ id, email, trackingInfo: tracking_info, stats }))
    }
    // TODO: 毎回 API 問い合わせで通信しているので後でパフォーマンスチューニングの対象になるかも
    getMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getAlarms = async () => {
      setIsLoading(true)
      const response = await GetAlarmsRequest().send()
      const alarms = response.data.data.map((obj) => {
        return obj.attributes
      })
      alarmContext.dispatch(setAlarms(alarms))
      setIsLoading(false)
    }
    // アラームのキャッシュをクライアントで変更している場合は念の為取り直す
    const fetch = alarmContext.state.alarms.length === 0 || alarmContext.state.isDirty
    if (fetch) {
      getAlarms()
    } else {
      // ログイン直後でアラームを既に取得している
      const alarms = alarmContext.state.alarms
      alarmContext.dispatch(setAlarms(alarms))
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const alarms = useMemo(() => {
    const alarms = alarmContext.state.alarms.filter((alarm) => alarm?.payment_status !== 'undecided')
    alarms.reverse()
    return alarms.map(
      (alarm): DisplayAlarm => {
        const formatter = new Intl.NumberFormat('ja-JP', {
          style: 'currency',
          currency: 'JPY',
        })
        const amount = formatter.format(alarm.amount)
        const title = (() => {
          if (['no_need_to_pay'].includes(alarm?.payment_status)) {
            if (alarm?.canceled_at !== null) {
              return 'キャンセル'
            } else {
              return '目覚め'
            }
          }
          if (['paid', 'payment_processing', 'banned', 'refunded'].includes(alarm?.payment_status)) {
            return '覚悟の金額'
          }
          return ''
        })()
        const message = (() => {
          switch (alarm?.payment_status) {
            case 'no_need_to_pay':
              return '課金されません'
            case 'payment_processing':
              return '課金処理中'
            case 'paid':
              return '課金されました'
            case 'banned':
              return '支払いエラー\nお問い合わせください'
            case 'refunded':
              return '返金されました'
          }
          return ''
        })()
        const displayTime = dayjs(new Date(alarm.history_display_time ?? 0)).format('YYYY/M/DD（ddd）HH : mm')
        return {
          id: alarm.id.toString(),
          displayTime,
          title,
          amount,
          message,
          primary: ['paid', 'payment_processing', 'banned'].includes(alarm?.payment_status),
        }
      }
    )
  }, [alarmContext.state])

  const stats = useMemo(() => {
    const stats = currentUserContext.state.stats
    if (!stats) {
      return null
    }
    const formatter = new Intl.NumberFormat('ja-JP')
    return {
      wakeUpSuccessRate: Math.round(stats.wake_up_success_rate * 100).toString(),
      awokeAlarmsCount: stats.awoke_alarms_count.toString(),
      totalAvoidanceAmount: formatter.format(stats.total_avoidance_amount).toString(),
    } as DisplayStats
  }, [currentUserContext.state])

  const renderItem = ({ item }: { item: DisplayAlarm }) => {
    return (
      <View style={{ height: 82, marginHorizontal: 24 }}>
        <View style={{ backgroundColor: Colors.ALPHA_DARK, height: 1, width: '100%' }}></View>
        <View style={{ flexDirection: 'row', width: '100%', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', flex: 1 }}>
            <Text style={style.itemTitle}>{item.title}</Text>
            <View style={{ width: '100%', height: 8 }}></View>
            <Text style={style.itemTime}>{item.displayTime}</Text>
          </View>
          <View style={{ flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', flex: 1 }}>
            <Text style={StyleSheet.flatten([style.itemAmount, item.primary ? style.primary : {}])}>{item.amount}</Text>
            <View style={{ width: '100%', height: 8 }}></View>
            <Text style={style.itemMessage}>( {item.message} )</Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={() => history.replace('/')}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title}>履歴</Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>
      {isLoading && alarms.length === 0 && (
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1 }}></View>
          <ActivityIndicator color={Colors.PRIMARY_ORANGE} size={'large'}></ActivityIndicator>
          <View style={{ flex: 1 }}></View>
        </View>
      )}
      {!isLoading && stats != null && (
        <View style={{ marginHorizontal: 24, marginBottom: 50, marginTop: 30 }}>
          <View style={{ flexDirection: 'row', width: '100%', flex: 1, alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <View style={{ flexDirection: 'column', alignItems: 'center' }}>
              <Text style={StyleSheet.flatten([style.statsTitle, { marginBottom: 15 }])}>{'目覚め率'}</Text>
              <Text style={style.statsSubValue}>{stats.wakeUpSuccessRate}</Text>
              <Text style={style.statsUnit}>{'%'}</Text>
            </View>
            <View style={{ flexDirection: 'column', alignItems: 'center' }}>
              <Text style={StyleSheet.flatten([style.statsTitle, { marginBottom: 8 }])}>{'目覚め回数'}</Text>
              <Text style={style.statsValue}>{stats.awokeAlarmsCount}</Text>
              <View style={{ height: 8 }}></View>
            </View>
            <View style={{ flexDirection: 'column', alignItems: 'center' }}>
              <Text style={StyleSheet.flatten([style.statsTitle, { marginBottom: 8 }])}>{'回避された\n『覚悟の金額』'}</Text>
              <Text style={style.statsSubValue}>{stats.totalAvoidanceAmount}</Text>
              <Text style={style.statsUnit}>{'円'}</Text>
            </View>
          </View>
        </View>
      )}
      <FlatList data={alarms} renderItem={renderItem} keyExtractor={(item) => item.id.toString()} />
    </View>
  )
}

export default History
