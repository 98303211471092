import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import PostStripeCardSetupRequest from '@/api/endpoints/PostStripeCardSetupRequest'
import PostStripeCardConfirmRequest from '@/api/endpoints/PostStripeCardConfirmRequest'
import DeleteStripeCardRequest from '@/api/endpoints/DeleteStripeCardRequest'

// loadStripe() を呼ぶと <head> か <body> に (head 優先で) <script> タグが挿入される。
// 既に <script> タグがあれば、それを使う実装になっている。
// https://github.com/stripe/stripe-js/blob/v1.0.2/src/index.ts
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)

const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (stripe === null || elements === null) {
      return
    }
    const cardElement = elements.getElement(CardElement)
    if (cardElement === null) {
      console.log('[error] elements.getElement(CardElement) returns null')
      return
    }

    const setupResponse = await PostStripeCardSetupRequest().send()
    const clientSecret = setupResponse.data.client_secret
    console.log(clientSecret)
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    })
    if (error) {
      console.log('[error]', error)
    } else {
      console.log('[setupIntent]', setupIntent)
    }

    const payment_method = setupIntent?.payment_method
    if (payment_method) {
      const confirmResponse = await PostStripeCardConfirmRequest({ payment_method }).send()
      console.log(confirmResponse)
    }
  }

  const handleDeleteSubmit = async (event: any) => {
    event.preventDefault()

    const deleteResponse = await DeleteStripeCardRequest().send()
    console.log(deleteResponse)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
            hidePostalCode: true,
          }}
        />
        <button type="submit" disabled={!stripe}>
          Register
        </button>
      </form>
      <form onSubmit={handleDeleteSubmit}>
        <button type="submit">Delete</button>
      </form>
    </>
  )
}

export const StripeSample0 = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
)
