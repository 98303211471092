import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react'
import { View, StyleSheet, PanResponder, Dimensions, ActivityIndicator } from 'react-native'
import { Map as M, Marker, TileLayer } from 'react-leaflet'
import Leaflet, { LeafletEvent, LeafletMouseEvent } from 'leaflet'
import { GoogleLayer } from 'react-leaflet-google-v2'
import 'leaflet/dist/leaflet.css'
import 'leaflet.gridlayer.googlemutant'
import locationMarker from '@/images/maps/marker.svg'
import { AlarmSettingContext, setLocation } from '@/reducers/AlarmSetting'
import { LocationRequest } from '@/api/types/Location'
import { Location } from '@/api/types/Location'
import { geocode } from '@/utils/gsi_reverse_geocoder'
import * as Colors from '@/constants/colors'

const locationIcon = new Leaflet.Icon({
  iconUrl: locationMarker,
  iconSize: new Leaflet.Point(44, 58),
  iconAnchor: new Leaflet.Point(22, 45.5),
})

delete (Leaflet.Icon.Default.prototype as any)._getIconUrl
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export interface MapProps {
  defaultLocation?: Location
  onLocationFailed: (error: { code: number; message: string }) => void
}
const Map: React.FunctionComponent<MapProps> = ({ defaultLocation, onLocationFailed }) => {
  const { dispatch } = React.useContext(AlarmSettingContext)
  const [selectedPosition, setSelectedPosition] = useState<{
    lat: number
    lng: number
  } | null>(null)
  const [currentPosition, setCurrentPosition] = useState<{
    lat: number
    lng: number
  } | null>(null)
  const [centerPosition, setCenterPosition] = useState<{
    lat: number
    lng: number
  } | null>(null)
  const mapRef = useRef(null)

  useEffect(() => {
    if (defaultLocation != null) {
      const { latitude: lat, longitude: lng } = defaultLocation
      setSelectedPosition({
        lat,
        lng,
      })
      setCenterPosition({
        lat,
        lng,
      })
    } else if (currentPosition != null) {
      const { lat, lng } = currentPosition
      setSelectedPosition({
        lat,
        lng,
      })
      setCenterPosition({
        lat,
        lng,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition])

  useEffect(() => {
    if (window === null || window.navigator.geolocation === null) {
      const errorMessage = 'お使いの端末では位置情報を取得できないため、メザミーをご利用いただけません'
      alert(errorMessage)
      return
    }

    let retryCount = 0
    let timerId: number | undefined
    const fn = () => {
      const option: PositionOptions = {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 20,
      }
      const onSuccess: PositionCallback = (position: Position) => {
        if (retryCount / 5 >= 1) {
          alert('位置情報を取得しました')
        }
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      }
      const onError: PositionErrorCallback = (error) => {
        retryCount += 1
        // 数回失敗したら、アラートで通信環境の確認を促す
        if (retryCount % 5 === 0) {
          onLocationFailed(error)
        }
        timerId = window.setTimeout(fn, 1000)
      }
      window.navigator.geolocation.getCurrentPosition(onSuccess, onError, option)
    }

    fn()
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (!mapRef.current) {
      return
    }
    ;(mapRef.current as any).leafletElement.invalidateSize()
  })

  const panResponder = useMemo(() => {
    return PanResponder.create({
      // マップ内のドラッグが外側に伝搬して、ボトムシートが動くのを防ぐ
      onStartShouldSetPanResponderCapture: (e) => {
        e.stopPropagation()
        e.preventDefault()
        return false
      },
    })
  }, [])

  const onMarkerDrag = (e: LeafletEvent) => {
    const marker = e.target
    const latLng = marker.getLatLng()
    setSelectedPosition(latLng)
  }

  const onDragEnd = async (e: LeafletEvent) => {
    const marker = e.target
    const { lat, lng } = marker.getLatLng() as { lat: number; lng: number }
    const location: LocationRequest = {
      latitude: lat,
      longitude: lng,
      accuracy: 0,
    }
    const address = await geocode(lat.toString(), lng.toString(), true)
    dispatch(setLocation(address, location))
  }

  const onTap = async (e: LeafletMouseEvent) => {
    const { lat, lng } = e.latlng as { lat: number; lng: number }
    setSelectedPosition({ lat, lng })
    const location: LocationRequest = {
      latitude: lat,
      longitude: lng,
      accuracy: 0,
    }
    const address = await geocode(lat.toString(), lng.toString(), true)
    dispatch(setLocation(address, location))
  }
  const osmAttribution = '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  const osmDefaultUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

  return (
    <>
      <style type="text/css">{`
      #setting-location-map .leaflet-container {
        width: 100%;
        height: ${Dimensions.get('window').height - 320}px;
      }
    `}</style>
      {(!centerPosition || !currentPosition) && (
        <View style={{ height: Dimensions.get('window').height - 320 }}>
          <View style={{ flex: 1 }}></View>
          <ActivityIndicator color={Colors.PRIMARY_ORANGE} size={'large'}></ActivityIndicator>
          <View style={{ flex: 1 }}></View>
        </View>
      )}
      <View style={style.container} nativeID="setting-location-map" {...panResponder.panHandlers}>
        {centerPosition && currentPosition && (
          <M center={centerPosition} zoom={17} ref={mapRef} zoomControl={false} onclick={onTap}>
            {process.env.REACT_APP_MAP_LAYER_TYPE === 'google' ? <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} maptype={'ROAD'} /> : <TileLayer attribution={osmAttribution} url={osmDefaultUrl} />}
            <Marker position={currentPosition} zIndexOffset={1} />
            {selectedPosition && <Marker position={selectedPosition} draggable={true} ondrag={onMarkerDrag} onDragend={onDragEnd} icon={locationIcon} bubblingMouseEvents={false} zIndexOffset={2} />}
          </M>
        )}
      </View>
    </>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default Map
