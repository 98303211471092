import React from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import * as Colors from '@/constants/colors'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { ReactComponent as Metaphor } from '@/images/about/metaphor.svg'
import { ReactComponent as Metaphor2 } from '@/images/about/metaphor2.svg'
import { ReactComponent as Metaphor3 } from '@/images/about/metaphor3.svg'
import { ReactComponent as Pic1 } from '@/images/about/pic_1.svg'
import { ReactComponent as Pic2 } from '@/images/about/pic_2.svg'
import { ReactComponent as Pic3 } from '@/images/about/pic_3.svg'
import { ReactComponent as IOSIcon } from '@/images/about/ios.svg'
import { ReactComponent as AndroidIcon } from '@/images/about/android.svg'
import { Button } from '@/components/Button'

interface BadgeProps {
  count: string
}
const Badge: React.FunctionComponent<BadgeProps> = ({ count }) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          borderRadius: 16,
          width: 32,
          height: 32,
          backgroundColor: Colors.SECONDARY_YELLOW,
        }}
      >
        <Text
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: '400',
            fontSize: 14,
            color: Colors.WHITE,
            marginVertical: 'auto',
            textAlignVertical: 'center',
            textAlign: 'center',
            padding: 'auto',
          }}
        >
          {count}
        </Text>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    overflowX: 'hidden',
    flex: 1,
  },
  aboutTitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 18,
    color: Colors.DARK,
    letterSpacing: 1,
    lineHeight: 38,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
  },
  aboutBody: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 13,
    color: Colors.DARK_GRAY,
    letterSpacing: 1,
    lineHeight: 22,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
  },
  useTitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 18,
    color: Colors.PRIMARY_ORANGE,
    letterSpacing: 1,
    lineHeight: 18,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
  },
  useBody: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 13,
    color: Colors.DARK_GRAY,
    letterSpacing: 0.93,
    lineHeight: 22,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
    marginHorizontal: 40,
  },
  installBody: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 13,
    color: Colors.DARK_GRAY,
    letterSpacing: 0.93,
    lineHeight: 22,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
    marginHorizontal: 40,
  },
  installOsTitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 12,
    color: '#939393',
    letterSpacing: 0.5,
    lineHeight: 18,
    textAlignVertical: 'center',
    textAlign: 'center',
    padding: 'auto',
  },
})

const AboutScreen: React.FunctionComponent = () => {
  const history = useHistory()

  const beforeInstallPromptEvent = (window as any).beforeInstallPromptEvent
  const isInstallButtonEnabled = typeof beforeInstallPromptEvent !== 'undefined'

  const onPressInstall = () => {
    beforeInstallPromptEvent.prompt()
    beforeInstallPromptEvent.userChoice.then(() => {
      delete (window as any).beforeInstallPromptEvent
    })
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ width: '120%', marginTop: '-12%', marginHorizontal: '-8%' }}>
          <Metaphor style={{ width: '100%' }}></Metaphor>
          <View style={{ height: 90 }}></View>
        </View>
        <View style={{ position: 'absolute', width: '100%' }}>
          <View style={{ height: 8 }}></View>
          <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
            <TouchableOpacity
              onPress={() => {
                // goBack が機能しないことがあるので、いったん replace でトップに戻す
                history.replace('/')
              }}
            >
              <BtnClose></BtnClose>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ height: 10 }}></View>
      </View>
      <Text
        style={{
          fontFamily: 'Hiragino Sans',
          fontWeight: '400',
          fontSize: 14,
          color: Colors.PRIMARY_ORANGE,
          textAlignVertical: 'center',
          textAlign: 'center',
          padding: 'auto',
        }}
      >
        What&apos;s Mezamee ?
      </Text>
      <View style={{ height: 38 }}></View>
      <Text
        style={{
          fontFamily: 'Hiragino Sans',
          fontWeight: '600',
          fontSize: 24,
          color: Colors.DARK,
          letterSpacing: 2,
          lineHeight: 38,
          textAlignVertical: 'center',
          textAlign: 'center',
          padding: 'auto',
        }}
      >
        {'覚悟を決めるだけ\nもう寝坊はできない'}
      </Text>
      <View style={{ height: 40 }}></View>
      <Text
        style={{
          fontFamily: 'Hiragino Sans',
          fontWeight: '300',
          fontSize: 14,
          color: Colors.DARK_GRAY,
          letterSpacing: 1,
          lineHeight: 28,
          textAlignVertical: 'center',
          textAlign: 'center',
          padding: 'auto',
        }}
      >
        {'「もう寝坊なんかしない」\nその覚悟に値段をつけてください。\n\n寝坊したらその金額が課金されます。\nこれでもう寝坊するわけにはいきません。\n充実した朝を過ごしましょう。'}
      </Text>
      <View style={{ height: 60 }}></View>
      <View style={{ marginHorizontal: 'auto' }}>
        <Pic1 width={64} height={64}></Pic1>
      </View>
      <View style={{ height: 30 }}></View>
      <Text style={style.aboutTitle}>寝坊すると課金</Text>
      <View style={{ height: 10 }}></View>
      <Text style={style.aboutBody}>{'課金されるので\n寝坊するわけには​いきません。\n​覚悟を決めて目覚めましょう。'}</Text>
      <View style={{ height: 60 }}></View>
      <View style={{ marginHorizontal: 'auto' }}>
        <Pic2 width={64} height={64}></Pic2>
      </View>
      <View style={{ height: 30 }}></View>
      <Text style={style.aboutTitle}>寝坊しなければ無料</Text>
      <Text style={style.aboutBody}>{'寝坊しなければ課金されません。\n有意義な朝を楽しみましょう。'}</Text>
      <View style={{ height: 60 }}></View>
      <View style={{ marginHorizontal: 'auto' }}>
        <Pic3 width={64} height={64}></Pic3>
      </View>
      <View style={{ height: 30 }}></View>
      <Text style={style.aboutTitle}>何度でも目覚め放題</Text>
      <Text style={style.aboutBody}>{'寝坊しなければ何度使っても無料です。\n繰り返し使って習慣づけましょう'}</Text>
      <View style={{ height: 60 }}></View>
      <View style={{ width: '100%', position: 'relative', left: -75 }}>
        <Metaphor2 style={{ width: '100%', display: 'block' }}></Metaphor2>
      </View>
      <View style={{ height: 60 }}></View>
      <Text
        style={{
          fontFamily: 'Hiragino Sans',
          fontWeight: '400',
          fontSize: 14,
          color: Colors.PRIMARY_ORANGE,
          textAlignVertical: 'center',
          textAlign: 'center',
          padding: 'auto',
        }}
      >
        How to use ?
      </Text>
      <View style={{ height: 34 }}></View>
      <Text
        style={{
          fontFamily: 'Hiragino Sans',
          fontWeight: '600',
          fontSize: 24,
          color: Colors.DARK,
          textAlignVertical: 'center',
          textAlign: 'center',
          padding: 'auto',
          letterSpacing: 2,
          lineHeight: 38,
        }}
      >
        使い方
      </Text>
      <View style={{ height: 80 }}></View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <Badge count={'1'}></Badge>
        <View style={{ width: 8 }}></View>
        <Text style={style.useTitle}>支払い方法を設定</Text>
      </View>
      <View style={{ height: 20 }}></View>
      <View style={{ height: 30 }}></View>
      <Text style={style.useBody}>メザミーのご利用には支払い方法の登録が必要です。寝坊しない限り課金されることはありません。</Text>
      <View style={{ height: 80 }}></View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <Badge count={'2'}></Badge>
        <View style={{ width: 8 }}></View>
        <Text style={style.useTitle}>起床場所を設定</Text>
      </View>
      <View style={{ height: 20 }}></View>
      <View style={{ height: 30 }}></View>
      <Text style={style.useBody}>地図上でオレンジのピンをドラッグして就寝、起床する場所（例：自宅）を設定してください。</Text>
      <View style={{ height: 80 }}></View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <Badge count={'3'}></Badge>
        <View style={{ width: 8 }}></View>
        <Text style={style.useTitle}>時間を設定</Text>
      </View>
      <View style={{ height: 20 }}></View>
      <View style={{ height: 30 }}></View>
      <Text style={style.useBody}>家を出る時間を設定してください。メザミーではアラームは鳴らないので、必要であれば別途設定する必要があります。</Text>
      <View style={{ height: 80 }}></View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <Badge count={'4'}></Badge>
        <View style={{ width: 8 }}></View>
        <Text style={style.useTitle}>金額を設定</Text>
      </View>
      <View style={{ height: 20 }}></View>
      <View style={{ height: 30 }}></View>
      <Text style={style.useBody}>万が一寝坊した際に課金される金額（覚悟の金額）を設定してください。最低100円（税込）から自由な金額を設定できます。</Text>
      <View style={{ height: 80 }}></View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <Badge count={'5'}></Badge>
        <View style={{ width: 8 }}></View>
        <Text style={style.useTitle}>起床場所から離れてスライド</Text>
      </View>
      <View style={{ height: 20 }}></View>
      <View style={{ height: 30 }}></View>
      <Text style={style.useBody}>設定した時間までに起床場所から100m以上離れてボタンをスライドしてください。時間を過ぎてしまった場合は設定した金額が課金されます。</Text>
      <View style={{ height: 120 }}></View>
      <View style={{ marginHorizontal: 24, flexDirection: 'column' }}>
        <Text
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: '400',
            fontSize: 14,
            color: Colors.PRIMARY_ORANGE,
            textAlignVertical: 'center',
            textAlign: 'center',
            padding: 'auto',
          }}
        >
          How to install ?
        </Text>
        <View style={{ height: 34 }}></View>
        <Text
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: '600',
            fontSize: 24,
            color: Colors.DARK,
            letterSpacing: 2,
            lineHeight: 38,
            textAlignVertical: 'center',
            textAlign: 'center',
            padding: 'auto',
          }}
        >
          ホーム画面に追加
        </Text>
      </View>
      <View style={{ height: 76 }}></View>
      <View style={{ marginHorizontal: 40 }}>
        <View style={{ marginHorizontal: 'auto' }}>
          <IOSIcon width={64} height={64}></IOSIcon>
        </View>
        <View style={{ height: 12 }}></View>
        <Text style={style.installOsTitle}>Apple iOS</Text>
        <View style={{ height: 24 }}></View>
        <Text style={style.installBody}>標準ブラウザ Safariの共有ボタンから『ホーム画面に追加』をタップしてください。</Text>
      </View>
      <View style={{ height: 64 }}></View>
      <View style={{ marginHorizontal: 40 }}>
        <View style={{ marginHorizontal: 'auto' }}>
          <AndroidIcon width={64} height={64}></AndroidIcon>
        </View>
        <View style={{ height: 12 }}></View>
        <Text style={style.installOsTitle}>Google Android</Text>
        <View style={{ height: 24 }}></View>
        <Text style={style.installBody}>{isInstallButtonEnabled ? '下のボタンからインストールしていただくか、正常にインストールできないなどの場合は、' : ''}標準ブラウザ Chromeのメニューより『ホーム画面に追加』をタップしてください。</Text>
        <View style={{ height: 39 }}></View>
        {isInstallButtonEnabled && <Button title={'インストール'} type="primary" onPress={onPressInstall} />}
      </View>
      <View style={{ height: 80 }}></View>
      <View style={{ height: '100%', flexDirection: 'column' }}>
        <View style={{ width: '100%', position: 'relative', left: 42, flex: 1 }}>
          <Metaphor3 style={{ width: '100%', display: 'block' }}></Metaphor3>
        </View>
        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontFamily: 'Hiragino Sans',
              fontWeight: '400',
              fontSize: 32,
              color: '#FFB611',
              letterSpacing: 0,
              lineHeight: 30,
              textAlignVertical: 'center',
              textAlign: 'center',
              padding: 'auto',
            }}
          >
            Have a Nice Day
          </Text>
          <View style={{ height: 16 }}></View>
          <Text
            style={{
              fontFamily: 'Hiragino Sans',
              fontWeight: '600',
              fontSize: 14,
              color: '#828282',
              letterSpacing: 1,
              lineHeight: 38,
              textAlignVertical: 'center',
              textAlign: 'center',
              padding: 'auto',
            }}
          >
            それでは、よい一日を。
          </Text>
        </View>
        <View>
          <Button
            title={'はじめる'}
            onPress={() => {
              // goBack が機能しないことがあるので、いったん replace でトップに戻す
              history.replace('/')
            }}
            type="action"
          />
          <View style={{ height: 74 }}></View>
        </View>
      </View>
    </View>
  )
}

const AboutSetting = () => {
  return <AboutScreen />
}
export default AboutSetting
