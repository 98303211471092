import React, { useState, useMemo } from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Input, CheckBox } from 'react-native-elements'

import PostAuthCreateAccountRequest from '@/api/endpoints/PostAuthCreateAccountRequest'
import { Button } from '@/components/Button'
import { Link } from '@/components/Link'
import * as Colors from '@/constants/colors'
import { ReactComponent as CheckIcon } from '@/images/icons/ic_check.svg'
import { useLocalStorage } from 'react-use'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 48,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  containerFocus: {
    borderColor: Colors.PRIMARY_ORANGE,
  },
  containerError: {
    borderColor: Colors.DESTRUCTIVE,
  },
  inputLabel: {
    fontSize: 11,
  },
  inputBase: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '300',
    fontSize: 16,
    color: Colors.LIGHT_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  inputFocus: {
    color: Colors.PRIMARY_ORANGE,
  },
  inputError: {
    color: Colors.DESTRUCTIVE,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontFamily: 'Hiragino Sans',
    fontWeight: '400',
    fontSize: 11,
    lineHeight: 22,
    letterSpacing: 0,
  },
  linkableText: {
    color: Colors.DARK,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: Colors.GRAY,
  },
})

export interface SignUpContentProps {
  onClose: () => void
}

const SignUpContent: React.FunctionComponent<SignUpContentProps> = ({ onClose }) => {
  const [email, setEmail] = useState('')
  const [emailFocused, setEmailFocused] = useState(false)
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [isPasswordDirty, setIsPasswordDirty] = useState(false)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordConfirmFocused, setPasswordConfirmFocused] = useState(false)
  const [isPasswordConfirmDirty, setIsPasswordConfirmDirty] = useState(false)
  const [isPosting, setIsPosting] = useState(false)
  const [a8Id] = useLocalStorage<string>('a8-id', '', { raw: true })

  const emailError = useMemo(() => {
    if (!isEmailDirty) {
      return ''
    }
    if (email.length === 0) {
      return 'メールアドレスを入力してください'
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return 'メールアドレスを正しく入力してください'
    }
    return ''
  }, [email, isEmailDirty])
  const emailStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.inputFocus : {}, emailError ? style.inputError : {}])
  }, [emailError, emailFocused])
  const emailInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, email.length > 0 ? { color: Colors.DARK } : {}])
  }, [email])
  const emailContainerStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.containerFocus : {}, emailError ? style.containerError : {}])
  }, [emailError, emailFocused])

  const passwordError = useMemo(() => {
    if (!isPasswordDirty) {
      return ''
    }
    if (password.length === 0) {
      return 'パスワードを入力してください'
    }
    if (password.length < 8) {
      return 'パスワードが短すぎます'
    }
    if (password.length > 255) {
      return 'パスワードが長すぎます'
    }
    return ''
  }, [password, isPasswordDirty])
  const passwordStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.inputFocus : {}, passwordError ? style.inputError : {}])
  }, [passwordError, passwordFocused])
  const passwordInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, password.length > 0 ? { color: Colors.DARK } : {}])
  }, [password])
  const passwordContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordFocused ? style.containerFocus : {}, passwordError ? style.containerError : {}])
  }, [passwordError, passwordFocused])

  const passwordConfirmError = useMemo(() => {
    if (!isPasswordConfirmDirty) {
      return ''
    }
    if (password !== passwordConfirm) {
      return 'パスワード(確認)に誤りがあります'
    }
    return ''
  }, [isPasswordConfirmDirty, password, passwordConfirm])
  const passwordConfirmStyle = useMemo(() => {
    return StyleSheet.flatten([passwordConfirmFocused ? style.inputFocus : {}, passwordConfirmError ? style.inputError : {}])
  }, [passwordConfirmError, passwordConfirmFocused])
  const passwordConfirmContainerStyle = useMemo(() => {
    return StyleSheet.flatten([passwordConfirmFocused ? style.containerFocus : {}, passwordConfirmError ? style.containerError : {}])
  }, [passwordConfirmError, passwordConfirmFocused])

  const [isAgreedTerms, setIsAgreedTerms] = useState(false)

  const isSubmitButtonEnabled = useMemo(() => {
    return emailError.length === 0 && email.length > 0 && passwordError.length === 0 && password.length > 0 && passwordConfirmError.length === 0 && passwordConfirm.length > 0 && isAgreedTerms
  }, [email.length, emailError.length, isAgreedTerms, password.length, passwordConfirm.length, passwordConfirmError.length, passwordError.length])

  const handleOnSubmit = async () => {
    if (!isSubmitButtonEnabled) {
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    try {
      await PostAuthCreateAccountRequest({
        email: email,
        password: password,
        tracking_info: {
          a8_id: a8Id,
        },
      }).send()
      window.gtag('event', 'アカウント作成', { event_category: 'sign-up' })
      alert('確認メールを送信しました。確認メールに記載のリンクからアカウントを有効化してください。')
      window.location.replace('/sign_in')
    } catch (e) {
      const response = e.response
      if (response?.data?.errors?.email[0]?.includes('すでに存在します')) {
        alert('既に利用されているメールアドレスです')
      } else {
        alert('アカウントの作成に失敗しました')
      }
    }
    setIsPosting(false)
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={onClose}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title} selectable={false}>
            {'アカウントを作成して\nMezameeをはじめましょう'}
          </Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>

      <View style={{ height: 60 }} pointerEvents={'none'} />

      <View style={{ marginHorizontal: 24 }}>
        <View>
          <Input
            label={'メールアドレス'}
            labelProps={{ style: [style.inputLabel, emailStyle] }}
            inputContainerStyle={emailContainerStyle}
            inputStyle={emailInput}
            labelStyle={emailStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="メールアドレス"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={emailError}
            autoFocus={false}
            autoCapitalize={'none'}
            value={email}
            onChangeText={setEmail}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => {
              setEmailFocused(false)
              setIsEmailDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <Input
            label={'パスワード'}
            labelProps={{ style: [style.inputLabel, passwordStyle] }}
            inputContainerStyle={passwordContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="パスワード"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordError}
            secureTextEntry={true}
            autoCapitalize={'none'}
            value={password}
            onChangeText={setPassword}
            onFocus={() => setPasswordFocused(true)}
            onBlur={() => {
              setPasswordFocused(false)
              setIsPasswordDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <Input
            label={'パスワード(確認)'}
            labelProps={{
              style: [style.inputLabel, passwordConfirmStyle],
            }}
            inputContainerStyle={passwordConfirmContainerStyle}
            inputStyle={passwordInput}
            labelStyle={passwordConfirmStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="パスワード(確認)"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={passwordConfirmError}
            secureTextEntry={true}
            value={passwordConfirm}
            autoCapitalize={'none'}
            onChangeText={setPasswordConfirm}
            onFocus={() => setPasswordConfirmFocused(true)}
            onBlur={() => {
              setPasswordConfirmFocused(false)
              setIsPasswordConfirmDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <CheckBox
            containerStyle={{
              backgroundColor: '#ffffff',
              borderWidth: 0,
              padding: 0,
              marginLeft: 0,
              marginRight: 0,
            }}
            checkedIcon={
              <TouchableOpacity
                style={{
                  borderWidth: 2,
                  borderColor: Colors.GRAY,
                  width: 18,
                  height: 18,
                  marginRight: 8,
                }}
                onPress={() => setIsAgreedTerms(!isAgreedTerms)}
              >
                <CheckIcon width={14} height={14} style={{ stroke: Colors.PRIMARY_ORANGE, color: Colors.PRIMARY_ORANGE, transform: `scale(1.2)` }}></CheckIcon>
              </TouchableOpacity>
            }
            uncheckedIcon={<TouchableOpacity style={{ borderWidth: 2, borderColor: Colors.GRAY, width: 18, height: 18, marginRight: 8 }} onPress={() => setIsAgreedTerms(!isAgreedTerms)} />}
            title={
              <Text>
                <Link href={'https://mezamee.com/terms/'} style={style.linkableText}>
                  {'利用規約'}
                </Link>
                <Text>{' に同意する'}</Text>
              </Text>
            }
            checked={isAgreedTerms}
          />
        </View>
        <View style={{ height: 30 }} />
        <Text style={style.note}>
          <Link href={'https://mezamee.com/privacy/'} style={style.linkableText}>
            {'プライバシーポリシー'}
          </Link>
          {' はこちらをご確認ください。'}
        </Text>
        <View style={{ height: 48 }} />
        <View>
          <Button title={'アカウントを作成'} type="action" disabled={!isSubmitButtonEnabled} onPress={handleOnSubmit} loading={isPosting} />
        </View>
        <View style={{ height: 96 }} />
      </View>
    </View>
  )
}

export default SignUpContent
