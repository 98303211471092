import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { View, StyleSheet, Dimensions, Text, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'
import * as Colors from '@/constants/colors'
import { Button } from '@/components/Button'
import { Resettable } from '.'
import { AlarmSettingContext, setLocation } from '@/reducers/AlarmSetting'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'

export interface SettingLocationProps {
  onPressClose: () => void
}
const SettingLocation = forwardRef<Resettable, SettingLocationProps>(function SettingLocation({ onPressClose }, ref) {
  const { state, dispatch } = React.useContext(AlarmSettingContext)
  const [address, setAddress] = useState('')

  useImperativeHandle(ref, () => {
    return {
      reset() {
        setAddress(state.locationAddress ?? '')
      },
    }
  })

  const onSubmit = () => {
    // TODO: geocoding
    const location = state.location
    if (location == null) {
      return
    }
    dispatch(setLocation(address, location))
    onPressClose()
  }

  return (
    <View style={style.container}>
      <View style={{ height: 8 }} />
      <View style={style.header}>
        <View style={{ flex: 1 }} />
        <TouchableOpacity style={style.closeButton} onPress={onPressClose}>
          <BtnClose></BtnClose>
        </TouchableOpacity>
      </View>
      <Text style={style.title}>起床場所</Text>
      <View style={{ height: 30 }} />
      <View style={{ marginHorizontal: 24 }}>
        <View>
          <Input autoCapitalize={'none'} placeholder={'住所を入力'} containerStyle={{ paddingLeft: 0, paddingRight: 0 }} value={address} onChangeText={setAddress} />
        </View>
        <View style={{ height: 30 }} />
        <Text style={style.note}>目覚める予定場所から100メートル以内の地点を登録してください。</Text>
      </View>

      <View style={{ position: 'absolute', bottom: 74, right: 0, left: 0 }}>
        <Button title={'登録する'} type="action" onPress={onSubmit} />
      </View>
    </View>
  )
})

const style = StyleSheet.create({
  container: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    backgroundColor: '#ffffff',
  },
  title: {
    marginHorizontal: 24,
    marginTop: 0,
    fontSize: 20,
    fontWeight: '600',
    color: Colors.DARK,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  closeButton: {
    width: 56,
    height: 56,
    marginLeft: 8,
  },
  note: {
    color: Colors.DARK_GRAY,
    fontSize: 11,
    lineHeight: 18,
  },
})

export default SettingLocation
