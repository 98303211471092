import * as API from '@/api/Request'
import { Alarm } from '@/api/types/Alarm'

interface PostAlarmCancelResponse {
  readonly data: {
    attributes: Alarm
  }
}

const PostAlarmCancelRequest = (id: number) => {
  return API.BuildRequest<PostAlarmCancelResponse>({
    method: 'post',
    version: 'v1',
    path: `/alarms/${id}/cancel`,
    requireAuthentication: true,
  })
}

export default PostAlarmCancelRequest
