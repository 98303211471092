import React, { useMemo, useState, useEffect } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'
import { ReactComponent as BtnClose } from '@/images/buttons/btn_close.svg'
import { useHistory } from 'react-router-dom'
import { useBoolean } from 'react-use'
import * as Colors from '@/constants/colors'
import { Button } from '@/components/Button'
import PutAuthChangeEmailRequest from '@/api/endpoints/PutAuthChangeEmailRequest'
import GetMeRequest from '@/api/endpoints/GetMeRequest'
import { CurrentUserContext, setCurrentUser } from '@/reducers/CurrentUser'

const style = StyleSheet.create({
  container: {
    minHeight: '100%',
    flex: 1,
  },
  title: {
    flex: 1,
    textAlign: 'left',
    marginHorizontal: 24,
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 24,
    color: '#2D2D2D',
    letterSpacing: 1,
    lineHeight: 30,
  },
  subtitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    fontSize: 15,
    color: '#2D2D2D',
    letterSpacing: 0,
    lineHeight: 32,
  },
  containerFocus: {
    borderColor: Colors.PRIMARY_ORANGE,
  },
  containerError: {
    borderColor: Colors.DESTRUCTIVE,
  },
  inputLabel: {
    fontSize: 11,
  },
  inputBase: {
    fontFamily: 'Hiragino Sans',
    fontWeight: '300',
    fontSize: 16,
    color: Colors.LIGHT_GRAY,
    letterSpacing: 0,
    lineHeight: 12,
  },
  inputFocus: {
    color: Colors.PRIMARY_ORANGE,
  },
  inputError: {
    color: Colors.DESTRUCTIVE,
  },
})

const EmailSettingScreen: React.FunctionComponent = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [emailFocused, setEmailFocused] = useState(false)
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [emailConfirm, setEmailConfirm] = useState('')
  const [emailConfirmFocused, setEmailConfirmFocused] = useState(false)
  const [isEmailConfirmDirty, setIsEmailConfirmDirty] = useState(false)
  const [isPosting, setIsPosting] = useBoolean(false)
  const currentUserContext = React.useContext(CurrentUserContext)

  const currentEmail = useMemo(() => {
    return currentUserContext.state.email ?? ''
  }, [currentUserContext.state])

  const emailError = useMemo(() => {
    if (!isEmailDirty) {
      return ''
    }
    if (email.length === 0) {
      return 'メールアドレスを入力してください'
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return 'メールアドレスを正しく入力してください'
    }
    return ''
  }, [email, isEmailDirty])
  const emailStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.inputFocus : {}, emailError ? style.inputError : {}])
  }, [emailError, emailFocused])
  const emailInput = useMemo(() => {
    return StyleSheet.flatten([style.inputBase, email.length > 0 ? { color: Colors.DARK } : {}])
  }, [email])
  const emailContainerStyle = useMemo(() => {
    return StyleSheet.flatten([emailFocused ? style.containerFocus : {}, emailError ? style.containerError : {}])
  }, [emailError, emailFocused])

  const emailConfirmError = useMemo(() => {
    if (!isEmailConfirmDirty) {
      return ''
    }
    if (email !== emailConfirm) {
      return 'メールアドレス(確認)に誤りがあります'
    }
    return ''
  }, [isEmailConfirmDirty, email, emailConfirm])
  const emailConfirmStyle = useMemo(() => {
    return StyleSheet.flatten([emailConfirmFocused ? style.inputFocus : {}, emailConfirmError ? style.inputError : {}])
  }, [emailConfirmError, emailConfirmFocused])
  const emailConfirmContainerStyle = useMemo(() => {
    return StyleSheet.flatten([emailConfirmFocused ? style.containerFocus : {}, emailConfirmError ? style.containerError : {}])
  }, [emailConfirmError, emailConfirmFocused])

  const isSubmitButtonEnabled = useMemo(() => {
    return emailError.length === 0 && email.length > 0 && emailConfirmError.length === 0 && emailConfirm.length > 0
  }, [email.length, emailConfirm.length, emailConfirmError.length, emailError.length])

  useEffect(() => {
    const getMe = async () => {
      const response = await GetMeRequest().send()
      const { id, email, tracking_info, stats } = response.data.data.attributes
      currentUserContext.dispatch(setCurrentUser({ id, email, trackingInfo: tracking_info, stats }))
    }
    if (currentUserContext.state.email == null) {
      getMe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnSubmit = async () => {
    if (!isSubmitButtonEnabled) {
      return
    }
    if (isPosting) {
      return
    }
    setIsPosting(true)
    try {
      await PutAuthChangeEmailRequest({
        email: email,
      }).send()
      alert('メールアドレス確認のためのメールを送信しました')
      history.replace('/')
    } catch (e) {
      console.error(e)
      const response = e.response
      if (response?.data?.errors?.email[0]?.includes('すでに存在します')) {
        alert('既に利用されているメールアドレスです')
      } else {
        alert('メールアドレスの変更に失敗しました')
      }
    }
    setIsPosting(false)
  }

  return (
    <View style={style.container}>
      <View style={{ width: '100%' }}>
        <View style={{ height: 8 }}></View>
        <View style={{ flexDirection: 'row-reverse', width: '100%' }}>
          <TouchableOpacity onPress={() => history.replace('/')}>
            <BtnClose></BtnClose>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Text style={style.title}>メールアドレス変更</Text>
        </View>
        <View style={{ height: 10 }}></View>
      </View>
      <View style={{ flex: 1, marginTop: 40, marginHorizontal: 24 }}>
        {currentEmail.length > 0 && (
          <>
            <View>
              <Input
                pointerEvents={'none'}
                label={'現在のメールアドレス'}
                labelProps={{ style: [style.inputLabel] }}
                inputStyle={StyleSheet.flatten([style.inputBase, { color: Colors.DARK }])}
                containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
                value={currentEmail}
              />
            </View>
            <View style={{ height: 16 }} />
          </>
        )}
        <View>
          <Input
            label={'新しいメールアドレス'}
            labelProps={{ style: [style.inputLabel, emailStyle] }}
            inputContainerStyle={emailContainerStyle}
            inputStyle={emailInput}
            labelStyle={emailStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="新しいメールアドレス"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={emailError}
            autoCapitalize={'none'}
            value={email}
            onChangeText={setEmail}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => {
              setEmailFocused(false)
              setIsEmailDirty(true)
            }}
          />
        </View>
        <View style={{ height: 16 }} />
        <View>
          <Input
            label={'新しいメールアドレス(確認)'}
            labelProps={{
              style: [style.inputLabel, emailConfirmStyle],
            }}
            inputContainerStyle={emailConfirmContainerStyle}
            inputStyle={emailInput}
            labelStyle={emailConfirmStyle}
            containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
            placeholder="新しいメールアドレス(確認)"
            placeholderTextColor={Colors.LIGHT_GRAY}
            errorStyle={StyleSheet.flatten(style.inputError)}
            errorMessage={emailConfirmError}
            value={emailConfirm}
            autoCapitalize={'none'}
            onChangeText={setEmailConfirm}
            onFocus={() => setEmailConfirmFocused(true)}
            onBlur={() => {
              setEmailConfirmFocused(false)
              setIsEmailConfirmDirty(true)
            }}
          />
        </View>
      </View>
      <View style={{ position: 'absolute', bottom: 74, right: 0, left: 0 }}>
        <Button title={'メールアドレスを変更'} type="action" disabled={!isSubmitButtonEnabled} onPress={handleOnSubmit} loading={isPosting} />
      </View>
    </View>
  )
}

const EmailSetting = () => {
  return <EmailSettingScreen />
}
export default EmailSetting
