import React from 'react'
import { StripeSample0 } from './stripe/0'
import { GoogleMaps0 } from './maps/0'
import { APISample0 } from './api/0'

const Nakaoka: React.FunctionComponent = () => {
  return (
    <>
      <div>
        mezamee
        <hr />
      </div>

      <div>
        <div>↓↓↓ StripeSample0 ↓↓↓</div>
        <StripeSample0 />
        <hr />
      </div>

      <div>
        <div>↓↓↓ Google Maps ↓↓↓</div>
        <GoogleMaps0 />
        <hr />
      </div>

      <div>
        <div>↓↓↓ API ↓↓↓</div>
        <APISample0 />
      </div>
    </>
  )
}

export default Nakaoka
