import * as API from '@/api/Request'
import * as Session from '@/api/Session'

interface DeleteAuthSignOutResponse {
  readonly success: boolean
}

const DeleteAuthSignOutRequest = () => {
  return API.BuildRequest<DeleteAuthSignOutResponse>({
    method: 'delete',
    version: 'v1',
    path: '/auth/sign_out',
    requireAuthentication: true,
    onRequestSuccess: (response) => {
      if (response.data.success) {
        Session.clearAuthToken()
      }
    },
  })
}

export default DeleteAuthSignOutRequest
