import React, { useState, useRef, useMemo, useContext, useEffect } from 'react'
import { View, StyleSheet, Dimensions, TouchableOpacity, Text } from 'react-native'
import { SwipeButton } from '@/pages/Alarm/SwipeButton'
import Modal from 'modal-enhanced-react-native-web'
import Map from './Map'
import SettingDateTime from './SettingDateTime'
import SettingLocation from './SettingLocation'
import SettingPay from './SettingPay'
import SettingCreditCard from './SettingCreditCard'
import CancelProgress from './CancelProgress'
import { Resettable } from '.'
import { AlarmSettingContext, setLocation, setPayment, setDateTime } from '@/reducers/AlarmSetting'
import { CreditCardContext } from '@/reducers/CreditCard'
import { ReactComponent as ArrowIcon } from '@/images/icons/ic_arrow.svg'
import { ReactComponent as CardIcon } from '@/images/cards/card.svg'
import { cardBrandToComponent } from '@/utils'
import { geocode } from '@/utils/gsi_reverse_geocoder'
import * as Types from '@/api/types/Alarm'
import * as Colors from '@/constants/colors'
import dayjs from 'dayjs'

type ItemRightIcon = 'amex' | 'diners' | 'jcb' | 'mastercard' | 'visa'

const ListItem = ({ title, rightTitle, placeholderRithtTitle = '', rightIcon, hideArrow = false }: { title: string; rightIcon?: ItemRightIcon; rightTitle: string; placeholderRithtTitle?: string; hideArrow?: boolean }) => {
  const rightIconComponent = useMemo(() => {
    const component = cardBrandToComponent(rightIcon ?? '')
    if (component != null) {
      return component
    }
    return rightIcon != null ? <CardIcon width={24} fill={Colors.GRAY}></CardIcon> : null
  }, [rightIcon])

  return (
    <View
      style={{
        width: '100%',
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          height: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          maxWidth: 64,
          marginLeft: 20,
          marginRight: 16,
          borderBottomWidth: 1,
          borderBottomColor: Colors.WHITE,
        }}
      >
        <Text
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: '400',
            fontSize: 12,
            color: '#999999',
            letterSpacing: 0.5,
            lineHeight: 16,
          }}
          selectable={false}
        >
          {title}
        </Text>
      </View>
      <View
        style={{
          height: '100%',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: Colors.LIGHT_GRAY,
        }}
      >
        {rightIconComponent != null && (
          <>
            {rightIconComponent}
            <View style={{ width: 12 }}></View>
          </>
        )}
        <Text
          style={{
            flex: 1,
            fontFamily: 'Hiragino Sans',
            fontWeight: '400',
            fontSize: 13,
            color: Colors.DARK,
            letterSpacing: 0,
            lineHeight: 16,
            ...(rightTitle.length > 0 ? {} : { fontSize: 14, color: Colors.DARK_GRAY }),
          }}
          numberOfLines={1}
          ellipsizeMode={'tail'}
        >
          {rightTitle.length > 0 ? rightTitle : placeholderRithtTitle}
        </Text>
        {!hideArrow && (
          <View
            style={{
              paddingHorizontal: 12,
            }}
          >
            <ArrowIcon></ArrowIcon>
          </View>
        )}
      </View>
    </View>
  )
}

export interface AlarmProps {
  onCreated: () => void
  templateAlarm?: Types.Alarm
  isInitial: boolean
}
const Alarm: React.FunctionComponent<AlarmProps> = ({ onCreated, templateAlarm, isInitial }) => {
  const creditCardContext = React.useContext(CreditCardContext)
  const { state, dispatch } = useContext(AlarmSettingContext)
  const [isVisibleSettingDateTime, setIsVisibleSettingDateTime] = useState(false)
  const [isVisibleSettingLocation, setIsVisibleSettingLocation] = useState(false)
  const [isVisibleSettingPay, setIsVisibleSettingPay] = useState(false)
  const [isVisibleSettingCreditCard, setIsVisibleSettingCreditCard] = useState(false)
  const [isVisibleCancelProgress, setIsVisibleCancelProgress] = useState(false)
  const settingDateTimeRef = useRef({} as Resettable)
  const settingLocationRef = useRef({} as Resettable)
  const settingPayRef = useRef({} as Resettable)
  const cancelProgressRef = useRef({} as Resettable)
  const onPressSettingDateTime = () => {
    setIsVisibleSettingDateTime(true)
    settingDateTimeRef.current.reset()
  }
  const onPressSettingPay = () => {
    setIsVisibleSettingPay(true)
    settingPayRef.current.reset()
  }
  const onPressSettingCreditCard = () => {
    setIsVisibleSettingCreditCard(true)
  }
  const onSwipeSuccess = () => {
    if (alertValidationErrorIfNeeded()) {
      return
    }
    setIsVisibleCancelProgress(true)
    cancelProgressRef.current.reset()
  }
  const onCompleteAlarmPosting = () => {
    setIsVisibleCancelProgress(false)
    onCreated()
  }
  const onCancelAlarmPosting = () => {
    setIsVisibleCancelProgress(false)
  }

  const dateTimeText = useMemo(() => {
    if (!state.dateTime) {
      return ''
    }
    return dayjs(state.dateTime).format('YYYY/MM/DD (ddd) HH:mm')
  }, [state.dateTime])
  const payAmountText = useMemo(() => {
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    })
    if (state.payAmount == null) {
      return ''
    }
    return formatter.format(state.payAmount)
  }, [state.payAmount])

  const creditCardText = useMemo(() => {
    const creditCard = creditCardContext.state.creditCard
    if (creditCard == null) {
      return ''
    }
    return `●●●● ●●●● ●●●● ${creditCard.last4}`
  }, [creditCardContext.state])

  const creditCardBrand = useMemo(() => {
    const creditCard = creditCardContext.state.creditCard
    if (creditCard == null) {
      return
    }
    return creditCard.brand as ItemRightIcon
  }, [creditCardContext.state])

  const isValidAlarmSetting = useMemo(() => {
    const { dateTime, payAmount, locationAddress, location } = state
    return dateTime != null && payAmount != null && locationAddress != null && location != null && creditCardContext.state.creditCard != null
  }, [state, creditCardContext.state.creditCard])

  const handleLocationFailed = (error: { code: number; message: string }) => {
    const navigator = window.navigator as any
    if (navigator.standalone) {
      const isYes = window.confirm('位置情報の取得に失敗しました。少し時間をおいてから再度お試しいただくか、ブラウザでご利用ください。ブラウザで開きますか？')
      if (isYes) {
        const url = new URL(window.location.href)
        window.open(url.origin, '_blank')
      }
    } else {
      alert(`位置情報を取得できませんでした(エラーコード: ${error.code})。通信環境をご確認ください`)
    }
  }

  const alertValidationErrorIfNeeded = (): boolean => {
    if (isValidAlarmSetting) {
      return false
    }
    if (creditCardContext.state.creditCard == null) {
      alert('メニューの「決済方法」でクレジットカードを登録してください。寝坊しない限り課金はされません。')
      return true
    }
    const { locationAddress, location } = state
    if (locationAddress == null || location == null) {
      alert('起床場所をピンをドラッグして決定してください')
      return true
    }
    return true
  }

  useEffect(() => {
    if (templateAlarm == null) {
      return
    }
    const location = templateAlarm.sleeping_location
    dispatch(setLocation('', location))
    const fetchAddress = async () => {
      const { latitude, longitude } = location
      const address = await geocode(latitude.toString(), longitude.toString(), true)
      dispatch(setLocation(address, location))
    }
    fetchAddress()
    dispatch(setPayment(templateAlarm.amount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // ref: https://pqina.nl/blog/blocking-navigation-gestures-on-ios-13-4/
    const listener = (e: TouchEvent) => {
      e.preventDefault()
    }
    const sheet = document.getElementById('alarm-sheet') as HTMLDivElement
    sheet.addEventListener('touchstart', listener)
    return () => {
      sheet.removeEventListener('touchstart', listener)
    }
  }, [])

  useEffect(() => {
    const now = new Date()
    const templateDateTime = templateAlarm?.fire_at
    const hour = templateDateTime ? new Date(templateDateTime).getHours() : 8
    const minute = templateDateTime ? new Date(templateDateTime).getMinutes() : 0
    // 早朝4時までは当日のアラームが設定できるようにする
    const addDay = now.getHours() < 4 ? 0 : 1
    let defaultDateTime = dayjs(now)
      .startOf('day')
      .add(addDay, 'day')
      .hour(hour)
      .minute(minute)
      .second(59)
      .millisecond(999)
    if (defaultDateTime.isBefore(now)) {
      defaultDateTime = defaultDateTime.add(1, 'day')
    }
    dispatch(setDateTime(defaultDateTime.toDate()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View nativeID={'alarm-sheet'} style={style.container}>
      <View
        style={{
          position: 'absolute',
          top: -25,
          width: Dimensions.get('window').width,
        }}
      >
        <View
          style={{
            position: 'relative',
          }}
        >
          <Map defaultLocation={templateAlarm?.sleeping_location} onLocationFailed={handleLocationFailed} />
          <SwipeButton onSwipeSuccess={onSwipeSuccess} disabled={false} />
        </View>
        <View style={{ height: 10 }}></View>
        <ListItem title={'起床場所'} rightTitle={state.locationAddress ?? ''} placeholderRithtTitle={'ピンをドラッグしてください'} hideArrow={true} />
        <TouchableOpacity onPress={onPressSettingDateTime}>
          <ListItem title={'目覚時間'} rightTitle={dateTimeText} />
        </TouchableOpacity>
        <TouchableOpacity onPress={onPressSettingPay}>
          <ListItem title={'覚悟金額'} rightTitle={payAmountText} placeholderRithtTitle={'指定してください'} />
        </TouchableOpacity>
        <TouchableOpacity onPress={onPressSettingCreditCard}>
          <ListItem title={'支払方法'} rightIcon={creditCardBrand} rightTitle={creditCardText} placeholderRithtTitle={'登録してください'} />
        </TouchableOpacity>
      </View>
      <Modal isVisible={isVisibleSettingDateTime} style={{ margin: 0 }}>
        <SettingDateTime ref={settingDateTimeRef} onPressClose={setIsVisibleSettingDateTime.bind(null, false)} />
      </Modal>
      <Modal isVisible={isVisibleSettingLocation} style={{ margin: 0 }}>
        <SettingLocation ref={settingLocationRef} onPressClose={setIsVisibleSettingLocation.bind(null, false)} />
      </Modal>
      <Modal isVisible={isVisibleSettingPay} style={{ margin: 0 }}>
        <SettingPay ref={settingPayRef} onPressClose={setIsVisibleSettingPay.bind(null, false)} />
      </Modal>
      <Modal isVisible={isVisibleSettingCreditCard} style={{ margin: 0 }}>
        <SettingCreditCard onPressClose={setIsVisibleSettingCreditCard.bind(null, false)} />
      </Modal>
      <Modal isVisible={isVisibleCancelProgress} style={{ margin: 0 }}>
        <CancelProgress ref={cancelProgressRef} isInitial={isInitial} onComplete={onCompleteAlarmPosting} onCancel={onCancelAlarmPosting} />
      </Modal>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
})

export default Alarm
