import React, { FunctionComponent, useState, Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'
import GetMeRequest from '@/api/endpoints/GetMeRequest'
import DeleteAuthAccountRequest from '@/api/endpoints/DeleteAuthAccountRequest'
import GetAlarmsRequest from '@/api/endpoints/GetAlarmsRequest'
import GetAlarmRequest from '@/api/endpoints/GetAlarmRequest'
import PostAlarmRequest from '@/api/endpoints/PostAlarmRequest'
import PostAlarmWakeUpRequest from '@/api/endpoints/PostAlarmWakeUpRequest'
import PostAlarmCancelRequest from '@/api/endpoints/PostAlarmCancelRequest'
import GetCreditCardRequest from '@/api/endpoints/GetCreditCardRequest'
import PostTrackingInfoRequest from '@/api/endpoints/PostTrackingInfoRequest'
import DeleteTrackingInfoRequest from '@/api/endpoints/DeleteTrackingInfoRequest'

interface LocationDebugInfo {
  latitude: number // 緯度, { precision: 11, scale: 8 }
  longitude: number // 経度, { precision: 11, scale: 8 }
  altitude?: number // 高度, { double, 単位はメートル }
  accuracy: number // 精度, { double, 単位はメートル }
  altitudeAccuracy?: number // 精度, { double, 単位はメートル }
  heading?: number // 方角, { double, 単位は 0 ~ 360 で 90 が東 }
  speed?: number // 速度, { double, 単位は m/s }
}

const harajuku: LocationDebugInfo = {
  latitude: 35.670821,
  longitude: 139.702726,
  accuracy: 0,
}

const shibuya: LocationDebugInfo = {
  latitude: 35.663928,
  longitude: 139.702551,
  accuracy: 0,
}

const kyoeido: LocationDebugInfo = {
  latitude: 35.696206,
  longitude: 139.759408,
  accuracy: 0,
}

const jimbocho: LocationDebugInfo = {
  latitude: 35.695983,
  longitude: 139.758066,
  accuracy: 0,
}

const LocationDebugField: FunctionComponent<{ locationState: [LocationDebugInfo, Dispatch<SetStateAction<LocationDebugInfo>>] }> = (props) => {
  const [location, setLocation] = props.locationState

  const handleInputChange = (e: any) => {
    const l: LocationDebugInfo = JSON.parse(JSON.stringify(location))
    switch (e.target.name) {
      case 'latitude':
        l.latitude = e.target.value
        break
      case 'longitude':
        l.longitude = e.target.value
        break
      case 'accuracy':
        l.accuracy = e.target.value
        break
      default:
        break
    }
    setLocation(l)
  }

  const handleOnClickHere = () => {
    if (window === null || window.navigator.geolocation === null) {
      const errorMessage = 'お使いの端末は、GeoLacation APIに対応していません。'
      alert(errorMessage)
      return
    }

    const option: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 80000,
      maximumAge: 20,
    }

    const onSuccess: PositionCallback = (position: Position) => {
      console.log(position.coords)
      // TypeScript 的にもっと格好良く書ける気がしている。
      const { latitude, longitude, accuracy } = position.coords
      setLocation({ latitude, longitude, accuracy })
    }

    const onError: PositionErrorCallback = (error) => {
      // エラーコード (error.code) の番号
      // 0: UNKNOWN_ERROR        原因不明のエラー
      // 1: PERMISSION_DENIED    利用者が位置情報の取得を許可しなかった
      // 2: POSITION_UNAVAILABLE 電波状況などで位置情報が取得できなかった
      // 3: TIMEOUT              位置情報の取得に時間がかかり過ぎた…
      const errorInfo = ['原因不明のエラーが発生しました…。', '位置情報の取得が許可されませんでした…。', '電波状況などで位置情報が取得できませんでした…。', '位置情報の取得に時間がかかり過ぎてタイムアウトしました…。']
      const errorMessage = '[エラー番号: ' + error.code + ']\n' + errorInfo[error.code]
      window.alert(errorMessage)
    }

    window.navigator.geolocation.getCurrentPosition(onSuccess, onError, option)
  }

  const handleOnClickHarajuku = () => {
    setLocation(harajuku)
  }

  const handleOnClickShibuya = () => {
    setLocation(shibuya)
  }

  const handleOnClickKyoeido = () => {
    setLocation(kyoeido)
  }

  const handleOnClickJimbocho = () => {
    setLocation(jimbocho)
  }

  const linkGreenStyle: React.CSSProperties = { cursor: 'pointer', textDecorationLine: 'underline', color: 'green' }

  return (
    <>
      <div>
        <span>latitude (緯度)</span>
        <span> : </span>
        <span>
          <input name="latitude" type="number" value={location.latitude} onChange={handleInputChange} />
        </span>
      </div>
      <div>
        <span>longitude (経度)</span>
        <span> : </span>
        <span>
          <input name="longitude" type="number" value={location.longitude} onChange={handleInputChange} />
        </span>
      </div>
      <div>
        <span>accuracy</span>
        <span> : </span>
        <span>
          <input name="accuracy" type="number" value={location.accuracy} onChange={handleInputChange} />
        </span>
      </div>
      <div>
        <span style={linkGreenStyle} onClick={handleOnClickHere}>
          @here
        </span>
        <span> </span>
        <span style={linkGreenStyle} onClick={handleOnClickHarajuku}>
          @harakuju
        </span>
        <span> </span>
        <span style={linkGreenStyle} onClick={handleOnClickShibuya}>
          @shibuya
        </span>
        <span> </span>
        <span style={linkGreenStyle} onClick={handleOnClickKyoeido}>
          @kyoeido
        </span>
        <span> </span>
        <span style={linkGreenStyle} onClick={handleOnClickJimbocho}>
          @jimbocho
        </span>
      </div>
    </>
  )
}

export const APISample0: FunctionComponent = () => {
  const history = useHistory()

  const [alarmId, setAlarmId] = useState(0)
  const locationState = useState(jimbocho)
  const location = locationState[0]
  const [fireAt, setFireAt] = useState(new Date().toISOString())
  const [amount, setAmount] = useState(100)
  const [currency, setCurrency] = useState('JPY')
  const [minimumDistance, setMinimumDistance] = useState(0.1)

  const [trackingInfo, setTrackingInfo] = useState('{"a8id": "123456789abcdef"}')

  const handleInputChangeAlarmID = (event: any) => {
    setAlarmId(event.target.value)
  }

  const handleOnClickGetCreditCard = async () => {
    const res = await GetCreditCardRequest().send()
    console.log(res)
  }

  const handleOnClickGetMe = async () => {
    const me = await GetMeRequest().send()
    console.log(me)
  }

  const handleOnClickGetAlarmList = async () => {
    const res = await GetAlarmsRequest().send()
    console.log(res)
  }

  const handleOnClickGetAlarmById = async () => {
    const id = alarmId
    const res = await GetAlarmRequest(id).send()
    console.log(res)
  }

  const handleInputChangeFireAt = (event: any) => {
    setFireAt(event.target.value)
  }

  const handleInputChangeAmount = (event: any) => {
    setAmount(event.target.value)
  }

  const handleInputChangeCurrency = (event: any) => {
    setCurrency(event.target.value)
  }

  const handleInputChangeMinimumDistance = (event: any) => {
    setMinimumDistance(event.target.value)
  }

  const handleInputChangeTrackingInfo = (event: any) => {
    setTrackingInfo(event.target.value)
  }

  const handleOnClickCreateAlarm = async () => {
    const res = await PostAlarmRequest({
      fire_at: fireAt,
      amount: amount,
      currency: currency,
      minimum_distance: minimumDistance,
      location,
    }).send()
    setAlarmId(res.data.data.attributes.id)
    console.log(res)
  }

  const handleOnClickWakeUpAlarm = async () => {
    const id = alarmId
    const res = await PostAlarmWakeUpRequest(id, {
      location,
    }).send()
    setAlarmId(res.data.data.attributes.id)
    console.log(res)
  }

  const handleOnClickCancelAlarm = async () => {
    const id = alarmId
    const res = await PostAlarmCancelRequest(id).send()
    setAlarmId(res.data.data.attributes.id)
    console.log(res)
  }

  const handleOnClickPostTrackingInfo = async () => {
    const data = JSON.parse(trackingInfo)
    const res = await PostTrackingInfoRequest(data).send()
    console.log(res)
  }

  const handleOnClickDeleteTrackingInfo = async () => {
    const res = await DeleteTrackingInfoRequest().send()
    console.log(res)
  }

  const handleOnClickLogout = () => {
    history.push('/sign_out')
  }

  const handleOnClickDeleteAccount = async () => {
    await DeleteAuthAccountRequest().send()
    history.push('/')
  }

  const linkBlueStyle: React.CSSProperties = { cursor: 'pointer', textDecorationLine: 'underline', color: 'blue' }
  const linkRedStyle: React.CSSProperties = { cursor: 'pointer', textDecorationLine: 'underline', color: 'red' }

  return (
    <>
      <div>
        <span>Get Credit Card</span>
        <div style={linkBlueStyle} onClick={handleOnClickGetCreditCard}>
          Fire Request
        </div>
      </div>
      <hr />

      <div>
        <span>Get Me</span>
        <div style={linkBlueStyle} onClick={handleOnClickGetMe}>
          Fire Request
        </div>
      </div>

      <hr />
      <div>
        <span>Get Alarm List</span>
        <div style={linkBlueStyle} onClick={handleOnClickGetAlarmList}>
          Fire Request
        </div>
      </div>
      <hr />

      <div>
        <span>Get Alarm by ID</span>
        <span> : </span>
        <span>
          <input name="alarm_id" type="number" value={alarmId} onChange={handleInputChangeAlarmID} />
        </span>
        <div style={linkBlueStyle} onClick={handleOnClickGetAlarmById}>
          Fire Request
        </div>
      </div>
      <hr />

      <div>
        <span>Create Alarm, fire_at</span>
        <span> : </span>
        <span>
          <input name="fire_at" type="string" value={fireAt} onChange={handleInputChangeFireAt} />
        </span>
        <div>
          <span>amount</span>
          <span> : </span>
          <span>
            <input name="amount" type="string" value={amount} onChange={handleInputChangeAmount} />
          </span>
        </div>
        <div>
          <span>currency</span>
          <span> : </span>
          <span>
            <input name="currency" type="string" value={currency} onChange={handleInputChangeCurrency} />
          </span>
        </div>
        <div>
          <span>minimum_distance</span>
          <span> : </span>
          <span>
            <input name="minimum_distance" type="string" value={minimumDistance} onChange={handleInputChangeMinimumDistance} />
          </span>
        </div>
        <LocationDebugField locationState={locationState} />
        <div style={linkBlueStyle} onClick={handleOnClickCreateAlarm}>
          Fire Request
        </div>
      </div>
      <hr />

      <div>
        <span>Wake Up Alarm by ID</span>
        <span> : </span>
        <span>
          <input name="alarm_id" type="number" value={alarmId} onChange={handleInputChangeAlarmID} />
        </span>
        <LocationDebugField locationState={locationState} />
        <div style={linkBlueStyle} onClick={handleOnClickWakeUpAlarm}>
          Fire Request
        </div>
      </div>
      <hr />

      <div>
        <span>Cancel Alarm by ID</span>
        <span> : </span>
        <span>
          <input name="alarm_id" type="number" value={alarmId} onChange={handleInputChangeAlarmID} />
        </span>
        <div style={linkBlueStyle} onClick={handleOnClickCancelAlarm}>
          Fire Request
        </div>
      </div>
      <hr />

      <div>
        <span>Tracking Info (JSON string)</span>
        <span> : </span>
        <span>
          <input name="tracking_info" type="string" value={trackingInfo} onChange={handleInputChangeTrackingInfo} />
        </span>
        <div style={linkBlueStyle} onClick={handleOnClickPostTrackingInfo}>
          Fire Post Request
        </div>
        <div style={linkBlueStyle} onClick={handleOnClickDeleteTrackingInfo}>
          Fire Delete Request
        </div>
      </div>
      <hr />

      <div>
        <span style={linkBlueStyle} onClick={handleOnClickLogout}>
          Logout
        </span>
      </div>
      <hr />

      <div>
        <div>↓↓↓ DANGER ↓↓↓</div>
        <span style={linkRedStyle} onClick={handleOnClickDeleteAccount}>
          Delete Account
        </span>
      </div>
    </>
  )
}
